import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	share: {
		id: 'business-invite-people.jwm-share-button.share',
		defaultMessage: 'Share',
		description: 'Message for button to show Share dialog',
	},
	shareTooltipText: {
		id: 'business-invite-people.jwm-share-button.share-tooltip-text',
		defaultMessage: 'Embed this Smart Link in other Atlassian products such as Confluence',
		description: 'Message shown in copy link tooltip describing how the share link can be used',
	},
	shareJwmHelperMessage: {
		id: 'business-invite-people.jwm-share-button.share-jwm-helper-message',
		defaultMessage: 'This view, along with any applied groups and filters are shared',
		description:
			'Message indicating the recipients of the email share will be able to see the JWM view and any applied groups and filters',
	},
});

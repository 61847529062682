import orderBy from 'lodash/orderBy';
import type {
	SortingDirection,
	SortingAttributes,
} from '@atlassian/jira-business-common/src/common/types/sorting.tsx';
import {
	DEFAULT_RANK_SORTING_DIRECTION,
	RANK_ALIAS_FIELD_ID,
} from '@atlassian/jira-business-constants';
import {
	getSorting,
	isSortingSupported,
	isSortingSupportedFieldTypePredicated,
} from '@atlassian/jira-business-fields/src/common/sorting/index.tsx';
import { isNewTransformedFieldPredicated } from '@atlassian/jira-business-fields/src/common/types.tsx';
import {
	type Field,
	safeGetValue,
	getFieldValue,
} from '@atlassian/jira-business-fields/src/utils/index.tsx';
import {
	StatusCategoryTypes,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ISSUE_TYPE,
	SUMMARY_TYPE,
	TEXT_CF_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	REPORTER_TYPE,
	DUE_DATE_TYPE,
	CREATED,
	UPDATED,
	LABELS_TYPE,
	PRIORITY_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
	STORY_POINTS_TYPE,
	NUMBER_CF_TYPE,
	DATE_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	DATETIME_CF_TYPE,
	COMPONENTS_TYPE,
	LABELS_CF_TYPE,
	CATEGORY_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SPRINT_TYPE,
} from '@atlassian/jira-platform-field-config';
import { resolveRank } from '../../common/utils/resolve-rank';
import type { AllSortedLists, SortedList } from './types';

const DEFAULT_HIGHER_RANKING_VALUE = 'Z';
const DEFAULT_LOWER_RANKING_VALUE = '~~';
const DEFAULT_LOWER_RANKING_NUMBER_VALUE = 0;

const toAcceptedOrder = (order?: string): 'asc' | 'desc' =>
	order != null && order.toLowerCase() === 'asc' ? 'asc' : 'desc';

export const sortListBy = <TNode extends { rank?: string; fields?: Array<Field> }>(
	nodes: TNode[],
	getters: ((arg1: TNode) => string | number)[],
	orders: SortingDirection[],
): TNode[] => orderBy<TNode>(nodes, getters, orders.map(toAcceptedOrder));

const getValueMapAndJoin = (
	fields: Field[],
	fieldKey: string | null | undefined,
	attributeName: string | null | undefined,
	defaultValue: (string | null)[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getMapValueCb: (arg1?: any) => void,
): string => {
	// join array with space so alphabetical order can be applied.
	// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string | undefined'.
	const fieldValue = safeGetValue(fields, fieldKey, attributeName, defaultValue) ?? defaultValue;
	return fieldValue.map(getMapValueCb).join(' ') || DEFAULT_LOWER_RANKING_VALUE;
};

const resolvePriorityOrder = (fields: Array<Field>, priorities: SortedList) => {
	const priorityId = fg('jwm_list_new_copy_paste_behaviour')
		? safeGetValue<string>(fields, 'priority', 'value.priorityId', '')
		: safeGetValue<string>(fields, 'priority', 'priorityId', '');
	const priorityOrder = priorities[priorityId];

	if (priorityOrder == null) {
		return -1;
	}
	return priorityOrder;
};

const resolveStatusOrder = (fields: Array<Field>, statuses: SortedList) => {
	const statusName = safeGetValue<string>(fields, 'status', 'name', '');
	const statusOrder = statuses[statusName];
	if (statusOrder == null) {
		return -1;
	}
	return statusOrder;
};

const resolveTypeOrder = (fields: Array<Field>, types: SortedList) => {
	const typeName = safeGetValue<string>(fields, 'issuetype', 'value', '');
	const typeOrder = types[typeName];
	if (typeOrder == null) {
		return -1;
	}
	return typeOrder;
};

const resolveIssueKeyOrder = (fields: Array<Field>) => {
	const [, issueKeyNumber = 0] = safeGetValue<string>(fields, 'issuekey', 'value', '').split('-');
	return Number(issueKeyNumber);
};

const resolveCustomTextFieldOrder = (fields: Array<Field>, sortingField?: string) => {
	// wiki value will be null when empty
	const value = safeGetValue<string | null>(fields, sortingField, 'value', null);
	return value != null ? value.toLowerCase() : DEFAULT_LOWER_RANKING_VALUE; // empty values should rank lower
};

/* eslint-disable complexity */
const createSortableGetter =
	(fieldType?: string, sortingField?: string, sortedList?: AllSortedLists) =>
	(node: { rank?: string; fields?: Field[] }): number | string => {
		const { rank, fields = [] } = node;

		if (
			fieldType != null &&
			isSortingSupportedFieldTypePredicated(fieldType) &&
			isSortingSupported(fieldType) &&
			fg('jsw_list_view_-_all_the_fields')
		) {
			const field = getFieldValue(fields, sortingField);
			if (isNewTransformedFieldPredicated(field)) {
				return getSorting(fieldType, field) || DEFAULT_LOWER_RANKING_VALUE;
			}
			return DEFAULT_LOWER_RANKING_VALUE;
		}

		switch (fieldType) {
			case LABELS_TYPE:
			case LABELS_CF_TYPE:
				// The backend will return [''] when it's empty so our default value might not be applied so we force return ~~ when it happens so empty items are ordered properly
				return getValueMapAndJoin(fields, sortingField, 'value', [''], (label) =>
					label.toLowerCase(),
				);
			case PRIORITY_TYPE:
				return resolvePriorityOrder(fields, (sortedList != null && sortedList.priorities) || {});
			case STATUS_TYPE:
				return resolveStatusOrder(fields, (sortedList != null && sortedList.statuses) || {});

			case ISSUE_TYPE:
				return resolveTypeOrder(fields, (sortedList != null && sortedList.issueTypes) || {});

			case ASSIGNEE_TYPE:
			case REPORTER_TYPE:
				return safeGetValue<string>(
					fields,
					sortingField,
					'value.name',
					// unassigned items are ranked higher than the ones with an user :shrug:
					DEFAULT_LOWER_RANKING_VALUE,
				).toLowerCase();
			case USER_CF_TYPE:
				return safeGetValue<string>(
					fields,
					sortingField,
					'value.name',
					// unassigned items are ranked higher than the ones with an user :shrug:
					DEFAULT_LOWER_RANKING_VALUE,
				).toLowerCase();

			case 'issuekey': // issue key is not available to import from field config
				return resolveIssueKeyOrder(fields);

			case CREATED:
			case UPDATED:
				return safeGetValue<string>(fields, sortingField, 'value', '');

			case DATE_CF_TYPE:
				return safeGetValue<string>(fields, sortingField, 'value', DEFAULT_HIGHER_RANKING_VALUE);

			case DUE_DATE_TYPE:
				return safeGetValue<string>(
					fields,
					sortingField,
					'value',
					// empty dates are ranked higher than the ones with set date :shrug:
					DEFAULT_HIGHER_RANKING_VALUE,
				);

			case DATETIME_CF_TYPE:
				return safeGetValue<string>(fields, sortingField, 'value', DEFAULT_HIGHER_RANKING_VALUE);

			case RANK_ALIAS_FIELD_ID: {
				if (ff('list-uses-new-jql-parent-project-directive_v5gfs')) {
					// We want to check for l-1 if the new jql parent is on
					if (safeGetValue(fields, 'issuetype', 'level', undefined) === -1) {
						return resolveRank(safeGetValue(fields, 'parent', 'value', undefined), rank ?? '');
					}
				} else {
					return resolveRank(safeGetValue(fields, 'parent', 'value', undefined), rank ?? '');
				}

				return rank ?? '';
			}
			case SUMMARY_TYPE:
				return safeGetValue<string>(fields, sortingField, 'value', '').toLowerCase();

			case TEXT_CF_TYPE:
				return resolveCustomTextFieldOrder(fields, sortingField);

			case SELECT_CF_TYPE:
			case CATEGORY_TYPE:
				return safeGetValue<string>(
					fields,
					sortingField,
					'value.value',
					DEFAULT_LOWER_RANKING_VALUE,
				);
			case MULTI_SELECT_CF_TYPE:
			case MULTI_CHECKBOXES_CF_TYPE:
				return getValueMapAndJoin(fields, sortingField, 'value', [], (selectedValue) =>
					selectedValue?.value.toLowerCase(),
				);
			case TIME_ESTIMATE_TYPE:
				return safeGetValue<number>(
					fields,
					sortingField,
					'value.timeInSeconds',
					DEFAULT_LOWER_RANKING_NUMBER_VALUE,
				);

			case URL_CF_TYPE:
				return safeGetValue<string>(fields, sortingField, 'value', DEFAULT_LOWER_RANKING_VALUE);

			case NUMBER_CF_TYPE:
			case STORY_POINTS_TYPE:
			case STORY_POINT_ESTIMATE_CF_TYPE:
				return safeGetValue<number>(
					fields,
					sortingField,
					'value',
					DEFAULT_LOWER_RANKING_NUMBER_VALUE,
				);

			case COMPONENTS_TYPE:
				return getValueMapAndJoin(fields, sortingField, 'value', [], (component) =>
					component?.name.toLowerCase(),
				);
			case SPRINT_TYPE: {
				const sprintValue = safeGetValue<string>(
					fields,
					sortingField,
					'value',
					DEFAULT_LOWER_RANKING_VALUE,
				);
				if (Array.isArray(sprintValue) && sprintValue.length) {
					const { name, state } = sprintValue[0];
					if (state === 'CLOSED') {
						const statusId = safeGetValue<string>(fields, 'status', 'value', '');
						// closed sprints should not be shown if the status is not done
						// means that this ticket is on the backlog
						if (statusId?.toString() !== StatusCategoryIds[StatusCategoryTypes.DONE].toString()) {
							return DEFAULT_LOWER_RANKING_VALUE;
						}
					}
					return name.toLowerCase();
				}
				return DEFAULT_LOWER_RANKING_VALUE;
			}
			default:
				return safeGetValue<string>(fields, RANK_ALIAS_FIELD_ID, 'value', '');
		}
	};
/* eslint-enable complexity */

export const createSortingGettersAndOrders = (
	sorting?: SortingAttributes,
	fieldType?: string,
	sortedLists?: AllSortedLists,
) => {
	const getters: Array<(node: { rank?: string; fields?: Array<Field> }) => number | string> = [];
	const orders: SortingDirection[] = [];

	let sortFieldType = fieldType;

	// fieldType for key is not returned from useIssueTypeFieldConfig
	if (sorting?.sortBy === 'key') {
		sortFieldType = 'issuekey';
	}

	if (sorting != null && sorting.sortBy != null) {
		orders.push(sorting.direction, sorting.direction);
		getters.push(createSortableGetter(sortFieldType, sorting.sortBy, sortedLists));
		getters.push(createSortableGetter(RANK_ALIAS_FIELD_ID, RANK_ALIAS_FIELD_ID));
	} else {
		orders.push(DEFAULT_RANK_SORTING_DIRECTION);
		getters.push(createSortableGetter(RANK_ALIAS_FIELD_ID, RANK_ALIAS_FIELD_ID));
	}

	return { getters, orders };
};

import type { ReactNode } from 'react';

export type SelectOption = {
	value: string | null;
	label: string;
	id?: string;
	cursor?: string;
	aboveTheFold?: boolean;
	data?: SelectOption;
	invalid?: boolean;
	icon?: string;
	isEmptyOption?: boolean;
	isSelected?: boolean;
};

export type CategoryOption = SelectOption & {
	color: string | null;
};

export type GoalOption = SelectOption &
	(
		| {
				isEmptyOption?: false;
				status: string;
		  }
		| { isEmptyOption: true; value: null }
	);

export type AvatarOption = SelectOption & {
	avatar?: string | null;
	square?: boolean;
	hideAvatar?: boolean;
};

export type IssueTypeOption = AvatarOption & {
	hierarchyLevel: number;
};

export type PriorityOption = SelectOption & {
	avatar: string;
};

export type LozengeOption = SelectOption & {
	categoryId?: string | number;
	appearance?: 'default' | 'inprogress' | 'moved' | 'new' | 'removed' | 'success';
	isBold?: boolean;
	maxWidth?: number | string;
};

export type SelectValue<OptionType extends SelectOption> = OptionType[];

export type DateBetween = {
	from?: string;
	to?: string;
};

export type DateType = 'from' | 'to';

export type DateValue = {
	type?: string;
	value: DateBetween | null;
};

export const FieldType = {
	AsyncSelectFilter: 'AsyncSelectFilter',
	AvatarSelectFilter: 'AvatarSelectFilter',
	AvatarAsyncSelectFilter: 'AvatarAsyncSelectFilter',
	PaginatedAsyncSelectFilter: 'PaginatedAsyncSelectFilter',
	LozengeAsyncSelectFilter: 'LozengeAsyncSelectFilter',
	PaginatedLozengeAsyncSelectFilter: 'PaginatedLozengeAsyncSelectFilter',
	LozengeSelectFilter: 'LozengeSelectFilter',
	PrioritySelectFilter: 'PrioritySelectFilter',
	CategorySelectFilter: 'CategorySelectFilter',
	GoalsSelectFilter: 'GoalsSelectFilter',
	PaginatedAvatarAsyncSelectFilter: 'PaginatedAvatarAsyncSelectFilter',
	NumberFilter: 'NumberFilter',
	SelectFilter: 'SelectFilter',
	SearchFilter: 'SearchFilter',
	TextFilter: 'TextFilter',
	DateFilter: 'DateFilter',
	ProjectFilter: 'ProjectFilter',
} as const;

export type FieldType = (typeof FieldType)[keyof typeof FieldType];

export type FieldBaseProps = {
	isRemovable?: boolean;
	label: string;
	type: FieldType;
	systemType?: string;
	key?: string | null;
	isCustomField?: boolean;
};

export type TransformedFieldData<OptionType extends SelectOption> = {
	totalCount: number;
	options: OptionType[];
};

export type LoadOptions<OptionType extends SelectOption> = (arg1: {
	query?: string;
	afterCursor?: string;
	first?: number;
}) => Promise<TransformedFieldData<OptionType>>;

export type SelectField<OptionType extends SelectOption> = FieldBaseProps & {
	options: SelectValue<OptionType>;
	loadOptions?: LoadOptions<OptionType>;
	totalCount?: number;
};

export type AsyncSelectField<OptionType extends SelectOption> = SelectField<OptionType> & {
	defaultOptionsLabel?: string;
	defaultOptions: SelectOption[] | boolean;
	loadOptions?: LoadOptions<OptionType>;
	cacheOptions: boolean;
	onInputChange?: () => void;
	inputValue?: string;
	onMenuScrollToBottom?: () => void;
	onMenuScrollToTop?: () => void;
	placeholder?: ReactNode;
	enableShowMore?: boolean;
};

export type TextField = SelectField<SelectOption> & {
	type: typeof FieldType.SelectFilter;
};

export type AliasField = SelectField<SelectOption> & {
	type: typeof FieldType.SelectFilter;
	aliasFieldId: string;
};

export type AsyncTextField = AsyncSelectField<SelectOption> & {
	type: typeof FieldType.AsyncSelectFilter | typeof FieldType.PaginatedAsyncSelectFilter;
};

export type LozengeField = SelectField<LozengeOption> & {
	type: typeof FieldType.LozengeSelectFilter;
};

export type AsyncLozengeField = AsyncSelectField<LozengeOption> & {
	type:
		| typeof FieldType.LozengeAsyncSelectFilter
		| typeof FieldType.PaginatedLozengeAsyncSelectFilter;
};

export type AvatarField = SelectField<AvatarOption> & {
	type: typeof FieldType.AvatarSelectFilter;
};

export type AsyncAvatarField = AsyncSelectField<AvatarOption> & {
	type:
		| typeof FieldType.AvatarAsyncSelectFilter
		| typeof FieldType.PaginatedAvatarAsyncSelectFilter;
};

export type PriorityField = SelectField<AvatarOption> & {
	type: typeof FieldType.PrioritySelectFilter;
};

export type ProjectField = SelectField<AvatarOption> & {
	type: typeof FieldType.ProjectFilter;
};

export type DateField = FieldBaseProps & {
	type: typeof FieldType.DateFilter;
	fromLabel?: string;
	toLabel?: string;
};

export type CategoryField = SelectField<CategoryOption> & {
	type: typeof FieldType.CategorySelectFilter;
};

export type GoalsField = SelectField<GoalOption> & {
	type: typeof FieldType.GoalsSelectFilter;
};

export type Field =
	| AsyncAvatarField
	| AsyncLozengeField
	| AsyncTextField
	| CategoryField
	| AvatarField
	| DateField
	| LozengeField
	| PriorityField
	| ProjectField
	| TextField
	| AliasField
	| GoalsField;

export type Value =
	| SelectValue<SelectOption>
	| SelectValue<AvatarOption>
	| SelectValue<PriorityOption>
	| SelectValue<LozengeOption>
	| SelectValue<GoalOption>
	| DateValue;

export type FieldConfig = {
	[key: string]: Field & { fieldConfigVersion?: number };
};

export type Filters = {
	[key: string]: Value;
};

export const isDateField = (field: Field): field is DateField =>
	field.type === FieldType.DateFilter;

export type QuickFilterKeys = 'ASSIGNED_TO_ME' | 'DONE' | 'DUE_THIS_WEEK' | 'ACTIVE_SPRINTS';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type QuickFiltersOriginal = Record<QuickFilterKeys, boolean>;
export type QuickFilters = Optional<QuickFiltersOriginal, 'ACTIVE_SPRINTS'>;
export type QuickFiltersJQL = Optional<Record<QuickFilterKeys, string>, 'ACTIVE_SPRINTS'>;

import type { KeyboardEvent, MouseEvent, RefObject, FocusEvent } from 'react';
import type { TransformedCascadingSelectField } from '@atlassian/jira-business-fields/src/common/field-types/cascading-select/types.tsx';
import type { TransformedCategoryField } from '@atlassian/jira-business-fields/src/common/field-types/category/types.tsx';
import type { TransformedCheckboxField } from '@atlassian/jira-business-fields/src/common/field-types/checkbox/types.tsx';
import type { TransformedComponentsField } from '@atlassian/jira-business-fields/src/common/field-types/components/types.tsx';
import type { jiraDateFieldFragment as JiraDateField } from '@atlassian/jira-business-fields/src/common/field-types/date/fragment/__generated_apollo__/jiraDateFieldFragment.ts';
import type { TransformedDateField } from '@atlassian/jira-business-fields/src/common/field-types/date/types.tsx';
import type { jiraDateTimeFieldFragment as JiraDateTimeField } from '@atlassian/jira-business-fields/src/common/field-types/datetime/fragment/__generated_apollo__/jiraDateTimeFieldFragment.ts';
import type { TransformedDateTimeField } from '@atlassian/jira-business-fields/src/common/field-types/datetime/types.tsx';
import type { TransformedDevSummary } from '@atlassian/jira-business-fields/src/common/field-types/dev-summary/types.tsx';
import type { TransformedGoals } from '@atlassian/jira-business-fields/src/common/field-types/goals/types.tsx';
import type { TransformedGroupField } from '@atlassian/jira-business-fields/src/common/field-types/group/types.tsx';
import type { jiraLabelsFieldFragment as JiraLabelsField } from '@atlassian/jira-business-fields/src/common/field-types/labels/fragment/__generated_apollo__/jiraLabelsFieldFragment.ts';
import type { TransformedLabelsField } from '@atlassian/jira-business-fields/src/common/field-types/labels/types.tsx';
import type { TransformedMultiSelectField } from '@atlassian/jira-business-fields/src/common/field-types/multi-select/types.tsx';
import type { TransformedNumberField } from '@atlassian/jira-business-fields/src/common/field-types/number/types.tsx';
import type { TransformedParentField } from '@atlassian/jira-business-fields/src/common/field-types/parent/types.tsx';
import type { jiraPriorityFieldFragment as JiraPriorityField } from '@atlassian/jira-business-fields/src/common/field-types/priority/fragment/__generated_apollo__/jiraPriorityFieldFragment.ts';
import type { TransformedPriorityField } from '@atlassian/jira-business-fields/src/common/field-types/priority/types.tsx';
import type { TransformedProjectField } from '@atlassian/jira-business-fields/src/common/field-types/project/types.tsx';
import type { TransformedRadioSelectField } from '@atlassian/jira-business-fields/src/common/field-types/radio-select/types.tsx';
import type { TransformedResolutionField } from '@atlassian/jira-business-fields/src/common/field-types/resolution/types.tsx';
import type { TransformedRichTextField } from '@atlassian/jira-business-fields/src/common/field-types/rich-text/types.tsx';
import type { TransformedSingleSelectField } from '@atlassian/jira-business-fields/src/common/field-types/single-select/types.tsx';
import type { TransformedSprintField } from '@atlassian/jira-business-fields/src/common/field-types/sprint/types.tsx';
import type { TransformedTeamField } from '@atlassian/jira-business-fields/src/common/field-types/team/types.tsx';
import type { TransformedTextField } from '@atlassian/jira-business-fields/src/common/field-types/text/types.tsx';
import type { TransformedTimeTrackingField } from '@atlassian/jira-business-fields/src/common/field-types/time-tracking/types.tsx';
import type { TransformedTypeField } from '@atlassian/jira-business-fields/src/common/field-types/type/types.tsx';
import type { TransformedUrlField } from '@atlassian/jira-business-fields/src/common/field-types/url/types.tsx';
import type { jiraSingleUserFieldFragment_user as JiraSingleUser } from '@atlassian/jira-business-fields/src/common/field-types/user/fragment/__generated_apollo__/jiraSingleUserFieldFragment.ts';
import type {
	TransformedUserField,
	TransformedMultiUserField,
} from '@atlassian/jira-business-fields/src/common/field-types/user/types.tsx';
import type { TransformedVersionField } from '@atlassian/jira-business-fields/src/common/field-types/version/types.tsx';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent/src/common/types';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TransformedStatusField } from './field-types';

export type { TransformedCategoryField } from '@atlassian/jira-business-fields/src/common/field-types/category/types.tsx';
export type { TransformedComponentsField } from '@atlassian/jira-business-fields/src/common/field-types/components/types.tsx';
export type { TransformedVersionField } from '@atlassian/jira-business-fields/src/common/field-types/version/types.tsx';
export type { TransformedParentField } from '@atlassian/jira-business-fields/src/common/field-types/parent/types.tsx';
export type { TransformedNumberField } from '@atlassian/jira-business-fields/src/common/field-types/number/types.tsx';
export type { TransformedDevSummary } from '@atlassian/jira-business-fields/src/common/field-types/dev-summary/types.tsx';
export type { TransformedLabelsField } from '@atlassian/jira-business-fields/src/common/field-types/labels/types.tsx';
export type {
	TransformedUserField,
	TransformedMultiUserField,
} from '@atlassian/jira-business-fields/src/common/field-types/user/types.tsx';
export type { TransformedDateField } from '@atlassian/jira-business-fields/src/common/field-types/date/types.tsx';
export type { TransformedDateTimeField } from '@atlassian/jira-business-fields/src/common/field-types/datetime/types.tsx';
export type {
	TransformedPriorityField,
	TransformedPriority,
} from '@atlassian/jira-business-fields/src/common/field-types/priority/types.tsx';
export type { TransformedTextField } from '@atlassian/jira-business-fields/src/common/field-types/text/types.tsx';
export type { TransformedUrlField } from '@atlassian/jira-business-fields/src/common/field-types/url/types.tsx';
export type { TransformedMultiSelectField } from '@atlassian/jira-business-fields/src/common/field-types/multi-select/types.tsx';
export type { TransformedCheckboxField } from '@atlassian/jira-business-fields/src/common/field-types/checkbox/types.tsx';
export type { TransformedSingleSelectField } from '@atlassian/jira-business-fields/src/common/field-types/single-select/types.tsx';
export type { TransformedRichTextField } from '@atlassian/jira-business-fields/src/common/field-types/rich-text/types.tsx';
export type { TransformedTimeTrackingField } from '@atlassian/jira-business-fields/src/common/field-types/time-tracking/types.tsx';
export type { TransformedSprintField } from '@atlassian/jira-business-fields/src/common/field-types/sprint/types.tsx';
export type { TransformedTeamField } from '@atlassian/jira-business-fields/src/common/field-types/team/types.tsx';
export type { TransformedRadioSelectField } from '@atlassian/jira-business-fields/src/common/field-types/radio-select/types.tsx';

export { type jiraBusinessFields, isJiraBusinessFields } from './fragment/types';

export type { SUPPORTED_FIELD_TYPES, TOP_LEVEL_FIELD_TYPES } from './supported-field-types';

export type ListIssueFieldTransformed =
	| TransformedCategoryField
	| TransformedCheckboxField
	| TransformedComponentsField
	| TransformedVersionField
	| TransformedParentField
	| TransformedNumberField
	| TransformedLabelsField
	| TransformedUserField
	| TransformedMultiUserField
	| TransformedDateField
	| TransformedDateTimeField
	| TransformedGoals
	| TransformedTextField
	| TransformedRadioSelectField
	| TransformedRichTextField
	| TransformedResolutionField
	| TransformedPriorityField
	| TransformedUrlField
	| TransformedGroupField
	| TransformedSingleSelectField
	| TransformedMultiSelectField
	| TransformedTimeTrackingField
	| TransformedSprintField
	| TransformedTeamField
	| TransformedProjectField
	| TransformedCascadingSelectField;

export type ListIssueFieldTransformedUnionForCopyPaste =
	| TransformedCategoryField
	| TransformedCheckboxField
	| TransformedVersionField
	| TransformedNumberField
	| TransformedComponentsField
	| TransformedLabelsField
	| TransformedUserField
	| TransformedMultiUserField
	| TransformedDateField
	| TransformedDateTimeField
	| TransformedTextField
	| TransformedPriorityField
	| TransformedUrlField
	| TransformedSingleSelectField
	| TransformedMultiSelectField
	| TransformedTimeTrackingField
	| TransformedSprintField
	| TransformedTeamField
	| TransformedGroupField
	| TransformedProjectField
	| TransformedCascadingSelectField
	| TransformedRadioSelectField;

export type ListIssueFieldTransformedForFormatRule =
	| TransformedUserField
	| TransformedPriorityField
	| TransformedTextField
	| TransformedLabelsField
	| TransformedNumberField
	| TransformedProjectField
	| TransformedMultiUserField
	| TransformedSprintField
	| TransformedVersionField
	| TransformedDateField
	| TransformedTypeField
	| TransformedSingleSelectField
	| TransformedMultiSelectField
	| TransformedRadioSelectField
	| TransformedComponentsField
	| TransformedStatusField;

export type ListIssueForCopyPaste = {
	key: string;
	issueId: string;
	issueType?: {
		level?: number;
	};
};

export type ListIssueField =
	| JiraLabelsField
	| JiraPriorityField
	| JiraSingleUser
	| JiraDateField
	| JiraDateTimeField;

export type TopLevelFieldTransformed = TransformedDevSummary;

export type CellOverrides = {
	onKeyDown?: (event: KeyboardEvent<HTMLElement>) => boolean | undefined;
	onClick?: (event: MouseEvent<HTMLElement>) => boolean | undefined;
	onBlur?: (event: FocusEvent<HTMLElement>) => boolean;
};

export type CellNavigationProps = {
	editMode: boolean;
	setEditMode: (editMode: boolean) => void;
	isHovering: boolean;
	cellRef?: RefObject<CellOverrides>;
	inputValue?: string;
	onInputChange?: (value: string) => void;
};

type IssueUpdatedHandlerValue = ParentFieldOption;

export type IssueUpdatedHandler = (args: {
	issueId: IssueId;
	fieldId: string;
	fieldType: string;
	oldValue: IssueUpdatedHandlerValue | null;
	newValue: IssueUpdatedHandlerValue | null;
	issueKey?: string;
}) => void;

export type FieldCellProps<Data, T> = {
	rowData: Data;
	rowIndex: number;
	columnIndex: number;
	column: {
		key: string;
		width: number;
	};
	cellData?: T | null;
	onIssueUpdated: IssueUpdatedHandler;
	fieldId: string;
	fieldName: string;
} & CellNavigationProps;

export type BasicListViewItem = {
	id: string;
	key: string;
	issueId: string;
	fieldConfig?: {
		isEditable: boolean;
	};
	issuetype?: {
		id?: string;
		name?: string;
		iconUrl?: string;
		level?: number;
	};
};

// Cast to to the new transformed fields
export const isNewTransformedFieldPredicated = (
	field: unknown,
): field is ListIssueFieldTransformed => field != null;

export type ClipboardItem = {
	plainText?: string;
	html?: string;
};

export type PasteMetadata = {
	fieldId: string;
	fieldType: string;
	fieldValue: { [key: string]: unknown } | unknown[] | null;
	sourceIssueTypeLevel?: string;
};

export type PasteHandler = (
	issue: ListIssueForCopyPaste,
	field: ListIssueFieldTransformed,
) => {
	contentToCopy: ClipboardItem | null;
	copyHandler: () => Promise<void>;
	pasteHandler: (clipboardItem: ClipboardItem, metadata: PasteMetadata | null) => Promise<void>;
	cutHandler: () => Promise<void>;
};

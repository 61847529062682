import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';

const store = createLocalStorageProvider('jwm-view-preferences');

const buildPreferenceKey = (entityId: string, viewId: JWMView, preferenceId: string) =>
	`${entityId}_${viewId}_${preferenceId}`;

export const setPreference = <T,>(
	entityId: string,
	viewId: JWMView,
	preferenceId: string,
	value: T,
) => {
	const key = buildPreferenceKey(entityId, viewId, preferenceId);
	store.set(key, value);
};

export const getPreference = (entityId: string, viewId: JWMView, preferenceId: string) => {
	const key = buildPreferenceKey(entityId, viewId, preferenceId);
	return store.get(key);
};

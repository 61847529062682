import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';

export class UserResolver {
	cache: Map<string, User>;

	constructor() {
		this.cache = new Map();
	}

	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	getUsersFromAccountId = (accountIds: string[]): Promise<string[] | void> => {
		const recoveredFromCache: User[] = [];
		const missingFromCache: Array<string> = [];

		accountIds.forEach((accountId) => {
			const user = this.cache.get(accountId);
			if (user) {
				recoveredFromCache.push(user);
			} else {
				missingFromCache.push(accountId);
			}
		});

		if (missingFromCache.length === 0) {
			// @ts-expect-error - User is not assignable to type 'string'
			return Promise.resolve(recoveredFromCache);
		}

		return fetchJson<User[]>(
			`/rest/api/2/user/bulk?maxResults=10&${missingFromCache
				.map((accountId) => `accountId=${accountId}`)
				.join('&')}`,
		)
			.then(
				(response) =>
					response &&
					response.values.length > 0 &&
					// @ts-expect-error - TS2339 - Property 'forEach' does not exist on type '() => IterableIterator<Readonly<Partial<Diff<ResponseSuccess, Record<any, any>>> & { accountId: string | null; displayName: string; avatarUrls: { readonly "48x48"?: string | undefined; readonly "32x32"?: string | undefined; readonly "24x24"?: string | undefined; readonly "16x16"?: string | undefined; }; }>>'. | TS7006 - Parameter 'user' implicitly has an 'any' type.
					response.values.forEach((user) => {
						if (user) {
							this.cache.set(user.accountId, user);
						}
					}),
			)
			.then(() => this.getUsersFromAccountId(accountIds))
			.catch((err) => {
				log.safeErrorWithoutCustomerData('jwm.services.get-users', 'Failed to fetch users', err);
			});
	};
}

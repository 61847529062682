import React, { useCallback, useEffect, useRef, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/glyph/error';
import InlineDialog from '@atlaskit/inline-dialog';
import InlineEdit from '@atlaskit/inline-edit';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	defaultValue: string;
	isEditing: boolean;
	onCancel: () => void;
	onConfirm: (value: string) => void;
	onEdit: () => void;
	onValidate: (value: string) => string | undefined;
	readView: () => ReactElement;
};

const columnNameMaxLength = 30;
const ERROR_MESSAGE_ID = 'column-name-error-dialog';

const EditableColumnHeader = ({
	defaultValue,
	isEditing,
	onCancel,
	onConfirm,
	onEdit,
	onValidate,
	readView,
}: Props) => {
	const { formatMessage } = useIntl();

	const isReadyRef = useRef(false);
	const renameTextFieldRef = useRef<HTMLInputElement>(null);

	// sets component to be ready after 10 ms and
	// focus on Textfield when editing
	useEffect(() => {
		if (isEditing) {
			setTimeout(() => {
				isReadyRef.current = true;
				renameTextFieldRef.current?.focus();
			}, 10);
		} else {
			isReadyRef.current = false;
		}
	}, [isEditing]);

	const handleConfirm = useCallback(
		(value: string) => {
			if (isReadyRef.current) {
				onConfirm(value);
			}
		},
		[onConfirm],
	);

	const columnNameButtonLabel = formatMessage(messages.columnNameButtonLabel, {
		statusName: defaultValue,
	});

	return (
		<Wrapper>
			<InlineEdit
				readViewFitContainerWidth
				defaultValue={defaultValue}
				isEditing={isEditing}
				readView={readView}
				editView={({ errorMessage, ...fieldProps }) => (
					<InlineDialog
						isOpen={fieldProps.isInvalid}
						content={
							<div id={ERROR_MESSAGE_ID} role="alert" aria-live="assertive">
								{errorMessage}
							</div>
						}
						placement="bottom"
					>
						<Textfield
							{...fieldProps}
							ref={renameTextFieldRef}
							elemAfterInput={
								fieldProps.isInvalid && (
									<WarningIcon
										label="error"
										primaryColor={token('color.icon.warning', colors.Y400)}
									/>
								)
							}
							onBlur={() => {
								if (fieldProps.isInvalid) {
									onCancel();
								}
							}}
							maxLength={columnNameMaxLength}
							autoComplete="off"
							aria-errormessage={ERROR_MESSAGE_ID}
							aria-label={columnNameButtonLabel}
						/>
					</InlineDialog>
				)}
				onEdit={onEdit}
				onCancel={onCancel}
				onConfirm={handleConfirm}
				validate={onValidate}
				editButtonLabel={columnNameButtonLabel}
			/>
		</Wrapper>
	);
};

const EditableColumnHeaderWithErrorBoundary = (props: Props) => (
	<ErrorBoundary
		id="async.jira-business.board.editable-column-header"
		packageName="jiraWorkManagementBoard"
	>
		<EditableColumnHeader {...props} />
	</ErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	minWidth: 0,
	marginLeft: token('space.negative.025', '-2px'),
	marginRight: token('space.negative.025', '-2px'),
});

export default EditableColumnHeaderWithErrorBoundary;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnMoveErrorTitle: {
		id: 'work-management-board.controllers.column-move-handler.column-move-error-title',
		defaultMessage: 'Failed to move column',
		description: 'Error title informing the user that the column move operation failed.',
	},
	columnMoveErrorMessage: {
		id: 'work-management-board.controllers.column-move-handler.column-move-error-message',
		defaultMessage: "We couldn't move this column, try refreshing the page or try again later.",
		description: 'Error message informing the user that the column move operation failed.',
	},
});

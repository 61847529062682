import { useCallback } from 'react';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	BOARD_FIELD_PRIORITY,
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_USER,
} from '../../common/constants';
import type {
	BoardIssueId,
	BoardIssuePriorityField,
	BoardIssueCategoryField,
	BoardIssueUserField,
} from '../../common/types';

const UPDATE_ISSUE_FIELDS_EXPERIENCE: ExperienceDetails = {
	experience: 'updateIssueFields',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export type FieldUpdateInput = {
	fieldId: string;
	value: BoardIssueUserField | BoardIssueCategoryField | BoardIssuePriorityField | undefined;
};

type PriorityFieldPayload = { id: string };
type CategoryFieldPayload = { value: string };
type UserFieldPayload = { accountId: string };

type FieldsPayload = {
	[fieldId: string]: PriorityFieldPayload | CategoryFieldPayload | UserFieldPayload | null;
};

export const useUpdateIssueFields = () => {
	const startUpdateIssueFieldsExperience = useExperienceStart(UPDATE_ISSUE_FIELDS_EXPERIENCE);
	const markUpdateIssueFieldsSuccess = useExperienceSuccess(UPDATE_ISSUE_FIELDS_EXPERIENCE);
	const markUpdateIssueFieldsFailed = useExperienceFail(UPDATE_ISSUE_FIELDS_EXPERIENCE);

	return useCallback(
		async (issueId: BoardIssueId, fields: FieldUpdateInput[]) => {
			const fieldsPayload: FieldsPayload = {};

			fields.forEach((field) => {
				const fieldId = field.fieldId;
				const fieldValue = field.value;
				if (fieldValue == null) {
					fieldsPayload[field.fieldId] = null;
					return;
				}

				const { type } = fieldValue;

				switch (type) {
					case BOARD_FIELD_PRIORITY:
						fieldsPayload[fieldId] = {
							id: fieldValue.priority.id,
						};
						break;
					case BOARD_FIELD_CATEGORY:
						fieldsPayload[fieldId] = {
							value: fieldValue.category.name,
						};
						break;
					case BOARD_FIELD_USER:
						fieldsPayload[fieldId] = {
							accountId: fieldValue.user.accountId,
						};
						break;
					default: {
						const exhaustiveCheck: never = type;
						throw new Error(`Unexpected field type value: ${exhaustiveCheck}`);
					}
				}
			});

			startUpdateIssueFieldsExperience();

			try {
				await performPutRequest(`/rest/api/3/issue/${issueId}/`, {
					body: JSON.stringify({
						fields: fieldsPayload,
					}),
				});

				markUpdateIssueFieldsSuccess();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				markUpdateIssueFieldsFailed('Failed to update issue fields', error);

				throw error;
			}
		},
		[markUpdateIssueFieldsFailed, markUpdateIssueFieldsSuccess, startUpdateIssueFieldsExperience],
	);
};

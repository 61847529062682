import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filtersCount: {
		id: 'business-filters.filters.trigger.filters-count',
		defaultMessage:
			'{filters, number} {filters, plural, one {filter applied} other {filters applied}}',
		description: 'Filter button text that shows number of filters',
	},
	filterButton: {
		id: 'business-filters.filters.trigger.filter-button',
		defaultMessage: 'Filter',
		description: 'Filter button text',
	},
	buttonWithFilters: {
		id: 'business-filters.filters.trigger.button-with-filters',
		defaultMessage: 'Filters applied',
		description: 'Filter button text indicating that filters are applied',
	},
	newLozenge: {
		id: 'business-filters.filters.trigger.new-lozenge',
		defaultMessage: 'New',
		description: 'New lozenge text when first seeing the filters experience',
	},
	newLozengeTooltip: {
		id: 'business-filters.filters.trigger.new-lozenge-tooltip',
		defaultMessage:
			'Now you can save your frequently used filters. Apply some filters to get started.',
		description: 'Tooltip for the filter button for when the user first sees saved filters enabled',
	},
});

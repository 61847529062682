import { createContext, useContext } from 'react';
import type { BoardFeaturesContextProps } from './types';

const defaultFeaturesContext = {
	isShareButtonEnabled: true,
	isPresenceEnabled: true,
	issueViewInteraction: 'default',
	isOnboardingEnabled: true,
	featureView: 'jwm-board',
	inviteButtonInteraction: 'default',
	typeSelectInteraction: 'default',
} as const;

const JWMBoardFeaturesContext = createContext<BoardFeaturesContextProps>(defaultFeaturesContext);

const useJWMBoardFeatures = () => useContext(JWMBoardFeaturesContext);

export default JWMBoardFeaturesContext;
export { useJWMBoardFeatures };

export const JWM_DELETE_ISSUE_SUBSCRIPTION = `
  subscription JwmIssueDeletedSubscription(
    $cloudId: ID!
    $projectId: String!
  ) {
    jira {
      onIssueDeletedByProject(cloudId: $cloudId, projectId: $projectId) {
        resource
      }
    }
  }
`;

import {
	type BusinessIssueField,
	useIssueTypesAndFields,
} from '../../services/issue-types-and-fields';

export const useFindField = (predicate: (value: BusinessIssueField) => boolean) => {
	const {
		data: { fields },
		loading,
		error,
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const field = fields.find(predicate) ?? null;

	return {
		data: field,
		loading,
		error,
	};
};

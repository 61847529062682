import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueTransitionSuccessful: {
		id: 'issue-transition-use-show-flag.use-show-flag.issue-transition-successful',
		defaultMessage: 'Issue has been updated.',
		description: 'message to show to user when the transition is successful',
	},
	issueTransitionSuccessfulWithIssueKeyAndStatus: {
		id: 'issue-transition-use-show-flag.use-show-flag.issue-transition-successful-with-issue-key-and-status',
		defaultMessage: '{issueKey} moved to {status}.',
		description:
			'message to show to user when the transition is successful. {issueKey} is the key for the issue. {status} is the key for the status where the issue has been moved',
	},
	issueTransitionSuccessfulFlagDescription: {
		id: 'issue-transition-use-show-flag.use-show-flag.issue-transition-successful-flag-description',
		defaultMessage: 'Kudos for keeping things up-to-date!',
		description: 'description message when the transition is successful',
	},
	issueTransitionFailure: {
		id: 'issue-transition-use-show-flag.use-show-flag.issue-transition-failure',
		defaultMessage: 'Issue could not be updated.',
		description: 'message to show to user when the transition fails',
	},
	issueTransitionFailureWithKey: {
		id: 'issue-transition-use-show-flag.use-show-flag.issue-transition-failure-with-key',
		defaultMessage: "We couldn't update {issueKey}.",
		description:
			'message to show to user when the transition fails. {issueKey} is the key for the issue',
	},
	viewIssue: {
		id: 'issue-transition-use-show-flag.use-show-flag.view-issue',
		defaultMessage: 'View issue',
		description: 'Link text to redirect user to view issue',
	},
});

import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';

export const PACKAGE_NAME = 'jiraBusinessFilters';

export const OPTIMISTIC_ID = 'optimistic-filter-id';

export const CREATE_FILTER_EXPERIENCE: ExperienceDetails = {
	experience: 'filtersCreateSavedFilters',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const UPDATE_FILTER_EXPERIENCE: ExperienceDetails = {
	experience: 'filtersUpdateSavedFilters',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const DELETE_FILTER_EXPERIENCE: ExperienceDetails = {
	experience: 'filtersDeleteSavedFilters',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const STAR_FILTER_EXPERIENCE: ExperienceDetails = {
	experience: 'filtersStarSavedFilters',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const SAVED_FILTER_EXPERIENCE: ExperienceDetails = {
	experience: 'filtersLoadSavedFilters',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};

export const EMPTY_FILTER_VALUE = null;

export const SIMPLE_SEARCH_PARAM = 'text';

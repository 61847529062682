import { metrics } from '@atlassian/browser-metrics';

export const issueTransitionModalLoad = metrics.interaction({
	key: 'issue-transition-modal',
	featureFlags: ['ui-modifications-on-issue-transition-view_7z1g0'],
});

export const issueTransittionModalLoadSubsequent = metrics.interaction({
	key: 'issue-transition-modal-subsequent',
	featureFlags: ['ui-modifications-on-issue-transition-view_7z1g0'],
});

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@compiled/react';
import { useVirtualizer, type Virtualizer } from '@tanstack/react-virtual';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { ExperienceSuccess } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useSimpleSearch } from '@atlassian/jira-business-filters/src/controllers/simple-search/index.tsx';
import { useCanCreateIssueInStatus } from '@atlassian/jira-business-issue-create/src/controllers/can-create-issue-in-status/index.tsx';
import { useWorkflows } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import { isSafari, isFirefox } from '@atlassian/jira-common-util-browser';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsTailoredOnboardingM2ForQuickstartEnabled } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-onboarding-personalisation';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { TailorBoardColumnsAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-board-view/async';
import {
	CARD_DND_TYPE,
	EMPTY_BOARD_HEIGHT,
	VIEW_EXPERIENCE,
	COLLAPSED_COLUMN_FIXED_WIDTH,
	COLUMN_FIXED_WIDTH,
	GAP_BETWEEN_COLUMNS,
} from '../../../common/constants';
import { useActiveWorkflow } from '../../../controllers/active-workflow';
import type { CardDragMeta } from '../../../controllers/drag-and-drop/use-draggable-card';
import { useDraggableColumn } from '../../../controllers/drag-and-drop/use-draggable-column';
import { useExpandCollapse } from '../../../controllers/expand-collapse-columns';
import { useGroupByField } from '../../../controllers/group-by';
import { useIssueGroup } from '../../../controllers/issues-by-group';
import { useIsWorkflowOperationInProgress } from '../../../controllers/workflow-actions';
import Card from './card';
import ColumnHeader from './column-header';
import InlineCreate from './inline-create';
import InvalidDropTargetMessage from './invalid-drop-target-message';
import TransitionZones from './transition-zones';

const isSafariBrowser = isSafari();

type Props = {
	columnsNumber: number;
	columnIndex: number;
};

const renderCardDropIndicator = (
	cardsCount: number,
	cardDragMeta: CardDragMeta | null,
	virtualizer: Virtualizer<HTMLDivElement, Element>,
) => {
	if (!cardDragMeta) {
		return null;
	}

	const virtualItem = virtualizer
		.getVirtualItems()
		.find((item) => item.index === cardDragMeta.cardIndex);

	if (!virtualItem) {
		return null;
	}

	const isAtVeryTop = cardDragMeta.cardIndex === 0 && cardDragMeta.closestEdge === 'top';
	const isAtVeryBottom =
		cardDragMeta.cardIndex === cardsCount - 1 && cardDragMeta.closestEdge === 'bottom';

	let start = cardDragMeta.closestEdge === 'top' ? virtualItem.start - 3 : virtualItem.end + 1;

	if (isAtVeryTop) {
		// makes sure the drop indicator is not clipped
		start = 3;
	} else if (isAtVeryBottom) {
		// makes sure the drop indicator doesn't cause a scrollbar
		start -= 6;
	}

	return <CardDropIndicator start={start} />;
};

const BoardColumn = ({ columnsNumber, columnIndex }: Props) => {
	const project = useProject();
	const { issues, group } = useIssueGroup();

	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();

	const [simpleSearch] = useSimpleSearch();
	const checkCanCreateIssueInStatus = useCanCreateIssueInStatus();
	const [siblingCreateIssueId, setSiblingCreateIssueId] = useState<number | null>(null);
	const workflows = useWorkflows();
	const [activeWorkflowId] = useActiveWorkflow();
	const [cardContainer, setCardContainer] = useState<HTMLDivElement | null>(null);
	const columnDropTargetRef = useRef<HTMLDivElement | null>(null);
	const columnHandleRef = useRef<HTMLDivElement | null>(null);
	const [cardDragMeta, setCardDragMeta] = useState<CardDragMeta | null>(null);

	const { isCollapsed } = useExpandCollapse(group.id);

	const groupBy = useGroupByField();
	const isGroupedByStatus = groupBy === GROUP_BY_STATUS;

	const isEnabledForTailoredOnboardingM2 = fg('jira_tailored_onboarding_jtbd_feature_gate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsTailoredOnboardingM2ForQuickstartEnabled()
		: false;

	const { canDropInGroup, closestEdge, dragPreviewState, isCardOverColumn, isDraggingColumn } =
		useDraggableColumn({
			columnDropTargetRef,
			columnHandleRef,
			group,
			index: columnIndex,
		});

	const shouldShowInvalidDropTargetMessage = !isCollapsed && canDropInGroup === 'no';

	const virtualizer = useVirtualizer({
		count: isCollapsed ? 0 : issues.length,
		getScrollElement: () => cardContainer,
		estimateSize: () => 300,
		overscan: 2,
		gap: 4,
		getItemKey: useCallback((index: number) => issues[index].id, [issues]),
	});

	useEffect(() => {
		if (!cardContainer) {
			return;
		}

		return autoScrollForElements({
			canScroll: ({ source }) => source.data.type === CARD_DND_TYPE,
			element: cardContainer,
			getAllowedAxis: () => 'vertical',
		});
	}, [cardContainer]);

	const scrollToLastElement = useCallback(() => {
		const issueCount = issues.length;

		if (issueCount === 0) {
			return;
		}

		virtualizer.scrollToIndex(issueCount, { align: 'start' });
	}, [issues.length, virtualizer]);

	const textHighlight = useMemo(
		() => (simpleSearch != null ? [simpleSearch] : undefined),
		[simpleSearch],
	);

	const canCreateIssues = useMemo(() => {
		if (isCollapsed) {
			return false;
		}

		if (!project.permissions.createIssues) {
			return false;
		}

		if (
			isGroupedByStatus &&
			!checkCanCreateIssueInStatus(
				group.id,
				workflows?.find((workflow) => workflow.workflowId === activeWorkflowId),
			)
		) {
			return false;
		}

		return true;
	}, [
		activeWorkflowId,
		checkCanCreateIssueInStatus,
		group.id,
		isCollapsed,
		isGroupedByStatus,
		project.permissions.createIssues,
		workflows,
	]);

	const isDropDisabled =
		canDropInGroup === 'no' || (isCollapsed && canDropInGroup === 'choice-required');

	return (
		<>
			<Box xcss={columnDropTargetStyles} ref={columnDropTargetRef}>
				<Column
					isCardOverColumn={isCardOverColumn}
					isCollapsed={isCollapsed}
					isDragging={isDraggingColumn}
					isDropDisabled={isDropDisabled}
					isShowingInvalidDropTargetMessage={shouldShowInvalidDropTargetMessage}
					isFixEmptyState={fg('fun-1174_fix_board_empty_state')}
					data-testid="work-management-board.ui.board.column"
				>
					{isEnabledForTailoredOnboardingM2 ? (
						<ConditionalNudgeWrapper
							conditionsToApplyWrapper={
								isGroupedByStatus && (columnsNumber > 1 ? columnIndex === 1 : columnIndex === 0)
							}
							Wrapper={TailorBoardColumnsAsync}
						>
							<ColumnHeader
								key={group.name}
								dragHandleRef={columnHandleRef}
								group={group}
								isCardOverColumn={isCardOverColumn}
								isDragPreview={false}
								isDropDisabled={isDropDisabled}
								issueCount={issues.length}
							/>
						</ConditionalNudgeWrapper>
					) : (
						<ColumnHeader
							key={group.name}
							dragHandleRef={columnHandleRef}
							group={group}
							isCardOverColumn={isCardOverColumn}
							isDragPreview={false}
							isDropDisabled={isDropDisabled}
							issueCount={issues.length}
						/>
					)}

					{!isCollapsed && issues.length > 0 && (
						<ScrollContainer
							isDragging={isDraggingColumn}
							isScrollbarForced={isFirefox()}
							ref={setCardContainer}
							data-testid="work-management-board.ui.board.column.scroll-container"
						>
							<VirtualCardsContainer height={virtualizer.getTotalSize()}>
								{virtualizer.getVirtualItems().map((virtualItem) => (
									<VirtualCardWrapper
										key={virtualItem.key}
										data-index={virtualItem.index}
										start={virtualItem.start}
										ref={virtualizer.measureElement}
									>
										<Card
											key={virtualItem.key}
											draggableIndex={virtualItem.index}
											canCreateIssues={canCreateIssues}
											group={group}
											issue={issues[virtualItem.index]}
											highlight={textHighlight}
											isSiblingCreateFormOpen={
												siblingCreateIssueId === issues[virtualItem.index].id
											}
											toggleSiblingCreateForm={setSiblingCreateIssueId}
											onCardDragChange={setCardDragMeta}
										/>
									</VirtualCardWrapper>
								))}

								{renderCardDropIndicator(issues.length, cardDragMeta, virtualizer)}

								<ExperienceSuccess experience={VIEW_EXPERIENCE} />
							</VirtualCardsContainer>
						</ScrollContainer>
					)}

					{shouldShowInvalidDropTargetMessage && <InvalidDropTargetMessage />}

					{!isCollapsed && ['choice-available', 'choice-required'].includes(canDropInGroup) && (
						<TransitionZones group={group} />
					)}

					{canCreateIssues && (
						<InlineCreate group={group} scrollToLastElement={scrollToLastElement} />
					)}

					{isWorkflowOperationInProgress && (
						<Box xcss={overlayStyles} testId="work-management-board.ui.board.column.overlay" />
					)}

					{closestEdge && (
						<ColumnDropIndicatorWrapper>
							<DropIndicator edge={closestEdge} gap={`${GAP_BETWEEN_COLUMNS}px`} />
						</ColumnDropIndicatorWrapper>
					)}
				</Column>
			</Box>

			{dragPreviewState.type === 'preview' && isDraggingColumn
				? createPortal(
						<ColumnDragPreviewWrapper isCollapsed={isCollapsed} isTilted={!isSafariBrowser}>
							<ColumnHeader
								key={group.name}
								group={group}
								isCardOverColumn={isCardOverColumn}
								isDragPreview
								isDropDisabled={false}
								issueCount={issues.length}
							/>
						</ColumnDragPreviewWrapper>,
						dragPreviewState.container,
					)
				: null}
		</>
	);
};

export default memo(BoardColumn);

const columnDropTargetStyles = xcss({
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnDragPreviewWrapper = styled.div<{
	isCollapsed: boolean;
	isTilted: boolean;
}>(
	{
		backgroundColor: token('elevation.surface.sunken', '#F4F5F7'),
		borderRadius: token('border.radius.200', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${COLUMN_FIXED_WIDTH}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		rotate: ({ isTilted }) => (isTilted ? '4deg' : '0deg'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed }) =>
		isCollapsed && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			width: `${COLLAPSED_COLUMN_FIXED_WIDTH}px`,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnDropIndicatorWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-border-selected': Tokens.COLOR_BORDER_SELECTED,
	height: '100vh',
	// sets drop indicator length to always be the max possible column height without changing adjacent column heights themselves
	position: 'absolute',
	// we need this so the drop indicator only shows at centre position of two adjacent columns
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Column = styled.div<{
	isCardOverColumn: boolean;
	isCollapsed: boolean;
	isDragging: boolean;
	isDropDisabled: boolean;
	isShowingInvalidDropTargetMessage: boolean;
	isFixEmptyState: boolean;
}>(
	{
		backgroundColor: token('elevation.surface.sunken', '#F4F5F7'),
		transition:
			'background-color 0.5s cubic-bezier(0.15, 1.0, 0.3, 1.0), outline-color 0.5s cubic-bezier(0.15, 1.0, 0.3, 1.0)',
		borderRadius: token('border.radius.200', '8px'),
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'column',
		maxHeight: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minHeight: ({ isShowingInvalidDropTargetMessage }) =>
			isShowingInvalidDropTargetMessage ? '240px' : undefined,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${COLUMN_FIXED_WIDTH}px`,
		position: 'relative',
		outlineColor: 'transparent',
		outlineOffset: 0,
		outlineStyle: 'solid',
		outlineWidth: token('border.width.outline', '2px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCardOverColumn }) =>
		isCardOverColumn && {
			backgroundColor: token('color.background.selected.hovered', '#CCE0FF'),
			outlineColor: token('color.border.selected', '#0C66E4'),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed, isFixEmptyState }) =>
		isCollapsed &&
		!isFixEmptyState && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			width: `${COLLAPSED_COLUMN_FIXED_WIDTH}px`,
			maxHeight: '320px',
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isCollapsed, isFixEmptyState }) =>
		isCollapsed &&
		isFixEmptyState && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			width: `${COLLAPSED_COLUMN_FIXED_WIDTH}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
			maxHeight: `${EMPTY_BOARD_HEIGHT}px`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDropDisabled }) =>
		isDropDisabled && {
			backgroundColor: token('color.background.danger', '#FFECEB'),
			outlineColor: token('color.border.danger', '#E2483D'),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDragging }) =>
		isDragging && {
			opacity: token('opacity.disabled', '0.4'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ScrollContainer = styled.div<{
	isDragging: boolean;
	isScrollbarForced: boolean;
}>(
	{
		flex: '1 1 0%',
		minHeight: '40px',
		overflowX: 'hidden',
		// Firefox has a bug in macOS where the scrollbar will flicker if the card content is at the edge of overflowing
		// so to prevent that, always show the scrollbar gutter in columns if scrollbars are set to be visible
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		overflowY: ({ isScrollbarForced }) => (isScrollbarForced ? 'scroll' : 'auto'),
		// Preserves the card shadows from clipping
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingTop: '1px',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingBottom: '1px',
		paddingRight: token('space.050', '4px'),
		// We need this to give spacing for the inline creation toggle icon to not be clipped by the overflowing parent
		paddingLeft: token('space.250', '20px'),
		marginLeft: token('space.negative.200', '-16px'),
		marginBottom: token('space.050', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDragging }) =>
		isDragging && {
			paddingLeft: token('space.050', '4px'),
			marginLeft: 0,
			// makes sure that the column drag preview doesn't have a scrollbar when column is not collapsed
			overflow: 'hidden',
		},
);

const overlayStyles = xcss({
	position: 'absolute',
	left: '0px',
	right: '0px',
	top: '0px',
	bottom: '0px',
	background: token('color.background.disabled', 'rgba(235, 236, 240, 0.7)'),
	zIndex: 'blanket',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VirtualCardsContainer = styled.div<{ height: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VirtualCardWrapper = styled.div<{
	start: number;
}>({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateY(${start}px)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardDropIndicator = styled.div<{ start: number }>({
	display: 'block',
	position: 'absolute',
	zIndex: 2,
	pointerEvents: 'none',
	background: token('color.border.selected', '#0c66e4'),
	height: '2px',
	left: '4px',
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateY(${start}px)`,
	// Terminal
	'&::before': {
		content: '""',
		width: '8px',
		height: '8px',
		left: '-6px',
		top: '-3px',
		boxSizing: 'border-box',
		position: 'absolute',
		border: `2px solid ${token('color.border.selected', '#0c66e4')}`,
		borderRadius: '50%',
	},
});

import React, { memo, useCallback, useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ISSUE_KEY_ID } from '../../../../../../common/constants';
import type { BoardIssue } from '../../../../../../common/types';
import { useDeleteIssue } from '../../../../../../controllers/delete-issue';
import messages from './messages';

type Props = {
	issue: BoardIssue;
	onClose: () => void;
};

const IssueDeleteModal = ({ issue, onClose }: Props) => {
	const { formatMessage } = useIntl();
	const itemKey = issue.fields[ISSUE_KEY_ID].value;
	const deleteIssue = useDeleteIssue();
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDelete = useCallback(async () => {
		setIsDeleting(true);

		try {
			await deleteIssue(issue);
		} catch (error: unknown) {
			setIsDeleting(false);
		} finally {
			onClose();
		}
	}, [deleteIssue, issue, onClose]);

	return (
		<ShortcutScope>
			<Modal width="small" onClose={onClose}>
				<ModalHeader>
					<ModalTitle appearance="danger">{formatMessage(messages.title, { itemKey })}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text as="p">{formatMessage(messages.warning)}</Text>
					<Text as="p">{formatMessage(messages.recommendation)}</Text>
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={onClose} isDisabled={isDeleting}>
						{formatMessage(messages.cancel)}
					</Button>
					<LoadingButton appearance="danger" onClick={handleDelete} isLoading={isDeleting}>
						{formatMessage(messages.delete)}
					</LoadingButton>
				</ModalFooter>
			</Modal>
		</ShortcutScope>
	);
};

export default memo(IssueDeleteModal);

import urlParse from 'url-parse';

/**
 * Server allows for funky project keys: https://confluence.atlassian.com/adminjiraserver073/changing-the-project-key-format-861253229.html
 *
 * Option to change project key for Cloud seems to have been disabled,
 * but we will likely want to honour custom project keys for instances migrated from Server.
 */
export const ISSUEKEY_REGEX = '([A-Z][A-Z0-9_]+-\\d+)';

/**
 * Returns the issue key if the URL is a valid full page Jira issue URL. It may also include a trailing
 * hash fragment. A matching URL must look like:
 * <host><baseurl>/browse/<issuekey><#hash?>
 * e.g. https://my-jira-instance.atlassian.net/browse/FOO-123#xyz
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (url: string, host: string): string | null => {
	const parsedUrl = urlParse(url, {});
	const isMatchingProtocol = parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
	const isMatchingHost = parsedUrl.host === host;
	const matches = parsedUrl.pathname.match(new RegExp(`^\\/browse\\/${ISSUEKEY_REGEX}\\/?$`));
	const issueKey = matches && matches.length > 1 ? matches[1] : null;
	return (isMatchingProtocol && isMatchingHost && issueKey) || null;
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreButton: {
		id: 'business-more-menu.more-menu.more-menu-core.trigger.more-button',
		defaultMessage: 'More',
		description: 'More menu options button text',
	},
	moreButtonLabel: {
		id: 'business-more-menu.more-menu.more-menu-core.trigger.more-button-label',
		defaultMessage: 'More actions on the view options',
		description:
			'Description of the more button in the header section that‘ll be used by screen reader technologies',
	},
});

import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import Placeholder from '@atlassian/jira-placeholder';
import { SHARE_PROJECT_NUDGE_ID } from '../../common/constants';
import type { WrapperProps } from '../../common/types';
import type ShareProjectNudge from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyShareProjectNudge = lazyAfterPaint<typeof ShareProjectNudge>(
	() => import(/* webpackChunkName: "async-jwm-share-project-nudge" */ './index'),
	{
		ssr: false,
	},
);

export const AsyncShareProjectNudge = ({ children }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id={SHARE_PROJECT_NUDGE_ID}
		fallback={children}
		attributes={{
			nudgeId: SHARE_PROJECT_NUDGE_ID,
		}}
	>
		<Placeholder name="jwm-share-project-nudge" fallback={children}>
			<LazyShareProjectNudge>{children}</LazyShareProjectNudge>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

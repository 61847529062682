import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noAssignee: {
		id: 'work-management-board.board.column.column-header.header-content.no-assignee',
		defaultMessage: 'Unassigned',
		description: 'Label for board group column for items without assignee',
	},
	noPriority: {
		id: 'work-management-board.board.column.column-header.header-content.no-priority',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Unprioritized',
		description: 'Label for board group column for items without priority',
	},
	noCategory: {
		id: 'work-management-board.board.column.column-header.header-content.no-category',
		defaultMessage: 'Uncategorized',
		description: 'Label for board group column for items without category',
	},
});

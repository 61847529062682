import { colors } from '@atlaskit/theme';

// Grey
export const N100 = '#F0F1F4';
export const N200 = 'rgba(9, 30, 66, 0.14)';
export const N300 = colors.N60A;
export const N400 = '#44546F';
export const N500 = colors.N800;

// Purple
export const P100 = '#F3F0FF';
export const P200 = '#DFD8FD';
export const P300 = '#9F8FEF';
export const P400 = '#5E4DB2';
export const P500 = '#352C63';

// Blue
export const B100 = '#E9F2FF';
export const B200 = '#CCE0FF';
export const B300 = '#579DFF';
export const B400 = '#0055CC';
export const B500 = '#09326C';

// Teal
export const T100 = '#E3FAFC';
export const T200 = '#C1F0F5';
export const T300 = '#60C6D2';
export const T400 = '#206B74';
export const T500 = '#1D474C';

// Green
export const G100 = '#DFFCF0';
export const G200 = '#BAF3DB';
export const G300 = '#4BCE97';
export const G400 = '#216E4E';
export const G500 = '#164B35';

// Lime
export const L100 = '#EEFBDA';
export const L200 = '#D3F1A7';
export const L300 = '#94C748';
export const L400 = '#4C6B1F';
export const L500 = '#37471F';

// Yellow
export const Y100 = '#FFF7D6';
export const Y200 = colors.Y75;
export const Y300 = '#E2B203';
export const Y400 = '#7F5F01';
export const Y500 = '#533F04';

// Orange
export const O100 = '#FFF4E5';
export const O200 = '#FFE2BD';
export const O300 = '#FAA53D';
export const O400 = '#974F0C';
export const O500 = '#5F3811';

// Red
export const R100 = '#FFEDEB';
export const R200 = '#FFD2CC';
export const R300 = '#F87462';
export const R400 = '#AE2A19';
export const R500 = '#601E16';

// Magenta
export const M100 = '#FFECF8';
export const M200 = '#FDD0EC';
export const M300 = '#F797D2';
export const M400 = '#943D73';
export const M500 = '#50253F';

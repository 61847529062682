import { useMemo, useCallback } from 'react';
import type { ColumnsOrder } from '@atlassian/jira-business-common/src/common/types/group-by.tsx';
import { GROUP_BY_PRIORITY } from '@atlassian/jira-business-constants';
import { COLUMNS_ORDER_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { NULL_GROUP_KEY } from '../../common/constants';
import type { Group } from '../../common/types';
import { useGroupByField } from '../group-by';

export const useGroupsOrder = () => {
	const groupBy = useGroupByField();
	const [orderedGroupsByGroupBy, setGroupsOrderPreference] =
		useViewPreference(COLUMNS_ORDER_PREFERENCE);

	const setGroupOrder = useCallback(
		(newGroupsOrderKeys: string[]) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			setGroupsOrderPreference({
				...orderedGroupsByGroupBy,
				[groupBy]: newGroupsOrderKeys,
			} as ColumnsOrder);
		},
		[groupBy, orderedGroupsByGroupBy, setGroupsOrderPreference],
	);

	const orderedGroupIds = useMemo(
		() => orderedGroupsByGroupBy?.[groupBy] ?? null,
		[orderedGroupsByGroupBy, groupBy],
	);

	return [orderedGroupIds, setGroupOrder] as const;
};

export const useSortGroups = () => {
	const [orderedGroupsKeys] = useGroupsOrder();
	const groupBy = useGroupByField();

	return useCallback(
		(groups: Group[]) => {
			const indexInOrder = (groupId: string, order: string[]) => {
				const index = order.indexOf(groupId);
				return index >= 0 ? index : groups.length;
			};

			if (orderedGroupsKeys == null) {
				if (groupBy === GROUP_BY_PRIORITY) {
					// Priority groups are already sorted based on the order in the field configuration
					return groups;
				}
				return groups.sort((groupA, groupB) => {
					if (groupA.id === NULL_GROUP_KEY) {
						return -1;
					}
					if (groupB.id === NULL_GROUP_KEY) {
						return 1;
					}
					// Sort fields alphabetically
					return groupA.name.localeCompare(groupB.name);
				});
			}

			return groups.sort(
				(groupA, groupB) =>
					indexInOrder(groupA.id, orderedGroupsKeys) - indexInOrder(groupB.id, orderedGroupsKeys),
			);
		},
		[groupBy, orderedGroupsKeys],
	);
};

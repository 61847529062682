export const INITIAL_DATA_QUERY = `
query JWMFilterValuesByGroupItems($fieldId: ID!, $jql: String!, $first: Int!) {
  items: jwmItemGroups(jql: $jql, groupByFieldId: $fieldId, fieldIds: [], first: $first) {
    totalCount
    edges {
      node {
        id
        field {
          __typename
          ... on FloatCustomField {
            floatValue
          }
        }
      }
    }
  }
}
`;

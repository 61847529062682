import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { IssueLinksStats as IssueLinkStatsComponent } from './ui';
import type { IssueLinksStatsProps } from './ui/types';

export const IssueLinksStats = (props: IssueLinksStatsProps) => (
	<JSErrorBoundary
		id="issue-links-stats-business"
		packageName="jiraIssueLinksStatsBusiness"
		teamName="jira-werewolves"
		fallback="flag"
	>
		<IssueLinkStatsComponent {...props} />
	</JSErrorBoundary>
);

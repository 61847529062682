import React, { useCallback, useState, useMemo, memo, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

const BUTTON_SIZE = 24;
const DIVIDER_SIZE = 2;
const ROW = 'row';
const COLUMN = 'column';

type Direction = typeof ROW | typeof COLUMN;

export type Props = {
	direction: Direction;
	label: string;
	onClick: (event: MouseEvent<HTMLButtonElement>) => void;
	onMouseEnter?: (event: MouseEvent<HTMLButtonElement>) => void;
	onMouseLeave?: (event: MouseEvent<HTMLButtonElement>) => void;
	onMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
	onMouseUp?: (event: MouseEvent<HTMLButtonElement>) => void;
	forceVisibility?: boolean;
	parentSize?: number | string;
	position?: number;
	offset?: number;
};

const InlineCreateTrigger = ({
	direction,
	label,
	onClick,
	onMouseEnter,
	onMouseLeave,
	onMouseDown,
	onMouseUp,
	forceVisibility = false,
	parentSize = '100%',
	position = 0,
	offset = 0,
}: Props) => {
	const [isButtonHovered, setIsButtonHovered] = useState(false);

	const handleMouseEnterButton = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			onMouseEnter && onMouseEnter(event);
			setIsButtonHovered(true);
		},
		[onMouseEnter],
	);

	const handleMouseLeaveButton = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			onMouseLeave && onMouseLeave(event);
			setIsButtonHovered(false);
		},
		[onMouseLeave],
	);

	const Container = useMemo(
		() => (direction === ROW ? RowContainer : ColumnContainer),
		[direction],
	);

	return (
		<Container
			isButtonHovered={isButtonHovered}
			forceVisibility={forceVisibility}
			parentSize={typeof parentSize === 'number' ? `${parentSize}px` : parentSize}
			position={position}
			offset={offset}
		>
			<Tooltip content={label}>
				<TriggerButton
					data-testid="business-inline-create-trigger.ui.trigger-button"
					onClick={onClick}
					onMouseDown={onMouseDown}
					onMouseEnter={handleMouseEnterButton}
					onMouseLeave={handleMouseLeaveButton}
					onMouseUp={onMouseUp}
				>
					<EditorAddIcon label={label} size="medium" />
				</TriggerButton>
			</Tooltip>
		</Container>
	);
};

export default memo(InlineCreateTrigger);

type ContainerProps = {
	isButtonHovered: boolean;
	forceVisibility: boolean;
	parentSize: number | string;
	position: number;
	offset: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div<ContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.navigation,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ forceVisibility }) => (forceVisibility ? 1 : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isButtonHovered, forceVisibility, parentSize, offset }) =>
		isButtonHovered || !forceVisibility ? `calc(${parentSize} +  ${offset}px)` : `${BUTTON_SIZE}px`,
	height: `${BUTTON_SIZE}px`,
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ position }) => `${position - BUTTON_SIZE * 0.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ offset }) => `-${offset}px`,
	transition: 'opacity 100ms ease-in-out',
	'&:hover, &:focus, &:focus-within': {
		opacity: 1,
	},
	'&:focus-within': {
		'&::after': {
			opacity: 1,
		},
	},
	'&::after': {
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border.focused', colors.B300),
		height: `${DIVIDER_SIZE}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isButtonHovered }) => (isButtonHovered ? 1 : 0),
		transition: 'opacity 100ms linear',
		width: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnContainer = styled.div<ContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.navigation,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ forceVisibility }) => (forceVisibility ? 1 : 0),
	width: `${BUTTON_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isButtonHovered, forceVisibility, parentSize, offset }) =>
		isButtonHovered || !forceVisibility ? `calc(${parentSize} + ${offset}px)` : `${BUTTON_SIZE}px`,
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ offset }) => `-${offset}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ position }) => `${position - BUTTON_SIZE * 0.5}px`,
	transition: 'opacity 100ms ease-in-out',
	'&:hover, &:focus, &:focus-within': {
		opacity: 1,
	},
	'&:focus-within': {
		'&::after': {
			opacity: 1,
		},
	},
	'&::after': {
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border.focused', colors.B300),
		height: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isButtonHovered }) => (isButtonHovered ? 1 : 0),
		transition: 'opacity 100ms linear',
		width: `${DIVIDER_SIZE}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerButton = styled.button({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: `${BUTTON_SIZE}px`,
	width: `${BUTTON_SIZE}px`,
	position: 'relative',
	boxShadow: `${token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0px 8px 12px 0px ${colors.N40A} , 0px 0px 1px 0px ${colors.N40A}`,
	)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.border', colors.N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface.overlay', colors.N0),
	cursor: 'pointer',
	borderRadius: token('border.radius', '3px'),
	'&:hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.overlay.hovered', colors.N20),
		outline: 'none',
	},
	'&:focus-visible, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		border: `2px solid ${token('color.border.focused', colors.B300)}`,
	},
});

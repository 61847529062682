import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'work-management-board.board.column.card.card-actions.delete-issue-modal.title',
		defaultMessage: 'Delete {itemKey}?',
		description:
			'Title of the confirmation modal asking if user really wants to delete an item from the Agility board',
	},
	warning: {
		id: 'work-management-board.board.column.card.card-actions.delete-issue-modal.warning',
		defaultMessage:
			"You're about to permanently delete this item and its comments, attachments, and all of its data.",
		description:
			'Warning displayed in item delete confirmation modal detailing item delete as an irrecoverable action.',
	},
	recommendation: {
		id: 'work-management-board.board.column.card.card-actions.delete-issue-modal.recommendation',
		defaultMessage: "If you don't want to delete it, you can resolve or close it instead.",
		description:
			'Displayed in item delete confirmation modal suggesting alternative options to deleting an item',
	},
	delete: {
		id: 'work-management-board.board.column.card.card-actions.delete-issue-modal.delete',
		defaultMessage: 'Delete',
		description: 'Text on primary action, Delete button in item delete confirmation modal',
	},
	cancel: {
		id: 'work-management-board.board.column.card.card-actions.delete-issue-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Text on secondary action, Cancel button in item delete confirmation modal',
	},
});

import React, {
	createContext,
	useContext,
	useCallback,
	useState,
	useMemo,
	type ReactNode,
} from 'react';

export const OPERATIONS = {
	DELETING: 'deleting-status',
	ADDING: 'adding-status',
	RENAMING: 'renaming-status',
} as const;

export type Operation = (typeof OPERATIONS)[keyof typeof OPERATIONS];

type Context = {
	operationInProgress: Operation | null;
	statusId: string | null;
	setStatusId: (statusId: string | null) => void;
	setOperationInProgress: (operation: Operation | null) => void;
};

const WorkflowActionsContext = createContext<Context | null>(null);

export const WorkflowActionsProvider = ({ children }: { children: ReactNode }) => {
	const [operationInProgress, setOperationInProgress] = useState<
		Context['operationInProgress'] | null
	>(null);
	const [statusId, setStatusId] = useState<Context['statusId'] | null>(null);

	const value = useMemo(
		() => ({
			statusId,
			operationInProgress,
			setStatusId,
			setOperationInProgress,
		}),
		[statusId, operationInProgress],
	);

	return (
		<WorkflowActionsContext.Provider value={value}>{children}</WorkflowActionsContext.Provider>
	);
};

export const useWorkflowActionsContext = () => {
	const context = useContext(WorkflowActionsContext);

	if (!context) {
		throw new Error('WorkflowActionsContext must be used within a WorkflowActionsProvider');
	}

	return context;
};

export const useIsWorkflowOperationInProgress = () => {
	const context = useWorkflowActionsContext();
	return context.operationInProgress != null;
};

export const useWorkflowOperationStatusId = () => {
	const context = useWorkflowActionsContext();
	return context.statusId;
};

export const useStopWorkflowOperationInProgress = () => {
	const { setOperationInProgress, setStatusId } = useWorkflowActionsContext();

	return useCallback(() => {
		setOperationInProgress(null);
		setStatusId(null);
	}, [setOperationInProgress, setStatusId]);
};

export const useStartDeleteOperation = () => {
	const { setOperationInProgress } = useWorkflowActionsContext();

	return useCallback(() => {
		setOperationInProgress(OPERATIONS.DELETING);
	}, [setOperationInProgress]);
};

export const useStartAddOperation = () => {
	const { setOperationInProgress, setStatusId } = useWorkflowActionsContext();

	return useCallback(
		(statusId: string) => {
			setStatusId(statusId);
			setOperationInProgress(OPERATIONS.ADDING);
		},
		[setOperationInProgress, setStatusId],
	);
};

export const useStartRenameOperation = () => {
	const { setOperationInProgress, setStatusId } = useWorkflowActionsContext();

	return useCallback(
		(statusId: string) => {
			setStatusId(statusId);
			setOperationInProgress(OPERATIONS.RENAMING);
		},
		[setStatusId, setOperationInProgress],
	);
};

import React, { memo, useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import InlineCreateComponent, {
	type Props as InlineCreateProps,
} from '@atlassian/jira-business-issue-create/src/ui/index.tsx';
import type { JWMBoardInlineCreateIssue_createJiraBusinessIssue_item as CreatedBoardItem } from '@atlassian/jira-work-management-board/src/services/create-issue/__generated_apollo__/JWMBoardInlineCreateIssue.ts';
import type { Group } from '../../../../common/types';
import { useInlineCreateProps } from '../../../../controllers/inline-create';

const inlineCreateStyles: InlineCreateProps<CreatedBoardItem>['inlineCreateStyles'] = {
	borderRadius: token('border.radius', '3px'),
};

type Props = {
	group: Group;
	scrollToLastElement: () => void;
};

const InlineCreate = ({ group, scrollToLastElement }: Props) => {
	const onAddOptimisticIssue = useCallback(() => {
		// Only attempt to scroll to the last element after the optimistic issue is created
		requestAnimationFrame(scrollToLastElement);
	}, [scrollToLastElement]);

	const inlineCreateProps = useInlineCreateProps({
		group,
		onAddOptimisticIssue,
	});

	const handleToggleForm = useCallback(
		(isFormVisible: boolean) => {
			if (isFormVisible) {
				scrollToLastElement();
			}
		},
		[scrollToLastElement],
	);

	return (
		<Box xcss={wrapperStyles}>
			<InlineCreateComponent
				{...inlineCreateProps}
				location="boardFooter"
				inlineCreateStyles={inlineCreateStyles}
				onToggleForm={handleToggleForm}
			/>
		</Box>
	);
};

const wrapperStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	paddingBottom: 'space.050',
});

export default memo(InlineCreate);

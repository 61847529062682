import React, { type ReactNode, createContext, useContext } from 'react';

type BusinessEntityType = {
	name: string;
	id: string;
};

export type BusinessEntityContextProviderProps = {
	children: ReactNode;
	data: BusinessEntityType;
};

export type BusinessEntityContextType = {
	data: BusinessEntityType;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const BusinessEntityContext = createContext<BusinessEntityType>(null as any);

export const BusinessEntityProvider = ({ data, children }: BusinessEntityContextProviderProps) => (
	<BusinessEntityContext.Provider value={data}>{children}</BusinessEntityContext.Provider>
);

export const useBusinessEntity = (): BusinessEntityContextType => {
	const entity = useContext(BusinessEntityContext);

	return { data: entity };
};

const FIELD_VALUE_NODE_FRAGMENT = `
fragment FieldValueNodeFragment on JiraJqlFieldValue {
    jqlTerm
    displayName
    ... on JiraJqlUserFieldValue {
        user {
            picture
        }
    }
    ... on JiraJqlPriorityFieldValue {
        priority {
            iconUrl
        }
    }
    ... on JiraJqlIssueTypeFieldValue {
        issueTypes {
            issueTypeId
            avatar {
                medium
            }
            hierarchy {
                level
            }
        }
    }
    ... on JiraJqlStatusFieldValue {
        statusCategory {
            statusCategoryId
        }
    }
    ... on JiraJqlSprintFieldValue {
        sprint {
            sprintId
            name
        }
    }
    ... on JiraJqlGoalsFieldValue {
        goal {
            name
            status
        }
    }
    ... on JiraJqlIssueFieldValue {
        issue {
            fieldsByIdOrAlias(idsOrAliases: ["issuetype"]) {
                ... on JiraIssueTypeField {
                    issueType {
                        avatar {
                            medium
                        }
                    }
                }
            }
        }
    }
}
`;

const CATEGORY_CONNECTION_FRAGMENT = `
fragment CategoryConnection on JiraWorkManagementCategoryOptionConnection {
    edges {
        cursor
        node {
            option {
              value
            }
            color
        }
    }
    totalCount
}
`;

export const INITIAL_DATA_QUERY = `
query JwmInitialFilterValues($cloudId: ID!, $first: Int, $jql: String!, $shouldFetchSprints: Boolean = false, $shouldFetchSoftwareFields: Boolean = false, $sprintsJqlContext: String, $goalsJqlTerm: String!, $shouldFetchGoalsField: Boolean = false, $shouldFetchParentField: Boolean = false) {
    jira {
        jqlBuilder(cloudId: $cloudId) {
            labels: fieldValues(
                first: $first
                jqlTerm: "labels"
                jqlContext: $jql
                viewContext: JWM
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            components: fieldValues(
                first: $first
                jqlTerm: "component"
                jqlContext: $jql
                viewContext: JWM
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            priority: fieldValues(
                first: $first
                jqlTerm: "priority"
                jqlContext: $jql
                viewContext: JWM
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            users: fieldValues(
                first: $first
                jqlTerm: "assignee"
                jqlContext: $jql
                viewContext: JWM
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            recentlyUsedUsers(
                first: $first
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            issuetype: fieldValues(
                first: $first
                jqlTerm: "issuetype"
                jqlContext: $jql
                viewContext: JWM
            ) {
                edges {
                     cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            status: fieldValues(
                first: $first
                jqlTerm: "status"
                jqlContext: $jql
                viewContext: JWM
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            sprints: fieldValues(
                first: $first
                jqlTerm: "sprint"
                jqlContext: $sprintsJqlContext
                viewContext: JSW
            ) @include(if: $shouldFetchSprints) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            fixVersions: fieldValues(
                first: $first
                jqlTerm: "fixVersion"
                jqlContext: $jql
                viewContext: JWM
            ) @include(if: $shouldFetchSoftwareFields) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
            parent: fieldValues(
                first: $first
                jqlTerm: "parent"
                jqlContext: $jql
                viewContext: JSW
            ) @include(if: $shouldFetchParentField) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }

            goals: fieldValues(
                first: $first
                jqlTerm: $goalsJqlTerm
                jqlContext: $jql
                viewContext: JWM
            ) @include(if: $shouldFetchGoalsField) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
        }
        jwmCategoryOptions(jqlContext: $jql, first: $first) {
            ...CategoryConnection
        }
    }
}
${FIELD_VALUE_NODE_FRAGMENT}
${CATEGORY_CONNECTION_FRAGMENT}
`;

export const SEARCH_QUERY = `
query JwmSearchFilterValues($cloudId: ID!, $first: Int, $jqlTerm: String!, $jql: String!, $searchString: String, $after: String) {
    jira {
        jqlBuilder(cloudId: $cloudId) {
            fieldValues(
                first: $first
                jqlTerm: $jqlTerm
                jqlContext: $jql
                searchString: $searchString
                after: $after
                viewContext: JWM
            ) {
                edges {
                    cursor
                    node {
                        ...FieldValueNodeFragment
                    }
                }
                totalCount
            }
        }
    }
}
${FIELD_VALUE_NODE_FRAGMENT}
`;

export const CATEGORY_SEARCH_QUERY = `
query JwmCategorySearchFilterValues($first: Int, $jql: String!, $searchQuery: String, $after: String) {
    jira {       
        jwmCategoryOptions(jqlContext: $jql, first: $first, searchQuery: $searchQuery, after: $after) {
            ...CategoryConnection
        }
    }
}
${CATEGORY_CONNECTION_FRAGMENT}
`;

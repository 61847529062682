import { useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { NTH_PROJECT_CREATE } from '../../common/constants';
import { inviteUsersSessionStorage } from '../../common/util';

export const useShouldShowInviteUserModalBasedOnSessionStorage = (projectKey?: string) => {
	const shouldShowInviteUserModal = useMemo(
		() => inviteUsersSessionStorage.get(NTH_PROJECT_CREATE) === projectKey,
		[projectKey],
	);
	return (
		shouldShowInviteUserModal &&
		// Fire exposure event here
		expVal('invite-users-on-project-create-experiment', 'shouldShowModal', false)
	);
};

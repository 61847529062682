import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noPermissionToModify: {
		id: 'work-management-board.board.column-create.no-permission-to-modify',
		defaultMessage: 'Contact your admin to make changes to the board',
		description:
			'Message to be shown to user when they do not have permission to modify board columns',
	},
	createStatusLabel: {
		id: 'work-management-board.board.column-create.create-status-label',
		defaultMessage: 'Create status',
		description: 'Label for the create column button',
	},
});

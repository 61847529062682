import { RADIO_BUTTONS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { Sorting } from '../../../sorting/types';
import type { TransformedRadioSelectField } from '../types';

const getRadioSelectSorting: Sorting<TransformedRadioSelectField> = (field) =>
	field.value ? field.value.value : '';

export const radioSelectSortingHandler = {
	[RADIO_BUTTONS_CF_TYPE]: getRadioSelectSorting,
};

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const IssueTransitionLoadUFOExperience = new UFOExperience(
	'issue-transition-screen.transition-load',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.Custom,
		category: 'issue-transition-screen.transition-load',
		featureFlags: ['ui-modifications-on-issue-transition-view_7z1g0'],
	},
);

export const IssueTransitionSubmitUFOExpereince = new UFOExperience(
	'issue-transition-screen.transition-submit',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.Custom,
		category: 'issue-transition-screen.transition-sumbit',
	},
);

export const IssueTransitionUFOExperience = new UFOExperience(
	'issue-transition-screen.transition-issue',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.Custom,
		category: 'issue-transition-screen.transition-issue',
	},
);

import { useEffect } from 'react';
// eslint-disable-next-line jira/restricted/use-debounce
import { useDebouncedCallback } from 'use-debounce';
import { isPageVisible, supportedVisiblityEvent } from '@atlassian/jira-common-page-visibility';

type Props = {
	onChange: (arg1: { isVisible: boolean }) => void;
};

export const DEBOUNCE_INTERVAL = 500;

export const PageVisibility = ({ onChange }: Props) => {
	// debouncing the page visibility change to prevent when people switching tabs too fast
	// useDebouncedCallback will always call the latest parameter for every re-render
	// so even though `onChange` may change each re-render, only the last one will be called when debounce finishes
	const [onVisibilityChangeDebounced] = useDebouncedCallback(() => {
		onChange({ isVisible: isPageVisible() });
	}, DEBOUNCE_INTERVAL);

	useEffect(() => {
		window.addEventListener(supportedVisiblityEvent, onVisibilityChangeDebounced);
		return () => {
			window.removeEventListener(supportedVisiblityEvent, onVisibilityChangeDebounced);
		};
	}, [onVisibilityChangeDebounced]);

	return null;
};

import { CATEGORY_TYPE } from '@atlassian/jira-platform-field-config';
import { useIssueTypesAndFields } from '../../services/issue-types-and-fields';

export const useCategoryField = () => {
	const {
		data: { fields },
		loading,
		error,
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const categoryField = fields.find((field) => field.type === CATEGORY_TYPE) ?? null;

	return {
		data: categoryField,
		loading,
		error,
	};
};

import { useCallback } from 'react';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
	useExperienceAbort,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { CARD_ACTION_DELETE_EXPERIENCE, ISSUE_KEY_ID } from '../../common/constants';
import type { BoardIssue } from '../../common/types';
import {
	useDeleteIssue as useDeleteIssueService,
	IssueNotFound,
	PermissionDenied,
} from '../../services/delete-issue';
import { useIssueStoreActions } from '../board-issue-store';
import messages from './messages';

export const useDeleteIssue = () => {
	const deleteIssue = useDeleteIssueService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();
	const { removeIssues } = useIssueStoreActions();

	const startExperience = useExperienceStart(CARD_ACTION_DELETE_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(CARD_ACTION_DELETE_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(CARD_ACTION_DELETE_EXPERIENCE);
	const abortExperience = useExperienceAbort(CARD_ACTION_DELETE_EXPERIENCE);

	return useCallback(
		async (issue: BoardIssue) => {
			const issueId = issue.id;
			const issueKey = issue.fields[ISSUE_KEY_ID].value;

			startExperience();

			let isIssueDeleted = false;

			try {
				await deleteIssue({ issueKey });

				isIssueDeleted = true;

				markExperienceSuccess();
				fireTrackAnalytics(createAnalyticsEvent({ actionSubjectId: issueId }), 'issue deleted', {
					throughActionCard: true,
					issueId,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				// issue not found is not handled as an error as the issue is already gone
				if (error instanceof IssueNotFound) {
					isIssueDeleted = true;

					markExperienceSuccess();
				} else if (error instanceof PermissionDenied) {
					showFlag({
						type: 'error',
						title: messages.deletePermissionDefinedErrorTitle,
						description: messages.deletePermissionDefinedErrorDescription,
					});

					abortExperience('handled validation error');

					throw error;
				} else {
					showFlag({
						type: 'error',
						title: messages.deleteErrorTitle,
						description: messages.deleteErrorDescription,
					});

					markExperienceFailed('delete issue failed', error);

					throw error;
				}
			}

			if (isIssueDeleted) {
				removeIssues([issueId]);
				showFlag({
					type: 'success',
					title: messages.deleteSuccessTitle,
					description: messages.deleteSuccessDescription,
				});
			}
		},
		[
			abortExperience,
			createAnalyticsEvent,
			deleteIssue,
			markExperienceFailed,
			markExperienceSuccess,
			removeIssues,
			showFlag,
			startExperience,
		],
	);
};

import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useCollabStore } from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import { getPresenceNodeId } from '@atlassian/jira-business-collaboration/src/utils/get-presence-node-id/index.tsx';
import { SELECTED_ISSUE_PARAM, GROUP_BY_STATUS } from '@atlassian/jira-business-constants';
import { BusinessRealtime } from '@atlassian/jira-business-entity-common/src/ui/realtime/index.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { ErrorPage } from '@atlassian/jira-business-error-page';
import { ExperienceAbort } from '@atlassian/jira-business-experience-tracking';
import { ExperienceFailed } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useSimpleSearchJQL } from '@atlassian/jira-business-filters/src/controllers/simple-search/index.tsx';
import { ThemedFilterEmptyState } from '@atlassian/jira-business-filters/src/ui/themed-filter-empty-state/index.tsx';
import { ISSUE_DELETED_EVENT } from '@atlassian/jira-business-gqls-realtime/src/constants.tsx';
import type { GqlsRealtimeEvent } from '@atlassian/jira-business-gqls-realtime/src/types.tsx';
import { GqlsRealtime } from '@atlassian/jira-business-gqls-realtime/src/ui/index.tsx';
import { isIssueEvent } from '@atlassian/jira-business-gqls-realtime/src/utils.tsx';
import { PrefetchIssueDataController } from '@atlassian/jira-business-issue-view-critical-data-loader/src/ui/index.tsx';
import ModalIssueApp from '@atlassian/jira-business-issue-view/src/ui/modal-issue-app/index.tsx';
import { SidebarIssueView } from '@atlassian/jira-business-issue-view/src/ui/sidebar-issue-app/index.tsx';
import { PageVisibility } from '@atlassian/jira-business-page-visibility';
import { PerformanceAnalytics } from '@atlassian/jira-business-performance/src/ui/index.tsx';
import { RenderStartMark } from '@atlassian/jira-business-performance/src/ui/page-load/index.tsx';
import { SignupInviteModal } from '@atlassian/jira-business-signup-invite-modal/src/ui/index.tsx';
import { JWMSpaStatePageReady } from '@atlassian/jira-business-spa-state-page-ready/src/ui/index.tsx';
import { ThemedSpinner } from '@atlassian/jira-business-theme-components/src/ui/ThemedSpinner';
import { BUSINESS_BOARD } from '@atlassian/jira-common-constants/src/analytics-sources';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useShouldShowInviteUserModalBasedOnSessionStorage } from '@atlassian/jira-invite-users-on-project-create/src/controllers/use-should-show-modal';
import { ProjectInviteModalWithRecommendedUsers } from '@atlassian/jira-invite-users-on-project-create/src/ui/async.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { ISSUE_DELETED_EVENT as ISSUE_DELETED_EVENT_OLD } from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import type { IssueEventPayload } from '@atlassian/jira-realtime/src/common/types/payloads.tsx';
import { useQueryParam } from '@atlassian/jira-router';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOCustomData from '@atlassian/react-ufo/custom-data';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import {
	EMPTY_BOARD_HEIGHT,
	COLUMN_FIXED_WIDTH,
	GAP_BETWEEN_COLUMNS,
	VIEW_EXPERIENCE,
	ISSUE_KEY_ID,
} from '../../common/constants';
import type { Group } from '../../common/types';
import { useBoardData } from '../../controllers/board-data';
import { useFilterStatus } from '../../controllers/board-filters';
import { useDropHandler } from '../../controllers/drag-and-drop/use-drop-handler';
import { useJWMBoardFeatures } from '../../controllers/features-context';
import { useGroupByField } from '../../controllers/group-by';
import { useHandleIssueEvents } from '../../controllers/handle-issue-events';
import {
	useIssueGroups,
	IssuesByGroupProvider,
	IssueGroupProvider,
} from '../../controllers/issues-by-group';
import Column from './column';
import ColumnCreate from './column-create';
import { EmptyBoardComponent } from './empty-state';
import Minimap from './minimap';

export type ColumnRendererProps = {
	group: Group;
	columnIndex: number;
};

const isRealTimePayloadIssueEvent = (
	payload: RealtimeEvent['payload'],
): payload is IssueEventPayload => 'issueId' in payload && payload.issueId != null;

const REALTIME_APP_ID = 'jira-business-board-view';

const BoardContent = ({ isEmpty }: { isEmpty: boolean }) => {
	const project = useProject();
	const groups = useIssueGroups();
	const groupBy = useGroupByField();
	const boardContentRef = useRef<HTMLDivElement | null>(null);
	const [boardWrapper, setBoardWrapper] = useState<HTMLDivElement | null>(null);
	const isColumnCreateEnabled = project.isSimplified && (!groupBy || groupBy === GROUP_BY_STATUS);

	const totalChildren = isColumnCreateEnabled ? groups.length + 1 : groups.length;

	const virtualizer = useVirtualizer({
		count: totalChildren,
		horizontal: true,
		getScrollElement: () => boardWrapper,
		estimateSize: () => COLUMN_FIXED_WIDTH,
		gap: GAP_BETWEEN_COLUMNS,
		overscan: 0,
	});

	useEffect(() => {
		if (!boardWrapper) {
			return;
		}

		return autoScrollForElements({
			element: boardWrapper,
		});
	}, [boardWrapper]);

	const ColumnContent = virtualizer.getVirtualItems().map((virtualItem) => (
		<VirtualItemWrapper
			key={virtualItem.key}
			start={virtualItem.start}
			data-index={virtualItem.index}
			ref={virtualizer.measureElement}
		>
			{virtualItem.index === groups.length ? (
				<ColumnCreate />
			) : (
				<IssueGroupProvider key={virtualItem.key} group={groups[virtualItem.index]}>
					<Column columnIndex={virtualItem.index} columnsNumber={groups.length} />
				</IssueGroupProvider>
			)}
		</VirtualItemWrapper>
	));

	return (
		<BoardWrapper
			ref={setBoardWrapper}
			data-testid="work-management-board.ui.board.board-wrapper"
			isEmpty={fg('fun-1174_fix_board_empty_state') && isEmpty}
		>
			<BoardContentWrapper ref={boardContentRef} totalSize={virtualizer.getTotalSize()}>
				{ColumnContent}
			</BoardContentWrapper>

			<Minimap boardContentElement={boardContentRef.current} scrollElement={boardWrapper} />
		</BoardWrapper>
	);
};

const Board = () => {
	const { featureView } = useJWMBoardFeatures();
	const { isFiltering } = useFilterStatus();
	const { isSearching } = useSimpleSearchJQL();
	const project = useProject();
	const projectId = String(project.id);

	const { filteredIssues, loading, error, refetch } = useBoardData();

	const { handleUpdatedIssue, handleDeletedIssue } = useHandleIssueEvents();

	const [, { sendNodeSelected, sendClearNodeSelection }] = useCollabStore();

	const [selectedIssueKey, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);
	const prevSelectedIssueKey = usePreviousWithInitial(selectedIssueKey);
	const viewMode = useViewMode();
	const isViewModeSideBar = viewMode === 'SIDEBAR';

	const shouldShowInviteUserModalBasedOnSessionStorage = fg(
		'invite-users-on-project-create-killswitch',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShouldShowInviteUserModalBasedOnSessionStorage(project.key)
		: false;

	useDropHandler(loading);

	const boardHasNoIssues = filteredIssues.length === 0;
	const isBoardEmptyAfterFilter = boardHasNoIssues && (isFiltering || isSearching) && !loading;
	const isBoardEmpty = boardHasNoIssues && !isFiltering && !loading;

	const onIssueModalClose = useCallback(() => {
		setSelectedIssueKey(undefined);
	}, [setSelectedIssueKey]);

	useEffect(() => {
		// when issueKey param is removed from URL we need to clear node selection
		if (selectedIssueKey == null && prevSelectedIssueKey != null) {
			const issue = filteredIssues.find(
				(item) => item.fields[ISSUE_KEY_ID].value === prevSelectedIssueKey,
			);
			if (issue) {
				const nodeId = getPresenceNodeId({
					projectId,
					viewId: 'board',
					nodeId: `${issue.id}`,
				});
				sendClearNodeSelection({ nodeId });
			}
		}
	}, [
		filteredIssues,
		prevSelectedIssueKey,
		projectId,
		selectedIssueKey,
		sendClearNodeSelection,
		sendNodeSelected,
	]);

	const onPageVisibilityChange = useCallback(
		({ isVisible }: { isVisible: boolean }) => {
			if (isVisible) {
				refetch();
			}
		},
		[refetch],
	);

	const onRealtimeEventOld = useCallback(
		async (event: RealtimeEvent) => {
			if (isRealTimePayloadIssueEvent(event.payload)) {
				const { issueId } = event.payload;
				const isDeleted = event.type === ISSUE_DELETED_EVENT_OLD;

				if (isDeleted) {
					handleDeletedIssue(issueId);
				} else {
					handleUpdatedIssue(issueId);
				}
			}
		},
		[handleDeletedIssue, handleUpdatedIssue],
	);

	const onRealtimeEvent = useCallback(
		(event: GqlsRealtimeEvent) => {
			if (isIssueEvent(event)) {
				const issueId = Number(event.payload.issueId);
				if (event.type === ISSUE_DELETED_EVENT) {
					handleDeletedIssue(issueId);
				} else {
					handleUpdatedIssue(issueId);
				}
			}
		},
		[handleDeletedIssue, handleUpdatedIssue],
	);

	const onBentoChange: OnChangeCallback = useCallback(
		(event) => handleUpdatedIssue(Number(event.issueId)),
		[handleUpdatedIssue],
	);

	const onBentoDelete = useCallback(
		({ issueId }: { issueId: string | number }) => handleDeletedIssue(Number(issueId)),
		[handleDeletedIssue],
	);

	// Memoised to prevent flicker on JIRT updates
	const issueModal = useMemo(
		() =>
			selectedIssueKey != null && (
				<ModalIssueApp
					issueKey={toIssueKey(selectedIssueKey)}
					onClose={onIssueModalClose}
					analyticsSource={BUSINESS_BOARD}
					onChange={onBentoChange}
					onDeleteSuccess={onBentoDelete}
				/>
			),
		[selectedIssueKey, onIssueModalClose, onBentoChange, onBentoDelete],
	);

	if (error != null) {
		const is404 = 'statusCode' in error && error.statusCode === 404;

		return (
			<>
				{is404 && <ExperienceAbort experience={VIEW_EXPERIENCE} error={error} />}
				{!is404 && <ExperienceFailed experience={VIEW_EXPERIENCE} error={error} />}
				<ErrorPage error={error} />
			</>
		);
	}

	return (
		<UFOSegment name="business-board-content">
			<Container>
				<UFOCustomData
					data={{
						hasSelectedIssue: !!selectedIssueKey,
						isViewModeSideBar,
						isFiltering,
						isSearching,
						isBoardEmpty,
					}}
				/>

				<>
					{loading ? (
						<UFOLoadHold name="board-container-initial">
							<Box xcss={loadingWrapperStyles}>
								<ThemedSpinner />
							</Box>
						</UFOLoadHold>
					) : (
						<IssuesByGroupProvider filteredIssues={filteredIssues}>
							<BoardContainer data-testid="work-management-board.ui.board.board-container">
								<RenderStartMark view={featureView} />

								<BoardContent isEmpty={isBoardEmpty || isBoardEmptyAfterFilter} />

								{isBoardEmpty ? (
									<EmptyStateContainer>
										<EmptyBoardComponent />
									</EmptyStateContainer>
								) : null}

								{isBoardEmptyAfterFilter ? (
									<EmptyStateContainer>
										<ThemedFilterEmptyState />
									</EmptyStateContainer>
								) : null}

								<JWMSpaStatePageReady />
							</BoardContainer>
						</IssuesByGroupProvider>
					)}

					{isViewModeSideBar ? (
						<SidebarIssueView
							issueKey={selectedIssueKey}
							onClose={onIssueModalClose}
							onChange={onBentoChange}
							onIssueDeleteSuccess={onBentoDelete}
							analyticsSource={BUSINESS_BOARD}
						/>
					) : (
						issueModal
					)}

					<PerformanceAnalytics
						view={featureView}
						loading={loading}
						itemsCount={filteredIssues.length}
					/>

					<SignupInviteModal />

					{shouldShowInviteUserModalBasedOnSessionStorage && (
						<ProjectInviteModalWithRecommendedUsers isLoading={loading} />
					)}

					<PrefetchIssueDataController />

					{ff('jwm.board.migrate-jirt-to-gqls') ? (
						<GqlsRealtime
							appId={REALTIME_APP_ID}
							entityId={projectId}
							onReceive={onRealtimeEvent}
						/>
					) : (
						<BusinessRealtime appId={REALTIME_APP_ID} onReceive={onRealtimeEventOld} />
					)}

					<PageVisibility onChange={onPageVisibilityChange} />
				</>
			</Container>
		</UFOSegment>
	);
};

export default Board;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flex: 1,
	minHeight: 0,
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainer = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	flexShrink: '0',
	flexWrap: 'nowrap',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardWrapper = styled.div<{ isEmpty: boolean }>({
	width: '100%',
	overflowX: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	flex: ({ isEmpty }) => (isEmpty ? 0 : 1),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values
	minHeight: ({ isEmpty }) => (isEmpty ? `${EMPTY_BOARD_HEIGHT + 8}px` : 0),
	minWidth: 0,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	// We need to provide extra space to prevent the column drop indicator circle being clipped as overflow-y is set to auto due to overflow-x on this wrapper
	// as well as prevent the sibling create button being clipped
	// as well as the column drag over outline
	padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
	margin: `${token('space.negative.050', '-4px')} ${token('space.negative.150', '-12px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContentWrapper = styled.div<{ totalSize: number }>({
	display: 'flex',
	alignItems: 'flex-start',
	flex: 1,
	flexFlow: 'row nowrap',
	position: 'relative',
	height: '100%',
	maxHeight: '100%',
	minWidth: 'fit-content',
	userSelect: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ totalSize }) => `${totalSize}px`,
	willChange: 'opacity',
});

const loadingWrapperStyles = xcss({
	alignItems: 'center',
	display: 'flex',
	flex: 1,
	justifyContent: 'space-around',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VirtualItemWrapper = styled.div<{
	start: number;
}>({
	height: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateX(${start}px)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateContainer = styled.div({
	flex: 1,
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
});

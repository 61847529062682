import React, { memo, useCallback, useMemo, useState, type ChangeEvent } from 'react';
import type { EntryPointProps } from 'react-relay';
import Button, { LoadingButton } from '@atlaskit/button';
import { ErrorMessage, Label, HelperMessage } from '@atlaskit/form';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { Box, Flex, xcss, type XCSS } from '@atlaskit/primitives';
import Select, { type StylesConfig } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import {
	DONE,
	IN_PROGRESS,
	TODO,
	UNDEFINED,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type NewStatus = { statusName: string; statusCategory: StatusCategory };
export type CreateStatusModalProps = {
	onClose: () => void;
	createStatus: (params: NewStatus) => Promise<void>;
	validateStatus: (params: NewStatus) => boolean;
};

type CategoryOption = {
	label: string;
	value: StatusCategory;
};

const categoryBlockColorStyles: Record<StatusCategory, XCSS> = {
	[TODO]: xcss({
		border: `1px solid ${token('color.border.bold')}`,
		backgroundColor: 'color.background.neutral',
	}),
	[IN_PROGRESS]: xcss({
		border: `1px solid ${token('color.border.brand')}`,
		backgroundColor: 'color.background.selected',
	}),
	[DONE]: xcss({
		border: `1px solid ${token('color.border.success')}`,
		backgroundColor: 'color.background.success',
	}),
	[UNDEFINED]: xcss({
		border: `1px solid ${token('color.border.bold')}`,
		backgroundColor: 'color.background.neutral',
	}),
};

const formatOptionLabel = (option: CategoryOption) => (
	<Flex gap="space.100" alignItems="center">
		<Box xcss={[categoryBlockColorStyles[option.value], commonCategoryBlockStyles]} />
		<span>{option.label}</span>
	</Flex>
);

const selectStyles: StylesConfig<CategoryOption> = {
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const CreateStatusModalImpl = ({
	onClose,
	createStatus,
	validateStatus,
}: CreateStatusModalProps) => {
	const { formatMessage } = useIntl();

	const categoryOptions: CategoryOption[] = useMemo(
		() => [
			{ label: formatMessage(messages.toDoCategoryOption), value: TODO },
			{ label: formatMessage(messages.inProgressCategoryOption), value: IN_PROGRESS },
			{ label: formatMessage(messages.doneCategoryOption), value: DONE },
		],
		[formatMessage],
	);

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState('');
	const [category, setCategory] = useState<CategoryOption>(categoryOptions[0]);

	const trimmedName = useMemo(() => {
		if (fg('business_remove_column_name_whitespaces')) {
			return name.replace(/\s+/g, ' ').trim();
		}

		return name.trim();
	}, [name]);

	const newStatus = useMemo(
		() => ({ statusName: trimmedName, statusCategory: category.value }),
		[category.value, trimmedName],
	);

	const handleSubmit = useCallback(async () => {
		setIsLoading(true);
		try {
			await createStatus(newStatus);
			onClose();
		} catch (error) {
			setIsLoading(false);
		}
	}, [createStatus, newStatus, onClose]);

	const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const input = event.target.value;
		setName(input);
	}, []);

	const handleCategoryChange = useCallback((option: CategoryOption | null) => {
		if (!option) {
			return;
		}
		setCategory(option);
	}, []);

	const isInvalidName = !validateStatus(newStatus);

	const isNameTooLong = trimmedName.length > 60;

	return (
		<ShortcutScope>
			<Modal width="small">
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.title)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Box paddingBlockEnd="space.200">
						<Label htmlFor="create-status-name-field">
							{formatMessage(messages.nameFieldLabel)}
						</Label>
						<Textfield
							autoComplete="off"
							id="create-status-name-field"
							onChange={handleNameChange}
							value={name}
							isRequired
						/>
						{isInvalidName && (
							<ErrorMessage>
								{formatMessage(messages.errorMessageNameIsTaken, { name: trimmedName })}
							</ErrorMessage>
						)}
						{isNameTooLong && (
							<ErrorMessage>{formatMessage(messages.errorMessageNameIsTooLong)}</ErrorMessage>
						)}
					</Box>
					<Label htmlFor="create-status-category-field">
						{formatMessage(messages.categoryFieldLabel)}
					</Label>
					<Select
						inputId="create-status-category-field"
						options={categoryOptions}
						onChange={handleCategoryChange}
						// Currently, if a Select is inside a modal, the below two props are needed to prevent the menu from being cut off
						// more details in https://product-fabric.atlassian.net/browse/DSP-1826
						styles={selectStyles}
						menuPortalTarget={document.body}
						value={category}
						formatOptionLabel={formatOptionLabel}
						isSearchable={false}
					/>
					<HelperMessage>{formatMessage(messages.categoryFieldDescription)}</HelperMessage>
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={onClose} isDisabled={isLoading}>
						{formatMessage(messages.cancelButton)}
					</Button>
					<LoadingButton
						isLoading={isLoading}
						appearance="primary"
						isDisabled={!name || isInvalidName || isNameTooLong}
						onClick={handleSubmit}
					>
						{formatMessage(messages.submitButton)}
					</LoadingButton>
				</ModalFooter>
			</Modal>
		</ShortcutScope>
	);
};

export const CreateStatusModal = memo(CreateStatusModalImpl);

const CreateStatusModalEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, CreateStatusModalProps, {}>) => <CreateStatusModal {...props} />;

export default CreateStatusModalEntryPoint;

const commonCategoryBlockStyles = xcss({
	width: '16px',
	height: '16px',
	borderRadius: 'border.radius.050',
});

import type { BoardGroupByType } from '@atlassian/jira-business-common/src/common/types/group-by.tsx';
import { createSelector, createStateHook } from '@atlassian/react-sweet-state';
import { PARENT_ID } from '../../../common/constants';
import type { BoardIssue, BoardIssueId, User } from '../../../common/types';
import { type State, Store } from '../index';

export type FieldFilter = {
	fieldId: BoardGroupByType;
	fieldValue: string | number | null;
};

const getAssigneesInIssues = createSelector(
	(state: State) => state.issues,
	(issues) => {
		const userMap = new Map<string, User>();

		issues.forEach((issue) => {
			if (issue.fields.assignee) {
				const user = issue.fields.assignee.user;
				userMap.set(user.accountId, user);
			}
		});

		return userMap;
	},
);

export const useIssueAssignees = createStateHook(Store, {
	selector: getAssigneesInIssues,
});

type SubtasksByParentIdProps = {
	parentId: BoardIssueId;
};

const getSubtasksByParentId = createSelector<
	State,
	SubtasksByParentIdProps,
	BoardIssue[],
	Map<BoardIssueId, BoardIssue>,
	BoardIssueId
>(
	(state) => state.issues,
	(_, props) => props.parentId,
	(issues, parentId) =>
		Array.from(issues.values()).filter(
			(issue) => issue.fields[PARENT_ID] && String(parentId) === issue.fields[PARENT_ID].value,
		),
);

export const useSubtasksByParentId = createStateHook(Store, {
	selector: getSubtasksByParentId,
});

const getIssues = createSelector(
	(state: State) => state.issues,
	(issues) => issues,
);

export const useIssues = createStateHook(Store, {
	selector: getIssues,
});

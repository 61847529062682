import React, { memo, useEffect, useMemo, useCallback, useRef } from 'react';
import { useRealtimeContext } from '@atlassian/jira-business-entity-common/src/controllers/realtime-context/index.tsx';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import ErrorBoundary, {
	type ExtraErrorAttributes,
} from '@atlassian/jira-error-boundary/src/main.tsx';
import { createAri } from '@atlassian/jira-platform-ari';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	CREATE_SUBSCRIPTIONS_EXPERIENCE,
	DEFAULT_EVENTS_TO_SUBSCRIBE_TO,
	FIELD_MUTATION_EVENT,
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
	REALTIME_EVENT_EXPERIENCE,
} from '../constants';
import type { GqlsRealtimeEvent, OnReceive } from '../types';
import { CreateIssueSubscription } from './create-issue';
import { DeleteIssueSubscription } from './delete-issue';
import { FieldMutationSubscription } from './field-mutation';
import { UpdateIssueSubscription } from './update-issue';

type GqlsRealtimeProps = {
	appId: string;
	entityId: string;
	eventsToSubscribeTo?: string[];
	onReceive: OnReceive;
};

export const GqlsRealtime = memo<GqlsRealtimeProps>(
	({
		appId,
		entityId,
		onReceive,
		eventsToSubscribeTo = DEFAULT_EVENTS_TO_SUBSCRIBE_TO,
	}: GqlsRealtimeProps) => {
		const { cloudId } = useTenantContext();
		const siteId = createAri({
			resourceOwner: 'jira',
			resourceType: 'site',
			resourceId: cloudId,
		});
		const realtimeIds = useRealtimeContext();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const createSubsExperienceStart = useExperienceStart(CREATE_SUBSCRIPTIONS_EXPERIENCE);
		const createSubsExperienceSuccess = useExperienceSuccess(CREATE_SUBSCRIPTIONS_EXPERIENCE);
		const createSubsExperienceFail = useExperienceFail(CREATE_SUBSCRIPTIONS_EXPERIENCE);
		const realtimeEventExperienceStart = useExperienceStart(REALTIME_EVENT_EXPERIENCE);
		const realtimeEventExperienceSuccess = useExperienceSuccess(REALTIME_EVENT_EXPERIENCE);
		const realtimeEventExperienceFail = useExperienceFail(REALTIME_EVENT_EXPERIENCE);
		createSubsExperienceStart();

		// We need to be careful not to re-trigger the subscriptions useMemo, as this
		// causes new subscribe messages to be sent over the WS, which we only want to
		// happen when something directly related to the subscription changes
		// So we are using Refs to prevent the handleReceive function from changing too much
		const extraEventDataRef = useRef({ appId, entityId });
		extraEventDataRef.current.appId = appId;
		extraEventDataRef.current.entityId = entityId;
		const onReceiveRef = useRef(onReceive);
		onReceiveRef.current = onReceive;
		const handleReceive = useCallback(
			(event: GqlsRealtimeEvent) => {
				realtimeEventExperienceStart();
				try {
					fireTrackAnalytics(createAnalyticsEvent({}), 'subscription next', {
						...extraEventDataRef.current,
						realtimeEvent: event.type,
					});
					onReceiveRef.current(event);
					realtimeEventExperienceSuccess({ realtimeEvent: event.type });
				} catch (error) {
					realtimeEventExperienceFail(
						'jiraBusinessGqlsRealtime.gqlsRealtime',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						error as any,
						{ realtimeEvent: event.type },
					);
				}
			},
			[
				createAnalyticsEvent,
				realtimeEventExperienceStart,
				realtimeEventExperienceSuccess,
				realtimeEventExperienceFail,
			],
		);

		const handleReactError = useCallback(
			(_location: string, error: Error, event: ExtraErrorAttributes) => {
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'jiraBusinessGqlsRealtime.gqlsRealtime failed',
					event,
				);
				createSubsExperienceFail('jiraBusinessGqlsRealtime.gqlsRealtime', error);
			},
			[createAnalyticsEvent, createSubsExperienceFail],
		);

		const handleSubscriptionError = useCallback(
			(errorMessage: string, operationName: string) => {
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'jiraBusinessGqlsRealtime.subscription failed',
					{ errorMessage, operationName, ...extraEventDataRef.current },
				);
			},
			[createAnalyticsEvent],
		);

		const subscriptions = useMemo(
			() =>
				!realtimeIds.length
					? null
					: realtimeIds.map((projectId) => (
							// eslint-disable-next-line react/jsx-key
							<>
								{eventsToSubscribeTo.includes(ISSUE_UPDATED_EVENT) && (
									<UpdateIssueSubscription
										cloudId={cloudId}
										projectId={projectId}
										onReceive={handleReceive}
										onError={handleSubscriptionError}
									/>
								)}
								{eventsToSubscribeTo.includes(ISSUE_CREATED_EVENT) && (
									<CreateIssueSubscription
										cloudId={cloudId}
										projectId={projectId}
										onReceive={handleReceive}
										onError={handleSubscriptionError}
									/>
								)}
								{eventsToSubscribeTo.includes(ISSUE_DELETED_EVENT) && (
									<DeleteIssueSubscription
										cloudId={cloudId}
										projectId={projectId}
										onReceive={handleReceive}
										onError={handleSubscriptionError}
									/>
								)}
								{eventsToSubscribeTo.includes(FIELD_MUTATION_EVENT) && (
									<FieldMutationSubscription
										siteId={siteId}
										onReceive={handleReceive}
										onError={handleSubscriptionError}
									/>
								)}
							</>
						)),
			[cloudId, siteId, realtimeIds, eventsToSubscribeTo, handleReceive, handleSubscriptionError],
		);

		useEffect(() => {
			const realtimeEvents = {
				issueCreated: eventsToSubscribeTo.includes(ISSUE_CREATED_EVENT),
				issueUpdated: eventsToSubscribeTo.includes(ISSUE_UPDATED_EVENT),
				issueDeleted: eventsToSubscribeTo.includes(ISSUE_DELETED_EVENT),
				fieldMutation: eventsToSubscribeTo.includes(FIELD_MUTATION_EVENT),
			};
			fireTrackAnalytics(createAnalyticsEvent({}), 'subscriptions created', {
				...extraEventDataRef.current,
				...realtimeEvents,
				subscriptions: realtimeIds.length * eventsToSubscribeTo.length,
			});
			createSubsExperienceSuccess();
		}, [
			subscriptions,
			createAnalyticsEvent,
			realtimeIds,
			eventsToSubscribeTo,
			createSubsExperienceSuccess,
		]);

		useEffect(() => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'subscriptions init', extraEventDataRef.current);

			const extraEventData = extraEventDataRef.current;
			return () => {
				fireTrackAnalytics(createAnalyticsEvent({}), 'subscriptions closed', extraEventData);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<ErrorBoundary
				id="gqlsRealtime"
				packageName="jiraBusinessGqlsRealtime"
				extraEventData={extraEventDataRef.current}
				onError={handleReactError}
			>
				{subscriptions}
			</ErrorBoundary>
		);
	},
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteSuccessTitle: {
		id: 'work-management-board.controllers.delete-issue.delete-success-title',
		defaultMessage: 'Deleted item',
		description: 'Flag title for successful delete issue',
	},
	deleteSuccessDescription: {
		id: 'work-management-board.controllers.delete-issue.delete-success-description',
		defaultMessage: 'Item deleted successfully',
		description: 'Flag description for successful delete issue',
	},
	deleteErrorTitle: {
		id: 'work-management-board.controllers.delete-issue.delete-error-title',
		defaultMessage: 'Failed to delete item',
		description: 'Flag title for failed to delete issue',
	},
	deleteErrorDescription: {
		id: 'work-management-board.controllers.delete-issue.delete-error-description',
		defaultMessage:
			'Something went wrong while deleting the item. Please try again or contact your administrator if the problem persists.',
		description: 'Flag description for failed to delete issue',
	},
	deletePermissionDefinedErrorTitle: {
		id: 'work-management-board.controllers.delete-issue.delete-permission-defined-error-title',
		defaultMessage: 'Permission denied',
		description: 'Flag title for permission denied error',
	},
	deletePermissionDefinedErrorDescription: {
		id: 'work-management-board.controllers.delete-issue.delete-permission-defined-error-description',
		defaultMessage: 'You do not have permission to delete this item.',
		description: 'Flag description for permission denied error',
	},
});

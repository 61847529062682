import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';

export const ISSUE_CREATED_EVENT = 'avi:jira:created:issue';
export const ISSUE_DELETED_EVENT = 'avi:jira:deleted:issue';
export const ISSUE_UPDATED_EVENT = 'avi:jira:updated:issue';
export const FIELD_MUTATION_EVENT = 'avi:jira:field:updated';

export const ISSUE_EVENTS = [ISSUE_CREATED_EVENT, ISSUE_DELETED_EVENT, ISSUE_UPDATED_EVENT];

export const DEFAULT_EVENTS_TO_SUBSCRIBE_TO = ISSUE_EVENTS;

export const PACKAGE_NAME = 'jiraBusinessGqlsRealtime';

export const CREATE_SUBSCRIPTIONS_EXPERIENCE: ExperienceDetails = {
	experience: 'createSubscriptions',
	packageName: PACKAGE_NAME,
	teamName: 'jira-warepil',
};

export const REALTIME_EVENT_EXPERIENCE: ExperienceDetails = {
	experience: 'realtimeEvent',
	packageName: PACKAGE_NAME,
	teamName: 'jira-warepil',
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	statusAlreadyExists: {
		id: 'work-management-board.controllers.workflow-actions.rename-column.status-already-exists',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "''{statusName}'' is already a status name in this project",
		description:
			'Error message to be shown to user when user trying to rename status into status that already exists',
	},
	emptyColumnNameError: {
		id: 'work-management-board.controllers.workflow-actions.rename-column.empty-column-name-error',
		defaultMessage: 'Status name cannot be empty',
		description:
			'Error message to be shown to user when user trying to rename status into empty status',
	},
	errorFlagTitleRenameColumn: {
		id: 'work-management-board.controllers.workflow-actions.rename-column.error-flag-title-rename-column',
		defaultMessage: 'Unable to rename column',
		description: 'Title of error flag that is shown if rename column is unsuccessful',
	},
});

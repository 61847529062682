import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type NotFound from './main';

const NotFoundComponent = lazyAfterPaint<typeof NotFound>(
	() => import(/* webpackChunkName: "async-jwm-project-not-found" */ './main'),
);

const NotFoundAsync = () => (
	<Placeholder name="not-found-component" fallback={null}>
		<NotFoundComponent />
	</Placeholder>
);

export default NotFoundAsync;

export const PACKAGE_NAME = 'jira-business.invite-people';

export const BAD_REQUEST_ERROR_CODE = 'Check your request, then give it another try.';
export const NETWORK_ERROR_CODE = 'Check your network connection, then give it another try.';
export const AUTH_FAILED_ERROR_CODE = 'AUTH_FAILED';
export const SERVER_ERROR_ERROR_CODE = 'SERVER_ERROR';
export const USER_NOT_FOUND_ERROR_CODE = 'USER_NOT_FOUND';
export const USER_LIMIT_PROJECT_ERROR_CODE = 'LICENSE_LIMIT_REACHED';
export const USER_LIMIT_INSTANCE_ERROR_CODE = 'Licence Exceeded';
export const RECAPTCHA_ERROR_CODE = 'Recaptcha validation failed';

import React, { type ReactNode, createContext, useContext, useMemo } from 'react';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type RealtimeContextType = ProjectId[];

export type RealtimeProviderProps = {
	children: ReactNode;
	ids?: number[];
};

const RealtimeContext = createContext<RealtimeContextType>([]);

export const RealtimeProvider = ({ ids, children }: RealtimeProviderProps) => {
	const projectsIds = useMemo(() => (ids ? ids.map((pid) => String(pid)) : []), [ids]);

	return <RealtimeContext.Provider value={projectsIds}>{children}</RealtimeContext.Provider>;
};

export const useRealtimeContext = (): RealtimeContextType => useContext(RealtimeContext);

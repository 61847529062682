import React, { memo, useMemo } from 'react';
import { SmartCardProvider } from '@atlaskit/link-provider';
import client from '@atlassian/jira-apollo-gira';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { ConfluenceModalProvider } from '@atlassian/jira-business-confluence-pages/src/ui/confluence-view-modal/index.tsx';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { BusinessDocumentTitle } from '@atlassian/jira-business-entity-common/src/utils/document-title/index.tsx';
import { IssueTypesAndFieldsContext } from '@atlassian/jira-business-entity-project/src/controllers/issue-types-and-fields-context/index.tsx';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { DeserialisedFiltersProvider } from '@atlassian/jira-business-filters/src/controllers/deserialise-filters/index.tsx';
import { IssueCreateProvider } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/index.tsx';
import { WorkflowsProvider } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ISSUE_LINKS_TYPE } from '@atlassian/jira-platform-field-config';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import {
	CREATE_ISSUE_MUTATION,
	ISSUE_REFETCH_QUERY,
} from '@atlassian/jira-work-management-board/src/services/create-issue/gql.tsx';
import { VIEW_EXPERIENCE } from './common/constants';
import { BoardDataProvider } from './controllers/board-data';
import { IssueStoreContainer } from './controllers/board-issue-store';
import { ConfettiColumnsProvider } from './controllers/column-confetti';
import { CardDragDropProvider } from './controllers/drag-and-drop/card-drag-drop-context';
import { ColumnDragDropProvider } from './controllers/drag-and-drop/column-drag-drop-context';
import { useSelectedFields } from './controllers/fields-preference';
import { GroupsProvider } from './controllers/groups-context';
import { IssueTransitionsProvider } from './controllers/issue-transitions';
import { WorkflowActionsProvider } from './controllers/workflow-actions';
import { WorkflowStoreContainer, useWorkflowStoreState } from './controllers/workflow-store';
import messages from './messages';
import { useCreateIssue } from './services/create-issue';
import JWMBoardComponent from './ui/board';
import JWMBoardHeader from './ui/header';
import { transformWorkflow } from './utils/workflow-transformer';

const analyticsSourceName = 'board';

const FireScreenAnalyticsWithIssueLinksAttributes = () => {
	const selectedFields = useSelectedFields();

	return (
		<FireScreenAnalytics
			attributes={{ withIssueLinks: Boolean(selectedFields[ISSUE_LINKS_TYPE]) }}
		/>
	);
};

const BoardWithWorkflowsData = () => {
	const { workflows } = useWorkflowStoreState();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const services = fg('use-generated-types-for-create-issue') ? useCreateIssue() : undefined;
	const workflowsProviderWorkflows = useMemo(() => workflows.map(transformWorkflow), [workflows]);

	return (
		<WorkflowsProvider workflows={workflowsProviderWorkflows}>
			<IssueCreateProvider
				mutation={CREATE_ISSUE_MUTATION}
				query={ISSUE_REFETCH_QUERY}
				onCreateIssue={services?.createIssue}
				onRefetchIssue={services?.refetchIssue}
				ignoreFiltersContext
			>
				<ContextualAnalyticsData
					objectType="jwmBoard"
					sourceType={SCREEN}
					sourceName={analyticsSourceName}
				>
					<DeserialisedFiltersProvider>
						<WorkflowActionsProvider>
							<IssueStoreContainer>
								<ColumnDragDropProvider>
									<CardDragDropProvider>
										<GroupsProvider>
											<BoardDataProvider>
												<IssueTransitionsProvider>
													<ConfettiColumnsProvider>
														<JWMBoardHeader />
														<JWMBoardComponent />
													</ConfettiColumnsProvider>
												</IssueTransitionsProvider>
											</BoardDataProvider>
										</GroupsProvider>
									</CardDragDropProvider>
								</ColumnDragDropProvider>
							</IssueStoreContainer>
						</WorkflowActionsProvider>
						<FireScreenAnalyticsWithIssueLinksAttributes />
					</DeserialisedFiltersProvider>
				</ContextualAnalyticsData>
			</IssueCreateProvider>
		</WorkflowsProvider>
	);
};

export const JWMBoardRoot = memo(() => {
	const { formatMessage } = useIntl();

	const pageHeading = formatMessage(messages.boardHeading);

	return (
		<>
			<BusinessDocumentTitle pageHeading={pageHeading} />
			<BusinessAppWrapper withSidebar withOverflow>
				<ApolloClientProvider client={client}>
					<IssueTypesAndFieldsContext>
						<WorkflowStoreContainer>
							<BoardWithWorkflowsData />
						</WorkflowStoreContainer>
					</IssueTypesAndFieldsContext>
				</ApolloClientProvider>
			</BusinessAppWrapper>
		</>
	);
});

const BoardViewRootWithSmartCard = () => {
	const hasConfluence = useTenantHasConfluence();

	if (hasConfluence) {
		return (
			<SmartCardProvider>
				<ConfluenceModalProvider>
					<JWMBoardRoot />
				</ConfluenceModalProvider>
			</SmartCardProvider>
		);
	}
	return <JWMBoardRoot />;
};

const Board = () => (
	<ExperienceErrorBoundary experience={VIEW_EXPERIENCE} shouldStartExperience>
		<BoardViewRootWithSmartCard />
	</ExperienceErrorBoundary>
);

export default memo(Board);

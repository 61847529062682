import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import MinimapColumn from '@atlassian/jira-platform-board-kit/src/ui/minimap/column/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { useGroups } from '../../../controllers/groups-context';

const MINIMAP_MAP_HEIGHT = 36;
const MIN_SCALING_FACTOR = 14;
const MAX_MAP_WIDTH = 100;
const SHOW_MAP_RATIO = 1.1;

type MinimapProps = {
	boardContentElement: HTMLElement | null;
	scrollElement: HTMLElement | null;
};

const getScalingFactor = (updatedScrollWidth: number) => {
	const maxSizeScalingFactor = updatedScrollWidth / MAX_MAP_WIDTH;
	return Math.max(MIN_SCALING_FACTOR, maxSizeScalingFactor);
};

const Minimap = ({ boardContentElement, scrollElement }: MinimapProps) => {
	const [scrollLeft, setScrollLeft] = useState(0);
	const [scrollWrapperWidth, setScrollWrapperWidth] = useState(scrollElement?.clientWidth ?? 0);
	const [contentWrapperWidth, setContentWrapperWidth] = useState(
		boardContentElement?.clientWidth ?? 0,
	);
	const [isDragging, setIsDragging] = useState(false);
	const [mouseStart, setMouseStart] = useState<number | null>(null);
	const [initialOffset, setInitialOffset] = useState(0);

	const innerViewRef = useRef<HTMLElement | null>(null);
	const groups = useGroups();

	const scalingFactor = getScalingFactor(contentWrapperWidth);
	const outerWidth = contentWrapperWidth / scalingFactor;
	const innerWidth = scrollWrapperWidth / scalingFactor;
	const innerOffset = scrollLeft / scalingFactor;

	const onMouseDown = useCallback(
		(e: MouseEvent | React.MouseEvent) => {
			if (e.button === 0) {
				setIsDragging(true);
				setMouseStart(e.clientX);
				setInitialOffset(innerOffset);
			}
		},
		[innerOffset],
	);

	const onMouseUp = useCallback(() => {
		if (isDragging) {
			setIsDragging(false);
		}
	}, [isDragging]);

	const setHorizontalScroll = useCallback(
		(newOffset: number) => {
			scrollElement?.scrollTo(newOffset, scrollElement?.scrollTop);
		},
		[scrollElement],
	);

	const onScroll = useCallback(
		(newOffset: number) => {
			const newScalingFactor = getScalingFactor(contentWrapperWidth);
			setHorizontalScroll(newOffset * newScalingFactor);
		},
		[contentWrapperWidth, setHorizontalScroll],
	);

	const onMouseMove = useCallback(
		(e: MouseEvent | React.MouseEvent) => {
			if (isDragging && mouseStart !== null) {
				const mouseCurrent = e.clientX;
				onScroll(initialOffset + mouseCurrent - mouseStart);
			}
		},
		[initialOffset, isDragging, mouseStart, onScroll],
	);

	const cleanupListeners = useCallback(() => {
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseup', onMouseUp);
	}, [onMouseMove, onMouseUp]);

	const updateInnerViewPosition = useCallback(() => {
		if (innerViewRef.current) {
			innerViewRef.current.style.marginLeft = `${innerOffset}px`;
		}
	}, [innerOffset]);

	const handleMinimapRef = useCallback(
		(ref: HTMLElement | null) => {
			innerViewRef.current = ref;
			updateInnerViewPosition();
		},
		[updateInnerViewPosition],
	);

	useEffect(() => {
		const handleScroll = () => {
			setScrollLeft(scrollElement?.scrollLeft ?? 0);
		};

		const scrollElementResizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				setScrollWrapperWidth(entry.contentRect.width);
			}
		});

		if (scrollElement) {
			scrollElementResizeObserver.observe(scrollElement);
		}

		const boardContentElementResizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				setContentWrapperWidth(entry.contentRect.width);
			}
		});

		if (boardContentElement) {
			boardContentElementResizeObserver.observe(boardContentElement);
		}

		scrollElement?.addEventListener('scroll', handleScroll);

		return () => {
			scrollElement?.removeEventListener('scroll', handleScroll);
			scrollElementResizeObserver.disconnect();
			boardContentElementResizeObserver.disconnect();
		};
	}, [scrollElement, boardContentElement]);

	useEffect(() => {
		if (isDragging) {
			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		} else {
			cleanupListeners();
		}

		return () => {
			cleanupListeners();
		};
	}, [cleanupListeners, isDragging, onMouseMove, onMouseUp]);

	if (!(contentWrapperWidth / scrollWrapperWidth > SHOW_MAP_RATIO)) {
		return null;
	}

	return (
		<UFOSegment name="business-board-minimap">
			<JiraBottomRightCornerOutlet orderFromRight={2} id="business-board-minimap">
				<MinimapWrapper>
					<Box xcss={RelativePositionStyles}>
						<InnerViewStyle
							innerWidth={innerWidth}
							isDragging={isDragging}
							onMouseDown={onMouseDown}
							onMouseMove={onMouseMove}
							onMouseUp={onMouseUp}
							ref={handleMinimapRef}
						/>
					</Box>
					<OuterViewStyle outerWidth={outerWidth}>
						{groups.map((group) => (
							<MinimapColumn key={group.id} />
						))}
					</OuterViewStyle>
				</MinimapWrapper>
			</JiraBottomRightCornerOutlet>
		</UFOSegment>
	);
};

const RelativePositionStyles = xcss({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MinimapWrapper = styled.div({
	background: token('elevation.surface.overlay', 'white'),
	borderRadius: token('border.radius.050', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overlay', `4px 8px 14px 0 ${colors.N50A}`),
	display: 'inline-flex',
	padding: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OuterViewStyle = styled.div<{ outerWidth: number }>({
	border: `1px solid ${token('elevation.surface.overlay', 'white')}`,
	borderRadius: '3px',
	display: 'flex',
	height: `${MINIMAP_MAP_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ outerWidth }) => `${outerWidth}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerViewStyle = styled.div<{ innerWidth: number; isDragging: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `2px solid ${token('color.border.brand', colors.B100)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ isDragging }) =>
		isDragging
			? // This looks different in dark mode but the colors used match between none/light/dark
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.focused', colors.B200)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.brand', colors.B100),
	borderRadius: '3px',
	cursor: 'move',
	height: `${MINIMAP_MAP_HEIGHT}px`,
	left: '-1px',
	position: 'absolute',
	top: '-1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ innerWidth }) => `${innerWidth}px`,
});

export default memo(Minimap);

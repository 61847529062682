import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterPlaceholder: {
		id: 'business-filters.controllers.field-config-v2.filter-placeholder',
		defaultMessage: 'Search',
		description: 'Placeholder text of the filter issue search bar',
	},
	dateRangeLabel: {
		id: 'business-filters.controllers.field-config-v2.date-range-label',
		defaultMessage: 'Date range',
		description: 'Label of the date range picker for filtering issues by date fields',
	},
	startDateLabel: {
		id: 'business-filters.controllers.field-config-v2.start-date-label',
		defaultMessage: 'Start date',
		description: 'Label of the start date range picker for filtering issues by date fields',
	},
	dueDateLabel: {
		id: 'business-filters.controllers.field-config-v2.due-date-label',
		defaultMessage: 'Due date',
		description: 'Label of the due date range picker for filtering issues by date fields',
	},
	unassignedLabel: {
		id: 'business-filters.controllers.field-config-v2.unassigned-label',
		defaultMessage: 'Unassigned',
		description: 'Label of the filter option for a user field with no assignee',
	},
	noLabelsLabel: {
		id: 'business-filters.controllers.field-config-v2.no-labels-label',
		defaultMessage: 'No label',
		description: 'Label of the filter option for a labels field with no labels',
	},
	noCategoriesLabel: {
		id: 'business-filters.controllers.field-config-v2.no-categories-label',
		defaultMessage: 'No category',
		description: 'Label of the filter option for a category field with no category',
	},
	noComponentsLabel: {
		id: 'business-filters.controllers.field-config-v2.no-components-label',
		defaultMessage: 'No component',
		description: 'Label of the filter option for a components field with no component',
	},
	noSprintLabel: {
		id: 'business-filters.controllers.field-config-v2.no-sprint-label',
		defaultMessage: 'Backlog',
		description: 'Label of the filter option for a sprint field with no sprint',
	},
	noVersionLabel: {
		id: 'business-filters.controllers.field-config-v2.no-version-label',
		defaultMessage: 'No version',
		description: 'Label of the filter option for a version field with no version',
	},
	noParentLabel: {
		id: 'business-filters.controllers.field-config-v2.no-parent-label',
		defaultMessage: 'No parent',
		description: 'Label of the filter option for a parent field with no parent',
	},
	noGoalLabel: {
		id: 'business-filters.controllers.field-config-v2.no-goal-label',
		defaultMessage: 'No goal',
		description: 'Label of the filter option for a goals field with no goal',
	},
	noTeamLabel: {
		id: 'business-filters.controllers.field-config-v2.no-team-label',
		defaultMessage: 'No team',
		description: 'Label of the filter option for a parent field with no team',
	},
});

import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import type { DocumentNode } from 'graphql';
import { generateJql } from '@atlassian/jira-business-common/src/common/utils/get-variables/index.tsx';
import { CARD_COVERS_TYPE } from '@atlassian/jira-business-constants';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { useFilteredViewJQL } from '@atlassian/jira-business-filters/src/controllers/default-query-input/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ISSUE_LINKS_TYPE } from '@atlassian/jira-platform-field-config';
import { useIsLinkingEnabled } from '@atlassian/jira-router-resources-business-configuration';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { QueryVariables, QueryResponse, Connection } from './types';

type RefetchIssue<TIssue> = (
	issueId: number,
	fieldIds: string[],
	withFiltersContext: boolean,
) => Promise<TIssue | null>;

export function useLazyQuery<TData, TVariables>(query: DocumentNode) {
	const client = useApolloClient();
	return useCallback(
		(variables: TVariables) =>
			client.query<TData, TVariables>({
				query,
				variables,
				errorPolicy: 'all',
			}),
		[client, query],
	);
}

export const useRefetchIssue = <TIssue,>(query: DocumentNode): RefetchIssue<TIssue> => {
	const cloudId = useCloudId();
	const jqlContext = useJqlContext();
	const filtersJQL = useFilteredViewJQL();
	const isLinkingEnabled = useIsLinkingEnabled();
	const refetchIssue = useLazyQuery<QueryResponse<TIssue>, QueryVariables>(query);

	return useCallback(
		async (issueId: number, fieldIds: string[], withFiltersContext: boolean) => {
			const jql = generateJql({
				jqlContext,
				quickFiltersJql: withFiltersContext ? filtersJQL : undefined,
				issueIds: [issueId],
			});

			const fieldIdsSet = new Set(fieldIds);

			const withIssueLinks = fieldIdsSet.has(ISSUE_LINKS_TYPE) && isLinkingEnabled;
			fieldIdsSet.delete(ISSUE_LINKS_TYPE);

			const withCoverMedia = fieldIdsSet.has(CARD_COVERS_TYPE);

			const fieldIdsToRequest = Array.from(fieldIdsSet);

			const { data } = await refetchIssue({
				jql,
				fieldIds: fieldIdsToRequest,
				cloudId,
				first: 1,
				withCoverMedia,
				withChildrenCount: false,
				isParentFieldVisible: false,
				isDevSummaryFieldVisible: false,
				withIssueLinks,
				withParentIssueId: fg('jsw_list_view_-_all_the_fields'),
			});

			const viewItems = data?.jira?.jwmViewItems;

			if (!viewItems || viewItems.__typename === 'QueryError') {
				throw new Error('Issue not found');
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const issue = (viewItems as Connection<TIssue>).edges?.[0]?.node;

			return issue ?? null;
		},
		[cloudId, filtersJQL, jqlContext, refetchIssue, isLinkingEnabled],
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	collapseButton: {
		id: 'work-management-board.board.column.column-header.collapse-button',
		defaultMessage: 'Collapse',
		description: 'Label for expand column button',
	},
	expandButton: {
		id: 'work-management-board.board.column.column-header.expand-button',
		defaultMessage: 'Expand',
		description: 'Label for collapse column button',
	},
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	createContext,
	useRef,
	useMemo,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	type ReactNode,
} from 'react';
import type { DocumentNode } from 'graphql';
import { getPreviousRank, getNextRank } from '@atlassian/jira-business-rank';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type {
	CreateIssueParameters,
	OptimisticIssuePayload,
	CreateIssueReturn,
	ExtraCreateIssuePayload,
	CreateIssueResponse,
} from '../../types';
import {
	useCreateIssueOld as useCreateIssueControllerOld,
	useCreateIssue as useCreateIssueController,
} from '../create-issue';
import { useCreateParamHandler } from '../create-param-handler';

class IssueCreateJob<TIssue> {
	createParams: CreateIssueParameters;

	promise: Promise<TIssue | null>;

	resolve!: (value: TIssue | null) => void;

	reject!: (reason?: any) => void;

	constructor(createParams: CreateIssueParameters) {
		this.createParams = createParams;

		this.promise = new Promise<TIssue | null>((resolve, reject) => {
			this.reject = reject;
			this.resolve = resolve;
		});
	}
}

type CreateIssue<TIssue> = (createParams: CreateIssueParameters) => CreateIssueReturn<TIssue>;

type Context = {
	createIssue: CreateIssue<any>;
	highlightedIssues: Set<number>;
	summary: string;
	saveSummary: (summary: string) => void;
} | null;

const IssueCreateContext = createContext<Context>(null);

// remove the props mutation and query when cleaning up fg(use-generated-types-for-create-issue) & make onCreateIssue & onRefetchIssue mandatory
type ProviderProps<TIssue> = {
	onCreateIssue?: (
		createParams: CreateIssueParameters,
		extraPayload?: ExtraCreateIssuePayload,
	) => Promise<CreateIssueResponse<TIssue>>;
	onRefetchIssue?: (
		issueId: number,
		fieldIds: string[],
		withFiltersContext: boolean,
	) => Promise<TIssue | null>;
	children: ReactNode;
	ignoreFiltersContext?: boolean;
	mutation: DocumentNode;
	query: DocumentNode;
};

type State = {
	jobQueue: IssueCreateJob<any>[];
	runningJob: IssueCreateJob<any> | null;
	highlightedIssues: Set<number>;
	summary: string;
};

type Action =
	| { type: 'enqueue'; job: IssueCreateJob<any> }
	| { type: 'start-job' }
	| { type: 'end-job' }
	| { type: 'highlight'; issueId: number }
	| { type: 'unhighlight'; issueId: number }
	| { type: 'save-summary'; summary: string };

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'enqueue':
			return {
				...state,
				jobQueue: [...state.jobQueue, action.job],
			};
		case 'start-job':
			return {
				...state,
				runningJob: state.jobQueue[0],
				jobQueue: state.jobQueue.slice(1),
			};
		case 'end-job':
			return {
				...state,
				runningJob: null,
			};
		case 'highlight':
			return {
				...state,
				highlightedIssues: new Set([...state.highlightedIssues, action.issueId]),
			};
		case 'unhighlight':
			return {
				...state,
				highlightedIssues: new Set(
					[...state.highlightedIssues].filter((id) => id !== action.issueId),
				),
			};
		case 'save-summary':
			return {
				...state,
				summary: action.summary,
			};
		default:
			return state;
	}
};

const initialState: State = {
	jobQueue: [],
	runningJob: null,
	highlightedIssues: new Set(),
	summary: '',
};

// mutation and query can be removed when cleaning up fg(use-generated-types-for-create-issue)
export const IssueCreateProvider = <TIssue,>({
	mutation,
	query,
	onCreateIssue,
	onRefetchIssue,
	ignoreFiltersContext = false,
	children,
}: ProviderProps<TIssue>) => {
	const {
		data: { user },
	} = useCurrentUser();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const currentUser = user as User;
	// optimistic issue ids are negative numbers to distinguish them from real issue ids
	const nextIssueId = useRef(-1);
	const [{ jobQueue, runningJob, highlightedIssues, summary }, dispatch] = useReducer(
		reducer,
		initialState,
	);

	const createIssueController =
		onCreateIssue && onRefetchIssue
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useCreateIssueController(onCreateIssue, onRefetchIssue)
			: // eslint-disable-next-line react-hooks/rules-of-hooks
				useCreateIssueControllerOld(mutation, query);

	const highlightIssue = useCallback(
		(issueId: number) => dispatch({ type: 'highlight', issueId }),
		[],
	);
	const unhighlightIssue = useCallback(
		(issueId: number) =>
			setTimeout(() => {
				dispatch({ type: 'unhighlight', issueId });
			}, 2000),
		[],
	);

	const createIssue = useCallback(
		(createParams: CreateIssueParameters) => {
			const job = new IssueCreateJob(createParams);

			dispatch({ type: 'enqueue', job });

			let rank: string | undefined;

			if (createParams.input.rankAfter) {
				rank = getNextRank(createParams.input.rankAfter.rank);
			} else if (createParams.input.rankBefore) {
				rank = getPreviousRank(createParams.input.rankBefore.rank);
			}

			const reporter = {
				accountId: currentUser.accountId,
				avatarUrl: currentUser.avatarUrls['48x48'],
				displayName: currentUser.displayName,
			};

			const optimisticIssuePayload: OptimisticIssuePayload = {
				issueId: nextIssueId.current--,
				rank,
				reporter,
				...createParams.input,
			};

			return { optimisticIssuePayload, promise: job.promise };
		},
		[currentUser],
	);

	useEffect(() => {
		// dequeue and run the next job if there is one and there is no running job
		if (jobQueue.length > 0 && !runningJob) {
			const job = jobQueue[0];

			dispatch({ type: 'start-job' });

			createIssueController({
				createParams: job.createParams,
				highlightIssue,
				unhighlightIssue,
				ignoreFiltersContext,
			})
				.then(job.resolve)
				.catch(job.reject)
				.finally(() => dispatch({ type: 'end-job' }));
		}
	}, [
		createIssueController,
		highlightedIssues,
		jobQueue,
		runningJob,
		highlightIssue,
		unhighlightIssue,
		ignoreFiltersContext,
	]);

	useCreateParamHandler();

	const saveSummary = useCallback((newSummary: string) => {
		dispatch({ type: 'save-summary', summary: newSummary });
	}, []);

	const value = useMemo(
		() => ({
			createIssue,
			highlightedIssues,
			summary,
			saveSummary,
		}),
		[createIssue, highlightedIssues, summary, saveSummary],
	);

	return <IssueCreateContext.Provider value={value}>{children}</IssueCreateContext.Provider>;
};

export const useCreateIssue = <TIssue,>(
	onSubmit: (result: CreateIssueReturn<TIssue>) => void,
	onBlur?: () => void,
) => {
	const context = useContext(IssueCreateContext);
	if (!context) {
		throw new Error('useCreateIssue must be used within an IssueCreateProvider');
	}
	const { createIssue, summary, saveSummary } = context;
	const summaryRef = useRef(summary);
	const hasExitedWithoutUserAction = useRef(true);

	const submit: CreateIssue<TIssue> = useCallback(
		(createParams) => {
			const result = createIssue(createParams);
			onSubmit(result);
			hasExitedWithoutUserAction.current = false;
			setTimeout(() => {
				// reset the value as the form can stay open after submitting
				hasExitedWithoutUserAction.current = true;
			}, 100);

			return result;
		},
		[createIssue, onSubmit],
	);

	const blur = useCallback(() => {
		onBlur?.();
		hasExitedWithoutUserAction.current = false;
	}, [onBlur]);

	useEffect(
		() => () => {
			// if the component has exited without the user blurring the form or submitting,
			// then save the summary value so it can be reapplied
			saveSummary(hasExitedWithoutUserAction.current ? summaryRef.current : '');
		},
		[saveSummary],
	);

	return { summaryRef, submit, blur };
};

export const isOptimisticIssue = (issueId: number) => issueId < 0;

export const useIsHighlighted = () => {
	const context = useContext(IssueCreateContext);

	if (!context) {
		throw new Error('useIsHighlighted must be used within an IssueCreateProvider');
	}

	const { highlightedIssues } = context;

	return useCallback(
		(issueId: number) => isOptimisticIssue(issueId) || highlightedIssues.has(issueId),
		[highlightedIssues],
	);
};

import React, { memo, useCallback } from 'react';
import noop from 'lodash/noop';
import { lazy } from 'react-loosely-lazy';
import type { InvitedUser } from '@atlassian/invite-people/types';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type AddPeopleModalTypes from '@atlassian/jira-project-add-people';
import type { Party } from '@atlassian/jira-project-add-people';
import type { Role, UserInviteError } from '@atlassian/jira-project-add-people/src/model';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useAddPeople } from '../../../../../controllers/add-people-provider';
import {
	BAD_REQUEST_ERROR_CODE,
	NETWORK_ERROR_CODE,
	AUTH_FAILED_ERROR_CODE,
	SERVER_ERROR_ERROR_CODE,
	USER_NOT_FOUND_ERROR_CODE,
} from '../../../../../controllers/modal-handlers/constants';
import type { ModalProps } from '../../../../../controllers/modal-handlers/types';
import type { UserInviteErrorType } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AddPeopleModalLazy = lazy<typeof AddPeopleModalTypes>(
	() =>
		import(/* webpackChunkName: "async-add-people-modal" */ '@atlassian/jira-project-add-people'),
	{ ssr: false },
);

const userInviteErrorCodes: Record<UserInviteErrorType, string> = {
	BAD_REQUEST_ERROR_CODE,
	NETWORK_ERROR_CODE,
	AUTH_FAILED_ERROR_CODE,
	SERVER_ERROR_ERROR_CODE,
	USER_NOT_FOUND_ERROR_CODE,
};

export const AddPeopleModal = memo<ModalProps>(
	({ onInviteSuccess, onInviteError, onModalClose }: ModalProps) => {
		const { triggerBtnRef } = useAddPeople();

		const projectData = useProject();
		const isAdmin = useIsAdmin();

		const handleInviteSuccess = useCallback(
			(addedParties: Party[], _role: Role) => {
				onInviteSuccess({
					totalPeopleAdded: addedParties.length,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					email: (addedParties[0] as InvitedUser)?.email || addedParties[0]?.displayName,
					modalType: 'Project',
				});
			},
			[onInviteSuccess],
		);

		// From the given error message, we want to search for a known error code defined under the user invite endpoint
		// Otherwise, we return the whole message and use that as error code for mapping later on
		const extractErrorCode = (message: string) => {
			// Typescript assumes Object.keys is of type string[] so need to type cast
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			for (const errorCode of Object.keys(userInviteErrorCodes) as UserInviteErrorType[]) {
				const errorMessage = userInviteErrorCodes[errorCode];

				if (message.includes(errorMessage)) {
					return errorCode;
				}
			}
			return message; // Return original message if no error code found
		};

		const handleInviteFailure = useCallback(
			(errors: readonly UserInviteError[]) => {
				const userInviteErrorsWithCode: Record<string, UserInviteError[]> = {};

				errors.forEach((error) => {
					// Group all errors by known user invite error codes
					const errorCode = extractErrorCode(error.message);
					if (errorCode) {
						if (!userInviteErrorsWithCode[errorCode]) {
							userInviteErrorsWithCode[errorCode] = [];
						}
						userInviteErrorsWithCode[errorCode].push(error);
					}
				});

				// Join messages with the same error code
				const groupedUserInviteErrors = Object.values(userInviteErrorsWithCode).map((group) => {
					const statusCode = group[0]?.statusCode; // Assuming all errors with same error code have the same status code
					const messages = group.map((obj) => obj.message).join('\n');
					return { message: messages, statusCode };
				});

				groupedUserInviteErrors.forEach((groupedUserInviteError) => {
					onInviteError({
						modalType: 'Project',
						errorMessage: groupedUserInviteError.message,
						statusCode: groupedUserInviteError.statusCode,
					});
				});
			},
			[onInviteError],
		);

		const { id, key } = projectData;

		return (
			<ErrorBoundary
				id="async.jira-business.project-invite-people-modal.add-people"
				packageName="jira-business.project-invite-people-modal.add-people"
				teamName="jira-warepil"
			>
				<FireTrackAnalytics eventName="jwmProjectInviteComponent viewed" />
				<Placeholder name="add-people-modal-lazy" fallback={null}>
					<AddPeopleModalLazy
						isOpen
						triggerBtnRef={triggerBtnRef}
						onClose={onModalClose}
						onAddSuccess={handleInviteSuccess}
						onError={handleInviteFailure}
						projectId={id}
						projectKey={key}
						projectType="business"
						isAdmin={isAdmin}
						baseUrl=""
						analyticsPrefix=""
						allowFlags={false}
						onStopPerfMetric={noop}
					/>
				</Placeholder>
			</ErrorBoundary>
		);
	},
);

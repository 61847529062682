import React, {
	createContext,
	type ReactNode,
	useCallback,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import { useAbortable, ABORTED } from '@atlassian/jira-business-abortable';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import HttpError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { STATUS_ID } from '../../common/constants';
import type { BoardIssue } from '../../common/types';
import { fetchIssueTransitions } from '../../services/fetch-issue-transitions';
import { useWorkflowStoreState } from '../workflow-store';
import messages from './messages';
import type { HookState, Action } from './types';

type ContextType = {
	fetch: (issue: BoardIssue) => Promise<void>;
	hasError: boolean;
	loading: boolean;
	transitions: Transition[];
};

const IssueTransitionsContext = createContext<ContextType | null>(null);

const initialState: HookState = { hasError: false, loading: false, transitions: [] };

const reducer = (state: HookState, action: Action) => {
	switch (action.type) {
		case 'fetch':
			return { ...state, hasError: false, loading: true, transitions: [] };
		case 'success':
			return { ...state, hasError: false, loading: false, transitions: action.value };
		case 'error':
			return { ...state, hasError: true, loading: false, transitions: [] };
		default:
			return state;
	}
};

export const IssueTransitionsProvider = ({ children }: { children: ReactNode }) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const { transitions: workflowTransitions } = useWorkflowStoreState();
	const [{ loading, hasError, transitions }, dispatch] = useReducer(reducer, initialState);
	const abortableFetchIssueTransitions = useAbortable(fetchIssueTransitions);

	const fetch = useCallback(
		async (issue: BoardIssue) => {
			// check if any of the relevant workflow transitions are conditional
			const fromStatusId = Number(issue.fields[STATUS_ID].status.id);
			const eligibleTransitions = workflowTransitions.filter(
				(transition) => transition.fromStatusId === fromStatusId || transition.isGlobal,
			);

			// if none of the eligible transitions are conditional, then we do not need to fetch
			// the transitions from the server
			if (eligibleTransitions.every((transition) => !transition.isConditional)) {
				dispatch({
					type: 'success',
					value: eligibleTransitions,
				});
				return;
			}

			try {
				dispatch({
					type: 'fetch',
				});

				const response = await abortableFetchIssueTransitions(issue.id);

				dispatch({
					type: 'success',
					value: response,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error === ABORTED) {
					return;
				}

				dispatch({ type: 'error' });

				if (error instanceof HttpError && error.statusCode === 404) {
					showFlag({
						description: formatMessage(messages.notFoundErrorMessage),
						title: formatMessage(messages.notFoundErrorTitle),
						type: 'error',
					});
					return;
				}

				showFlag({
					description: formatMessage(messages.errorMessage),
					title: formatMessage(messages.errorTitle),
					type: 'error',
				});
			}
		},
		[abortableFetchIssueTransitions, formatMessage, showFlag, workflowTransitions],
	);

	const value = useMemo(
		() => ({ fetch, hasError, loading, transitions }),
		[fetch, hasError, loading, transitions],
	);

	return (
		<IssueTransitionsContext.Provider value={value}>{children}</IssueTransitionsContext.Provider>
	);
};

export const useIssueTransitions = () => {
	const context = useContext(IssueTransitionsContext);

	if (!context) {
		throw new Error('useIssueTransitions must be used within a IssueTransitionsProvider');
	}

	return context;
};

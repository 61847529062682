import { useEffect } from 'react';
import { useSidebar } from '@atlassian/jira-business-app-wrapper/src/controllers/sidebar/index.tsx';
import { DETAIL_PANEL_WIDTH_CSS_VAR } from '@atlassian/jira-layout-controller/src/common/constants';
import { useDetailPanelActions } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/store';

const MARGIN = 24;

// this is used to re-calclate position of the TimelineMode panel when Sidebar is open
export const useSetSidebarStyleVar = () => {
	const [{ content, sidebarWidth }] = useSidebar();
	const { setWidth } = useDetailPanelActions();

	useEffect(() => {
		if (content !== null) {
			document.documentElement?.style.setProperty(DETAIL_PANEL_WIDTH_CSS_VAR, `${sidebarWidth}px`);
			setWidth(sidebarWidth + MARGIN);
		} else {
			document.documentElement?.style.removeProperty(DETAIL_PANEL_WIDTH_CSS_VAR);
		}

		return () => {
			setWidth(0);
		};
	}, [content, setWidth, sidebarWidth]);
};

import { useMemo } from 'react';
import get from 'lodash/get';
import {
	type BoundActions,
	createStore,
	createHook,
	type StoreActionApi,
	createSelector,
} from '@atlassian/react-sweet-state';
import { useIssueTypesAndFields } from '../issue-types-and-fields';
import type { State, FieldConfig, IssueTypeConfig, Props } from './types';

const merge =
	(screen: string, issueTypeId: string, configs: IssueTypeConfig) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			[screen]: {
				...get(getState(), [screen], {}),
				[issueTypeId]: configs,
			},
		});
	};

const actions = {
	merge,
} as const;

export type Actions = typeof actions;

export const IssueTypeFieldConfigStore = createStore<State, Actions>({
	initialState: {},
	actions,
	name: 'issueTypeFieldConfig',
});

const getIssueTypeFieldConfigSelector = createSelector(
	(state: State) => state,
	(_, props: Props) => props?.screen ?? undefined,
	(_, props: Props) => props?.issueTypeId ?? undefined,
	(_, props: Props) => props?.fieldId ?? undefined,
	(state, screen, issueTypeId, fieldId) => {
		if (screen === undefined || issueTypeId === undefined || fieldId === undefined) {
			return null;
		}

		return get(state, [screen, issueTypeId, fieldId], null);
	},
);

export const useIssueTypeFieldConfigFromCache = createHook(IssueTypeFieldConfigStore, {
	selector: getIssueTypeFieldConfigSelector,
});

// This hook was introduced as a replacement for the useFieldConfig hook from platform, solving the problem of fetching fieldConfig for a list.
// It follows the same shape as the platform hook and should be used if consuming anything based on extracted fields inside JWM views that show a list of issues.
// It gets data from the available fields api when data requested is not available so the mapping of options for each field should happen there.
export const useIssueTypeFieldConfig = (
	props: Props,
): [FieldConfig | null, BoundActions<State, Actions>, { loading: boolean; error?: Error }] => {
	const [state, fieldsConfigActions] = useIssueTypeFieldConfigFromCache(props);

	const screen = props?.screen || 'VIEW';

	const { data, loading, error } = useIssueTypesAndFields({
		issueOperation: screen,
	});

	// store field config to be consumed at the cell level
	return useMemo(() => {
		if (state != null) {
			return [state, fieldsConfigActions, { loading: false, error: undefined }];
		}

		if (loading === false && get(data, ['issueTypes'], []).length > 0) {
			data.issueTypes.forEach((issueType) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const config: Record<string, any> = {};
				data.fields.forEach((field) => {
					if ((field.issueTypes || []).includes(issueType.id) && field.id != null) {
						config[field.id] = {
							...field.fieldConfig,
							fieldType: field.type,
						};
					}
				});

				fieldsConfigActions.merge(screen, issueType.id, config);

				if (issueType.isDefault) {
					fieldsConfigActions.merge(screen, 'default', config);
				}
			});
		}

		return [state, fieldsConfigActions, { loading, error }];
	}, [data, error, fieldsConfigActions, loading, screen, state]);
};

export type { FieldConfig };

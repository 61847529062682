import React, {
	useRef,
	memo,
	useCallback,
	type MouseEvent,
	type KeyboardEvent,
	useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	type Selection,
	useCollabStore,
} from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import {
	BADGE_INNER_POSITION as INNER,
	BADGE_OUTER_POSITION as OUTER,
} from '@atlassian/jira-business-collaboration/src/ui/node-selection/constants.tsx';
import { getPresenceNodeId } from '@atlassian/jira-business-collaboration/src/utils/get-presence-node-id/index.tsx';
import { openViewFromEmbed } from '@atlassian/jira-business-common/src/common/utils/embeds/index.tsx';
import { CARD_COVERS_TYPE, SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import {
	isOptimisticIssue,
	useIsHighlighted,
} from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/index.tsx';
import { usePrefetchIssueViewDataActions } from '@atlassian/jira-business-issue-view-critical-data-loader/src/controllers/use-prefetch-issue-view-data/index.tsx';
import { isSafari } from '@atlassian/jira-common-util-browser';
import { fg } from '@atlassian/jira-feature-gating';
import { CardSummary } from '@atlassian/jira-platform-card/src/common/ui/summary/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useTraitsActions } from '@atlassian/jira-traits/src/TraitsStore.tsx';
import { ISSUE_KEY_ID, SUMMARY_ID } from '../../../../common/constants';
import type { BoardIssue, BoardIssueCoverMedia, Group } from '../../../../common/types';
import { CardCoverEditingContainer } from '../../../../controllers/card-cover-editing-state';
import {
	useCardDragDrop,
	useHighlightedIssue,
} from '../../../../controllers/drag-and-drop/card-drag-drop-context';
import {
	useDraggableCard,
	type OnCardDragChange,
} from '../../../../controllers/drag-and-drop/use-draggable-card';
import { useJWMBoardFeatures } from '../../../../controllers/features-context';
import { useSelectedFields } from '../../../../controllers/fields-preference';
import { useViewIssue } from '../../../../controllers/view-issue';
import CardActions, { CARD_ACTIONS_DISPLAY_VARIABLE } from './card-actions';
import { CardBadge } from './card-badge';
import { CardContent } from './card-content';
import { CardCover } from './card-cover';
import { useCardFooter } from './card-footer';
import InlineSiblingCreate from './inline-sibling-create';

const isSafariBrowser = isSafari();

export type CardProps = {
	issue: BoardIssue;
	draggableIndex: number;
	canCreateIssues: boolean;
	group: Group;
	highlight?: string[];
	selections?: Selection[];
	isSiblingCreateFormOpen: boolean;
	toggleSiblingCreateForm: (issueId: number | null) => void;
	onCardDragChange: OnCardDragChange;
};

const useDropHighlight = (issueId: number) => {
	const { highlightedIssueId, resetHighlightedIssue } = useHighlightedIssue();

	const isDropHighlighted = highlightedIssueId === issueId;

	useEffect(() => {
		if (isDropHighlighted) {
			/*
			 * We need to reset the highlighted issue after a short delay to ensure the card has enough time to move
			 * to the correct position after a successful drop.
			 */
			const timeoutId = setTimeout(() => {
				resetHighlightedIssue();
			}, 100);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [isDropHighlighted, resetHighlightedIssue]);

	return isDropHighlighted;
};

export const Card = memo<CardProps>(
	({
		canCreateIssues,
		group,
		draggableIndex,
		highlight,
		isSiblingCreateFormOpen,
		issue,
		toggleSiblingCreateForm,
		selections,
		onCardDragChange,
	}) => {
		const issueKey = issue.fields[ISSUE_KEY_ID].value;
		const { issueViewInteraction } = useJWMBoardFeatures();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const shouldUseEmbedIssueClick = issueViewInteraction === 'openNewTab';
		const getIsHighlighted = useIsHighlighted();
		const isHighlighted = getIsHighlighted(issue.id);
		const { footerContent, footerPlaceholder } = useCardFooter(issue);
		const isDropHighlighted = useDropHighlight(issue.id);
		const cloudId = useCloudId();

		const isCardCoversChangeboardingEnabled = fg('jwm_card_covers_changeboarding');
		const { fetchTraits } = useTraitsActions();

		const ref = useRef<HTMLDivElement | null>(null);

		const { draggedIssue } = useCardDragDrop();
		const { dragState } = useDraggableCard({
			ref,
			issue,
			cardIndex: draggableIndex,
			group,
			onCardDragChange,
		});
		const isDragging = draggedIssue?.id === issue.id;

		const onEmbedIssueOpen = useCallback(() => {
			fireUIAnalytics(createAnalyticsEvent({}), 'issue clicked', 'boardEmbedIssue');
			openViewFromEmbed({ key: SELECTED_ISSUE_PARAM, value: issueKey });
		}, [createAnalyticsEvent, issueKey]);

		const onIssueClick = useViewIssue();
		const clickHandler = useCallback(
			(e: MouseEvent<HTMLElement>) => {
				onIssueClick(issue, e.ctrlKey || e.metaKey);
				fireUIAnalytics(createAnalyticsEvent({}), 'issue clicked', 'boardIssue');
			},
			[onIssueClick, issue, createAnalyticsEvent],
		);

		const issueKeyClickHandler = useCallback(() => {
			onIssueClick(issue, false);
		}, [onIssueClick, issue]);

		const { prefetchIssue } = usePrefetchIssueViewDataActions();

		const onMouseEnter = useCallback(() => {
			prefetchIssue(issueKey);

			if (isCardCoversChangeboardingEnabled) {
				fetchTraits(['SITE_USER'], { cloudId });
			}
		}, [cloudId, fetchTraits, isCardCoversChangeboardingEnabled, issueKey, prefetchIssue]);

		const handleKeyDown = useCallback(
			(event: KeyboardEvent<HTMLDivElement>) => {
				if (event.key === 'Enter' && event.target === event.currentTarget) {
					shouldUseEmbedIssueClick ? onEmbedIssueOpen() : onIssueClick(issue, false);
				}
			},
			[onIssueClick, issue, onEmbedIssueOpen, shouldUseEmbedIssueClick],
		);

		const cardContent = (
			<>
				<CardSummaryWrapper>
					<CardSummary
						text={issue.fields[SUMMARY_ID].value}
						shouldMenuRender
						highlight={highlight}
					/>
				</CardSummaryWrapper>

				<CardContent
					highlight={highlight}
					issue={issue}
					onIssueKeyClick={shouldUseEmbedIssueClick ? onEmbedIssueOpen : issueKeyClickHandler}
				/>
			</>
		);

		const cardContainer = (
			<CardContentContainer
				onClick={shouldUseEmbedIssueClick ? onEmbedIssueOpen : clickHandler}
				onKeyDown={handleKeyDown}
				onMouseEnter={onMouseEnter}
				data-testid="work-management-board.ui.board.column.card.container"
			>
				{cardContent}
				{footerPlaceholder}
			</CardContentContainer>
		);

		return (
			<>
				{canCreateIssues && (
					<InlineSiblingCreate
						group={group}
						isTopMost={draggableIndex === 0}
						isSiblingCreateFormOpen={isSiblingCreateFormOpen}
						issue={issue}
						toggleSiblingCreateForm={toggleSiblingCreateForm}
					/>
				)}

				<CardContainer
					ref={ref}
					isDragging={isDragging}
					data-testid="work-management-board.ui.board.column.card"
				>
					<CardCoverEditingContainer>
						<CardContentContainer
							onKeyDown={handleKeyDown}
							onMouseEnter={onMouseEnter}
							onClick={shouldUseEmbedIssueClick ? onEmbedIssueOpen : clickHandler}
						>
							<CardCoverWrapper coverMedia={issue.coverMedia} />
						</CardContentContainer>
						<RelativeMainContentContainer>
							<HighlightContainer
								isDraggingAllowed={!isOptimisticIssue(issue.id)}
								isHighlighted={isHighlighted || isDropHighlighted}
							>
								{selections ? (
									<CardBadge
										selections={selections}
										position={draggableIndex === 0 ? INNER : OUTER}
									>
										{cardContainer}
									</CardBadge>
								) : (
									cardContainer
								)}
							</HighlightContainer>

							{footerContent}

							{!isOptimisticIssue(issue.id) && <CardActions issue={issue} />}
						</RelativeMainContentContainer>
					</CardCoverEditingContainer>
				</CardContainer>

				{dragState.type === 'preview'
					? createPortal(
							<CardDragPreviewWrapper isTilted={!isSafariBrowser}>
								{cardContent}
							</CardDragPreviewWrapper>,
							dragState.container,
						)
					: null}
			</>
		);
	},
);

type CardCoverWrapperProps = {
	coverMedia: BoardIssueCoverMedia | null;
};

const CardCoverWrapper = memo<CardCoverWrapperProps>(({ coverMedia }) => {
	const selectedFields = useSelectedFields();
	const showCardCovers = selectedFields[CARD_COVERS_TYPE];
	return showCardCovers && <CardCover coverMedia={coverMedia} />;
});

const CardWithSelection = memo<CardProps>((props) => {
	const projectData = useProject();
	const projectId = String(projectData.id);
	const nodeId = getPresenceNodeId({ projectId, viewId: 'board', nodeId: `${props.issue.id}` });
	const [{ nodeSelection }] = useCollabStore();
	const selections = nodeSelection[nodeId];
	// card could have multiple selections from different users
	const cardSelections = Array.isArray(selections) && selections.length ? selections : undefined;

	return <Card {...props} selections={cardSelections} />;
});

export default CardWithSelection;

const MAX_CARD_WIDTH = 270;
const ACTION_MENU_BUTTON_SIZE = 32;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContainer = styled.div<{
	isDragging: boolean;
}>({
	maxWidth: MAX_CARD_WIDTH,
	userSelect: 'none',
	boxSizing: 'border-box',
	position: 'relative',
	borderRadius: token('border.radius', '3px'),
	boxShadow: token(
		'elevation.shadow.raised',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 1px 1px ${colors.N50A}, 0 0 1px 1px ${colors.N40A}`,
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.raised', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.isDragging ? token('opacity.disabled', '0.4') : 1),
	transition: 'opacity 0.2s ease',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: (props) => (props.isDragging ? 'grabbing' : 'pointer'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.hovered', colors.N20),
	},
	'&:hover, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		[CARD_ACTIONS_DISPLAY_VARIABLE]: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const RelativeMainContentContainer = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardDragPreviewWrapper = styled.div<{ isTilted: boolean }>({
	width: MAX_CARD_WIDTH,
	boxSizing: 'border-box',
	position: 'relative',
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.raised', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	rotate: ({ isTilted }) => (isTilted ? '4deg' : '0deg'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContentContainer = styled.div({
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardSummaryWrapper = styled.div({
	padding: token('space.150', '12px'),
	display: 'flex',
	minHeight: `${ACTION_MENU_BUTTON_SIZE}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const HighlightContainer = styled.div<{
	isDraggingAllowed: boolean;
	isHighlighted: boolean;
}>(
	{
		backgroundColor: 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: ({ isDraggingAllowed }) => (isDraggingAllowed ? 'inherit' : 'not-allowed'),
		transition: 'background-color 1s ease-out',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isHighlighted }) =>
		isHighlighted && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.accent.blue.subtler', colors.B200),
			transition: 'none',
		},
);

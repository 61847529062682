import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateHeading: {
		id: 'business-filters.themed-filter-empty-state.empty-state-heading',
		defaultMessage: "It looks like we can't find any results that match.",
		description: 'Message shown when the filtered list is empty',
	},
	invalidStateHeading: {
		id: 'business-filters.themed-filter-empty-state.invalid-state-heading',
		defaultMessage: "We can't handle the current filters, please clear to continue.",
		description: "Message shown when the user's filters are invalid and cannot be handled",
	},
	clearFiltersButton: {
		id: 'business-filters.themed-filter-empty-state.clear-filters-button',
		defaultMessage: 'Clear',
		description:
			'Call to action to clear filters when there are no results with the current filters',
	},
});

import { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import type { CreatePayload } from '@atlaskit/link-create';
import type { EMBEDDED_CONFLUENCE_MODE } from '@atlassian/embedded-confluence';

export interface ConfluencePagesContext {
	showConfluencePage: (args: {
		confluenceUrl: string;
		mode?: EMBEDDED_CONFLUENCE_MODE;
		onCloseComplete?: () => void;
	}) => void;

	showCreatePage: (args?: {
		onCreate?: (payload: CreatePayload) => void;
		onCloseComplete?: () => void;
		onCancel?: () => void;
	}) => void;
}

export const confluencePagesContext = createContext<ConfluencePagesContext>({
	showConfluencePage: noop,
	showCreatePage: noop,
});

export const useConfluencePagesModals = () => {
	const context = useContext(confluencePagesContext);
	const { showConfluencePage, showCreatePage } = context;
	return {
		showConfluencePage,
		showCreatePage,
	};
};

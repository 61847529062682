import type { UserPreferences } from '@atlassian/jira-issue-shared-types';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions';
import type { State, Value } from './types';

export const store = createStore<State, Actions>({
	name: 'user-preferences',
	initialState: {
		preferences: {},
	},
	actions,
});

const getUserPreference = (state: State, userPreferenceKey: UserPreferences): Value =>
	state.preferences[userPreferenceKey];

export const useUserPreferencesStoreValue = createHook(store, {
	selector: getUserPreference,
});

export const useUserPreferencesStoreActions = createHook(store, {
	selector: null,
});

import React, { type ReactNode, type ReactElement } from 'react';

type ConditionalWrapperProps = {
	condition: boolean;
	children: ReactNode | string;
	renderWrapper: (children: ReactNode) => ReactElement;
};

export const ConditionalWrapper = ({
	condition,
	renderWrapper,
	children,
}: ConditionalWrapperProps) => (condition ? renderWrapper(children) : <>{children}</>);

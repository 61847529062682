import React, { type ReactNode } from 'react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const OpenProjectSettings = ({ children }: { children: ReactNode }) => {
	const project = useProject();

	return (
		<a href={`/jira/core/projects/${project.key}/settings/issuetypes`} target="_blank">
			{children} <ShortcutIcon label="" size="small" />
		</a>
	);
};

export const OpenProjectSettingsMultipleWorkflows = () => {
	const { formatMessage } = useIntl();

	return (
		<FormattedI18nMessage
			componentsMapping={{
				link: OpenProjectSettings,
			}}
			message={formatMessage(messages.multipleWorkflowsBoardActionsDisabled, {
				linkStart: '{linkStart}',
				linkEnd: '{linkEnd}',
			})}
		/>
	);
};

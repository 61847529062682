import type { ApolloError } from 'apollo-client';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

export const APOLLO_NETWORK_ERROR = 'APOLLO_NETWORK_ERROR';
export const NULL_OR_UNDEFINED = 'NULL_OR_UNDEFINED';
export const OTHER = 'OTHER';

export const KNOWN_NETWORK_ERRORS = {
	FAILED_TO_FETCH: 'Failed to fetch',
	NETWORK_ERROR: 'NetworkError when attempting to fetch resource.',
	NETWORK_REQUEST_FAILED: 'Network request failed',
	NETWORK_CONNECTION_LOST: 'The network connection was lost.',
	NETWORK_CONNECTION_ERROR:
		'upstream connect error or disconnect/reset before headers. reset reason: connection',
	UNEXPECTED_ERROR_OCCURRED: 'Response ended with an error message: An unexpected error occurred',
	ABORTED: 'operation was aborted.',
	CANCELLED: 'cancelled',
	TIMEOUT: 'The request timed out.',
} as const;

const isApolloClientNetworkError = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: (Error | ApolloError) & { networkError?: any },
): boolean => error.networkError !== undefined && error.networkError !== null;

export const getErrorType = (error?: Error) => {
	if (error == null) {
		return NULL_OR_UNDEFINED;
	}

	const message = String(error instanceof Error ? error.message : error).trim();
	if (error instanceof FetchError) {
		const { statusCode } = error;
		if (statusCode) {
			return `FETCH_ERROR_${statusCode}`;
		}
	}

	if (isApolloClientNetworkError(error)) {
		return APOLLO_NETWORK_ERROR;
	}

	const entry = Object.entries(KNOWN_NETWORK_ERRORS).find(
		(arrayItem) => String(arrayItem[1]).toLowerCase() === message.toLowerCase(),
	);

	return entry ? entry[0] : OTHER;
};

import { useCallback } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { useExperienceAbort } from '@atlassian/jira-business-experience-tracking';
import {
	useExperienceStart,
	useExperienceFail,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useWorkflowsV2 } from '@atlassian/jira-business-workflows/src/services/workflow-v2';
import {
	mapStatusesToStatusUpdateObjects,
	mapWorkflowToWorkflowUpdateObject,
	removeWorkflowAssociatedWithSubtaskOnly,
} from '@atlassian/jira-business-workflows/src/services/workflow-v2/utils';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { COLUMN_ACTION_RENAME_EXPERIENCE } from '../../../common/constants';
import { useBoardData } from '../../board-data';
import { useWorkflowStoreState } from '../../workflow-store';
import { useStartRenameOperation, useStopWorkflowOperationInProgress } from '../index';
import { getColumnActionFailedFlag, trackColumnExperience } from '../utils';
import messages from './messages';

const getCleanColumnName = (name: string): string => name.replace(/\s{2,}/g, ' ').trim();

export const useRenameColumn = () => {
	const { columns } = useWorkflowStoreState();

	const startRenameOperation = useStartRenameOperation();
	const stopWorkflowOperationInProgress = useStopWorkflowOperationInProgress();

	const { fetchWorkflowsV2, validateAndUpdateWorkflowsV2 } = useWorkflowsV2();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();

	const {
		data: { issueTypes },
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const startExperience = useExperienceStart(COLUMN_ACTION_RENAME_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(COLUMN_ACTION_RENAME_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(COLUMN_ACTION_RENAME_EXPERIENCE);
	const abortExperience = useExperienceAbort(COLUMN_ACTION_RENAME_EXPERIENCE);

	const project = useProject();

	const { refetch: refetchBoardData } = useBoardData();
	const { showFlag } = useFlagService();

	/**
	 * @returns undefined if it is valid, otherwise return string that will be used as error message
	 * this is a pattern used by atlaskit form field validation or inline edit
	 */
	const validateRename = useCallback(
		({ oldName, newName }: { oldName: string; newName: string }) => {
			const cleanNewName = getCleanColumnName(newName);
			const convertedNewName = cleanNewName.toUpperCase();

			if (convertedNewName === oldName.toUpperCase()) {
				return undefined;
			}

			if (!cleanNewName.length) {
				return formatMessage(messages.emptyColumnNameError);
			}

			if (columns.some((column) => column.name.toUpperCase() === convertedNewName)) {
				return formatMessage(messages.statusAlreadyExists, {
					statusName: cleanNewName,
				});
			}
		},
		[columns, formatMessage],
	);

	const renameColumn = useCallback(
		async ({
			oldName,
			statusId,
			newName,
		}: {
			oldName: string;
			statusId: number;
			newName: string;
		}) => {
			const cleanNewName = getCleanColumnName(newName);
			const convertedNewName = cleanNewName.toUpperCase();

			if (!cleanNewName.length || convertedNewName === oldName.toUpperCase()) {
				return;
			}

			startRenameOperation(String(statusId));
			startExperience();
			try {
				// fetch workflow data from workflow API
				const workflowsData = await fetchWorkflowsV2({
					cloudId,
					projectAndIssueTypes: issueTypes.map((it) => ({
						projectId: String(project.id),
						issueTypeId: it.id,
					})),
				});

				if (workflowsData) {
					const subtaskIssueTypeId = issueTypes.find(
						(issueType) => issueType.hierarchyLevel === -1,
					)?.id;
					// remove subtask workflow
					const filteredWorkflowsData = removeWorkflowAssociatedWithSubtaskOnly(
						workflowsData,
						subtaskIssueTypeId,
					);
					// rename the column in place
					const toRenameStatus = filteredWorkflowsData.statuses.find(
						(s) => s.id === statusId.toString(),
					);

					if (toRenameStatus) {
						toRenameStatus.name = cleanNewName;
						await validateAndUpdateWorkflowsV2({
							cloudId,
							payload: {
								payload: {
									statuses: mapStatusesToStatusUpdateObjects(workflowsData.statuses),
									workflows: [mapWorkflowToWorkflowUpdateObject(workflowsData.workflows[0])],
								},
								validationOptions: { levels: ['ERROR'] },
							},
						});
						markExperienceSuccess();
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				const issueTypeIdNotSubtask = issueTypes.find(
					(issueType) => issueType.hierarchyLevel === 0,
				)?.id;

				showFlag(
					getColumnActionFailedFlag({
						messageTitle: messages.errorFlagTitleRenameColumn,
						action: 'rename',
						error,
						issueTypeIdNotSubtask,
						projectKey: project.key,
					}),
				);

				trackColumnExperience({
					markExperienceFailed,
					abortExperience,
					error,
					errorMessage: 'Failed to validate and update workflow',
				});

				throw error;
			} finally {
				stopWorkflowOperationInProgress();
				refetchBoardData();
			}
		},
		[
			issueTypes,
			project,
			cloudId,
			startRenameOperation,
			startExperience,
			fetchWorkflowsV2,
			validateAndUpdateWorkflowsV2,
			showFlag,
			markExperienceSuccess,
			markExperienceFailed,
			abortExperience,
			stopWorkflowOperationInProgress,
			refetchBoardData,
		],
	);

	return {
		renameColumn,
		validateRename,
	};
};

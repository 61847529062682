import React, { useState, useEffect } from 'react';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';

/**
 * This component is used to defer the SPA ready state until the view is ready to be interacted with,
 * which accomplishes deferring lazy loaded bundles marked for lazyAfterPaint. It uses a useEffect so that
 * it doesn't block the initial paint of the page.
 */
export const JWMSpaStatePageReady = () => {
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		/**
		 * Waiting for 2 animation frames is equivalent to waiting for react to paint the DOM.
		 */
		let id = window.requestAnimationFrame(() => {
			id = window.requestAnimationFrame(() => {
				setIsReady(true);
			});
		});

		return () => {
			window.cancelAnimationFrame(id);
		};
	}, []);

	if (!isReady) {
		return null;
	}

	return <SpaStatePageReady />;
};

import React from 'react';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import CheckboxIcon from '@atlaskit/icon/glyph/checkbox';
import ChevronDownCircleIcon from '@atlaskit/icon/glyph/chevron-down-circle';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import ComponentIcon from '@atlaskit/icon/glyph/component';
import EditorAlignLeftIcon from '@atlaskit/icon/glyph/editor/align-left';
import EditorLinkIcon from '@atlaskit/icon/glyph/editor/link';
import EditorMentionIcon from '@atlaskit/icon/glyph/editor/mention';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import EmojiKeyboardIcon from '@atlaskit/icon/glyph/emoji/keyboard';
import IssuesIcon from '@atlaskit/icon/glyph/issues';
import LabelIcon from '@atlaskit/icon/glyph/label';
import MediaServicesScaleLargeIcon from '@atlaskit/icon/glyph/media-services/scale-large';
import MenuExpandIcon from '@atlaskit/icon/glyph/menu-expand';
import PageIcon from '@atlaskit/icon/glyph/page';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import PersonIcon from '@atlaskit/icon/glyph/person';
import RecentIcon from '@atlaskit/icon/glyph/recent';
import SprintIcon from '@atlaskit/icon/glyph/sprint';
import SubtaskIcon from '@atlaskit/icon/glyph/subtask';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	REMOTE_ISSUE_LINKS_TYPE,
	COMMENT_SUMMARY_TYPE,
	CARD_COVERS_TYPE,
} from '@atlassian/jira-business-constants';
import {
	ASSIGNEE_TYPE,
	CREATED,
	DUE_DATE_TYPE,
	ISSUE_TYPE,
	LABELS_TYPE,
	PRIORITY_TYPE,
	REPORTER_TYPE,
	SUMMARY_TYPE,
	STATUS_TYPE,
	TEXT_CF_TYPE,
	UPDATED,
	URL_CF_TYPE,
	USER_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	DATE_CF_TYPE,
	NUMBER_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	MULTI_USER_CF_TYPE,
	DATETIME_CF_TYPE,
	COMPONENTS_TYPE,
	STORY_POINTS_TYPE,
	LABELS_CF_TYPE,
	PEOPLE_CF_TYPE,
	SUBTASKS_TYPE,
	EPIC_TYPE,
	COMMENT_TYPE,
	CATEGORY_TYPE,
	RESOLUTION_DATE,
	MULTI_CHECKBOXES_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SPRINT_TYPE,
	ISSUE_LINKS_TYPE,
} from '@atlassian/jira-platform-field-config';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import type { IconSize } from '../types';

const PriorityIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM12 18.2222C15.4364 18.2222 18.2222 15.4364 18.2222 12C18.2222 8.56356 15.4364 5.77778 12 5.77778C8.56356 5.77778 5.77778 8.56356 5.77778 12C5.77778 15.4364 8.56356 18.2222 12 18.2222ZM14.1219 13.5914L14.0381 13.5174L12 11.48L9.96186 13.5174C9.64143 13.8378 9.13725 13.8625 8.78855 13.5914L8.70481 13.5174C8.38438 13.197 8.35973 12.6928 8.63086 12.3441L8.70481 12.2604L11.3715 9.5937C11.6919 9.27327 12.1961 9.24861 12.5448 9.51975L12.6285 9.5937L15.2952 12.2604C15.6423 12.6075 15.6423 13.1703 15.2952 13.5174C14.9748 13.8378 14.4706 13.8625 14.1219 13.5914Z"
			fill="currentColor"
		/>
	</svg>
);
const StatusIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM18.2222 12C18.2222 8.56356 15.4364 5.77778 12 5.77778C8.56356 5.77778 5.77778 8.56356 5.77778 12C5.77778 15.4364 8.56356 18.2222 12 18.2222C15.4364 18.2222 18.2222 15.4364 18.2222 12ZM15.3664 11.4518L15.2952 11.3715C15.3267 11.4029 15.3553 11.4362 15.381 11.4708C15.3969 11.4923 15.4118 11.5144 15.4257 11.5372C15.4324 11.5479 15.4386 11.5588 15.4447 11.5697C15.4544 11.5873 15.4637 11.6054 15.4723 11.6239C15.4794 11.639 15.486 11.6544 15.4922 11.6699C15.4993 11.6875 15.5057 11.7051 15.5115 11.7229C15.5158 11.7361 15.5199 11.7498 15.5237 11.7636C15.5291 11.7827 15.5337 11.802 15.5377 11.8215C15.5407 11.837 15.5434 11.8525 15.5457 11.868C15.5483 11.8849 15.5504 11.9024 15.552 11.92C15.5539 11.9416 15.555 11.9626 15.5554 11.9837C15.5555 11.9886 15.5556 11.9943 15.5556 12L15.5554 12.0172C15.555 12.0382 15.5539 12.0593 15.552 12.0802L15.5556 12C15.5556 12.0439 15.5524 12.087 15.5462 12.1292C15.5436 12.1467 15.5404 12.1648 15.5366 12.1829C15.5331 12.1999 15.5293 12.2162 15.5249 12.2323C15.521 12.2463 15.5168 12.2606 15.5122 12.2748C15.5059 12.2943 15.4989 12.3135 15.4913 12.3325C15.4855 12.3467 15.4795 12.3606 15.4731 12.3744C15.4639 12.3941 15.4539 12.4138 15.4431 12.4331C15.438 12.4423 15.4326 12.4517 15.4269 12.461C15.4135 12.4829 15.3993 12.5041 15.3843 12.5246C15.3793 12.5315 15.3743 12.5382 15.3691 12.5448C15.3528 12.5655 15.3393 12.5815 15.3252 12.597L15.2952 12.6285L12.6285 15.2952C12.2814 15.6423 11.7186 15.6423 11.3715 15.2952C11.051 14.9748 11.0264 14.4706 11.2975 14.1219L11.3715 14.0381L12.52 12.8889H9.33333C8.84241 12.8889 8.44444 12.4909 8.44444 12C8.44444 11.5468 8.78355 11.1729 9.22184 11.118L9.33333 11.1111H12.5218L11.3715 9.96187C11.051 9.64144 11.0264 9.13724 11.2975 8.78853L11.3715 8.70479C11.6919 8.38436 12.1961 8.35971 12.5448 8.63085L12.6285 8.70479L15.2977 11.374C15.3221 11.3985 15.345 11.4245 15.3664 11.4518Z"
			fill="currentColor"
		/>
	</svg>
);

// Custom icon not from Atlaskit
const NumberGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24">
		<path
			d="M7 4h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm.417 10.636h1.15V9.141H7.42L6 10.12v1.07l1.348-.93h.069v4.376zm1.923-3.85v.02h1.067v-.024c0-.506.365-.857.898-.857.503 0 .861.313.861.755 0 .357-.194.643-.967 1.397l-1.794 1.756v.803h3.964v-.955h-2.406v-.069l1.05-1.002c.953-.895 1.277-1.409 1.277-2.007 0-.944-.8-1.603-1.947-1.603-1.188 0-2.003.724-2.003 1.786zm5.811 1.466h.682c.617 0 .994.297.994.777 0 .469-.4.792-.975.792-.59 0-.983-.293-1.017-.757h-1.1c.05 1.035.88 1.713 2.106 1.713 1.253 0 2.159-.697 2.159-1.66 0-.724-.472-1.226-1.226-1.31v-.069c.613-.125 1.013-.62 1.013-1.257 0-.864-.811-1.481-1.939-1.481-1.2 0-1.972.655-2.01 1.695H14.9c.03-.476.385-.777.918-.777.537 0 .88.282.88.723 0 .45-.355.754-.876.754h-.67v.857z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

const TypeIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.9299 7C10.9299 6.44772 11.3776 6 11.9299 6C12.4822 6 12.9299 6.44772 12.9299 7V10.268L15.76 8.63397C16.2383 8.35783 16.8499 8.5217 17.1261 9C17.4022 9.47829 17.2383 10.0899 16.76 10.366L13.9299 12L16.76 13.634C17.2383 13.9101 17.4022 14.5217 17.1261 15C16.8499 15.4783 16.2383 15.6422 15.76 15.366L12.9299 13.732V17C12.9299 17.5523 12.4822 18 11.9299 18C11.3776 18 10.9299 17.5523 10.9299 17V13.7321L8.09978 15.366C7.62148 15.6422 7.00989 15.4783 6.73375 15C6.45761 14.5217 6.62148 13.9101 7.09978 13.634L9.9299 12L7.09978 10.366C6.62148 10.0899 6.45761 9.47829 6.73375 9C7.00989 8.5217 7.62148 8.35783 8.09978 8.63397L10.9299 10.2679V7Z"
			fill="currentColor"
		/>
	</svg>
);

const KeyIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 6C9.44772 6 9 6.44772 9 7V9H7C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H9V13H7C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H9V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V15H13V17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17V15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H15V11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9H15V7C15 6.44772 14.5523 6 14 6C13.4477 6 13 6.44772 13 7V9H11V7C11 6.44772 10.5523 6 10 6ZM13 13V11H11V13H13Z"
			fill="currentColor"
		/>
	</svg>
);

const EpicIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.5 6C17.3284 6 18 6.67157 18 7.5V16.5C18 17.3284 17.3284 18 16.5 18H7.5C6.67157 18 6 17.3284 6 16.5V7.5C6 6.67157 6.67157 6 7.5 6H16.5ZM12.375 8.25C12.2625 8.25 12.165 8.30175 12.096 8.37975L9.11175 12.1088C9.1095 12.111 9.08475 12.1425 9.08475 12.1425C9.03525 12.2092 9 12.2865 9 12.375C9 12.582 9.168 12.75 9.375 12.75C9.396 12.75 9.41325 12.7537 9.43275 12.75H11.25V15.375C11.25 15.582 11.418 15.75 11.625 15.75C11.718 15.75 11.8005 15.7125 11.8658 15.657C11.8658 15.657 11.9123 15.6068 11.9295 15.582L14.88 11.8972C14.8943 11.8837 14.9033 11.868 14.9153 11.8522L14.9355 11.8275C14.9722 11.7667 15 11.7 15 11.625C15 11.418 14.832 11.25 14.625 11.25H12.75V8.625C12.75 8.418 12.582 8.25 12.375 8.25Z"
			fill="currentColor"
		/>
	</svg>
);

const renderChevronDownIcon = ({ size }: { size: IconSize }) =>
	isVisualRefreshEnabled() ? (
		<Box xcss={iconSpacing}>
			<ChevronDownIcon label="" color={token('color.icon')} />
		</Box>
	) : (
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		<ChevronDownCircleIcon size={size} label="" />
	);

export const getLegacyFieldIcons = ({ size }: { size: IconSize }): Record<string, JSX.Element> => ({
	key: <KeyIcon />,
	[ISSUE_TYPE]: <TypeIcon />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[SUMMARY_TYPE]: <EditorAlignLeftIcon size={size} label="" />,
	[STATUS_TYPE]: <StatusIcon />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[ASSIGNEE_TYPE]: <EditorMentionIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[LABELS_TYPE]: <LabelIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[DUE_DATE_TYPE]: <CalendarIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[CREATED]: <CalendarIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[UPDATED]: <CalendarIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[RESOLUTION_DATE]: <CalendarIcon size={size} label="" />,
	[PRIORITY_TYPE]: <PriorityIcon />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[REPORTER_TYPE]: <EditorMentionIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[TEXT_CF_TYPE]: <EmojiKeyboardIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[URL_CF_TYPE]: <EditorLinkIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[USER_CF_TYPE]: <PersonIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[TEXT_AREA_CF_TYPE]: <EditorAlignLeftIcon size={size} label="" />,
	[SELECT_CF_TYPE]: renderChevronDownIcon({ size }),
	[MULTI_SELECT_CF_TYPE]: renderChevronDownIcon({ size }),
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[DATE_CF_TYPE]: <CalendarIcon size={size} label="" />,
	[NUMBER_CF_TYPE]: <NumberGlyph />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[TIME_ESTIMATE_TYPE]: <EmojiFrequentIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[MULTI_USER_CF_TYPE]: <PeopleIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[DATETIME_CF_TYPE]: <RecentIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[COMPONENTS_TYPE]: <ComponentIcon size={size} label="" />,
	[STORY_POINTS_TYPE]: <NumberGlyph />,
	[STORY_POINT_ESTIMATE_CF_TYPE]: <NumberGlyph />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[LABELS_CF_TYPE]: <LabelIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[PEOPLE_CF_TYPE]: <PeopleIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[SUBTASKS_TYPE]: <SubtaskIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[CATEGORY_TYPE]: <MenuExpandIcon size={size} label="" />,
	[EPIC_TYPE]: <EpicIcon />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[COMMENT_TYPE]: <CommentIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[COMMENT_SUMMARY_TYPE]: <CommentIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[MULTI_CHECKBOXES_CF_TYPE]: <CheckboxIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[SPRINT_TYPE]: <SprintIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[REMOTE_ISSUE_LINKS_TYPE]: <PageIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[ISSUE_LINKS_TYPE]: <IssuesIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[CARD_COVERS_TYPE]: <MediaServicesScaleLargeIcon size={size} label="" />,
});

const iconSpacing = xcss({ margin: 'space.075', marginTop: 'space.050' });

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noneOption: {
		id: 'business-filters.controllers.group-item-values.none-option',
		defaultMessage: 'None',
		description:
			'Option shown on the number filters like storypoints and storypointestimate, for when there is no value.',
	},
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	JWMWorkflowWithIssueTypes,
	JWMWorkflowWithIssueTypesVariables,
} from './__generated_apollo__/JWMWorkflowWithIssueTypes';
import { WORKFLOWS_QUERY } from './gql';
import type { WorkflowDetails } from './types';

export const useFetchWorkflows = () => {
	const project = useProject();

	const [fetch, { data, loading, error }] = useLazyQuery<
		JWMWorkflowWithIssueTypes,
		JWMWorkflowWithIssueTypesVariables
	>(WORKFLOWS_QUERY, {
		variables: {
			projectId: project.id,
			first: 100,
		},
		onError: (apolloError) => {
			fireErrorAnalytics({
				meta: {
					id: 'jwmFetchWorkflows',
					packageName: 'jiraBusinessWorkflows',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to fetch workflows',
				},
				error: apolloError,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
	});

	const workflowsDetails = useMemo(
		() =>
			data?.jwmWorkflowWithIssueTypes?.edges?.map<WorkflowDetails>((edge) => {
				const { workflow, issueTypes } = edge?.node || {};
				return {
					workflowId: workflow!.workflowId,
					name: workflow?.name ?? undefined,
					transitions: workflow!.transitions!.edges!.map((transitionEdge) => ({
						transitionId: transitionEdge!.node!.transitionId!,
						name: transitionEdge!.node!.name ?? undefined,
						isInitial: transitionEdge!.node!.isInitial!,
						isGlobal: transitionEdge!.node!.isGlobal!,
						isConditional: transitionEdge!.node!.isConditional!,
						to: {
							statusId: transitionEdge!.node!.to!.statusId!,
						},
					})),

					issueTypes: issueTypes!.edges!.map((issueTypeEdge) => ({
						issueTypeId: issueTypeEdge!.node!.issueTypeId!,
						name: issueTypeEdge!.node!.name!,
						avatarUrl: issueTypeEdge!.node!.avatar!.medium!,
						hierarchyLevel: issueTypeEdge!.node!.hierarchy!.level!,
					})),
				};
			}),
		[data],
	);

	return { fetch, data: workflowsDetails, loading, error };
};

import React, { memo, useCallback } from 'react';
import noop from 'lodash/noop';
import {
	ActionsWrapper,
	LeftActionsWrapper,
	RightActionsWrapper,
} from '@atlassian/jira-business-app-wrapper/src/ui/actions-wrapper/index.tsx';
import type { QuickFilterKeys } from '@atlassian/jira-business-filters/src/common/types.tsx';
import { Filters } from '@atlassian/jira-business-filters/src/ui/filters/index.tsx';
import { SimpleSearchField } from '@atlassian/jira-business-filters/src/ui/simple-search-field/index.tsx';
import AddPeopleWithAvatar from '@atlassian/jira-business-invite-people/src/ui/add-people-with-avatars/index.tsx';
import { JWMShareButton } from '@atlassian/jira-business-invite-people/src/ui/jwm-share-button/index.tsx';
import MoreMenuCore from '@atlassian/jira-business-more-menu/src/ui/more-menu/more-menu-core/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	ISSUE_TYPE,
	PARENT_TYPE,
	LABELS_TYPE,
	MULTI_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	STATUS_TYPE,
	USER_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { useJWMBoardFeatures } from '../../controllers/features-context';
import { useWorkflowStoreState } from '../../controllers/workflow-store';
import GroupSelector from './group-selector';
import messages from './messages';
import { MoreMenuContent } from './more-menu-content';

const MORE_MENU_MIN_WIDTH = 260;
const WORKFLOWS_MENU_WIDTH = 320;
const HIDDEN_QUICKFILTERS: QuickFilterKeys[] = ['DONE', 'ACTIVE_SPRINTS'];

const ALLOWED_FIELD_TYPES = new Set<string>([
	ASSIGNEE_TYPE,
	ISSUE_TYPE,
	PRIORITY_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	LABELS_TYPE,
	MULTI_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	USER_CF_TYPE,
	STATUS_TYPE,
	PARENT_TYPE,
]);

const ALLOWED_FIELD_TYPES_OLD = new Set<string>([
	ASSIGNEE_TYPE,
	ISSUE_TYPE,
	PRIORITY_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	LABELS_TYPE,
	MULTI_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	USER_CF_TYPE,
	STATUS_TYPE,
]);

const Header = () => {
	const { isShareButtonEnabled, inviteButtonInteraction, isPresenceEnabled } =
		useJWMBoardFeatures();
	const { workflows } = useWorkflowStoreState();
	const hasMultipleWorkflows = workflows.length > 1;

	const moreMenuContent = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'closeMenu' implicitly has an 'any' type.
		(closeMenu) => <MoreMenuContent useCloseMenu={closeMenu ?? noop} />,
		[],
	);

	const shareButton = (
		<JWMShareButton
			shareTitle={messages.shareTitle}
			integrationType={integrationTypes.BOARD}
			shareContentType="board"
		/>
	);

	const searchBar = <SimpleSearchField placeholder={messages.searchBoardLabel} view="board" />;
	const addPeopleWithPresence = (
		<AddPeopleWithAvatar
			view="board"
			allowedFieldTypes={
				fg('jwm_board_implement_parent_filter') ? ALLOWED_FIELD_TYPES : ALLOWED_FIELD_TYPES_OLD
			}
			isPresenceEnabledInContext={isPresenceEnabled}
			isOpenNewTabOnClick={inviteButtonInteraction === 'openNewTab'}
			allowCustomFields={fg('jira_filter-fix_filter_by_custom_fields_and_avatar')}
		/>
	);

	const shareActionButton = isShareButtonEnabled && shareButton;

	const filters = (
		<Filters
			view="board"
			allowedFieldTypes={
				fg('jwm_board_implement_parent_filter') ? ALLOWED_FIELD_TYPES : ALLOWED_FIELD_TYPES_OLD
			}
			hiddenQuickFilters={HIDDEN_QUICKFILTERS}
			allowCustomFields
		/>
	);

	return (
		<UFOSegment name="business-board-header">
			<ActionsWrapper>
				<LeftActionsWrapper>
					{searchBar}
					{addPeopleWithPresence}
					{isVisualRefreshEnabled() && filters}
				</LeftActionsWrapper>

				<RightActionsWrapper>
					{!isVisualRefreshEnabled() && !fg('common_project_actions_menu') && shareActionButton}
					{!isVisualRefreshEnabled() && filters}
					<GroupSelector />
					<MoreMenuCore
						renderContent={moreMenuContent}
						minWidth={hasMultipleWorkflows ? WORKFLOWS_MENU_WIDTH : MORE_MENU_MIN_WIDTH}
					/>
				</RightActionsWrapper>
			</ActionsWrapper>
		</UFOSegment>
	);
};

export default memo(Header);

import React, { memo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { useAddPeople, AddPeopleProvider } from '../../../controllers/add-people-provider';
import { ProjectInvitePeopleModal } from './project-invite-people-modal';

type AddPeopleProps = AddPeopleModalProps & { children: ReactNode };

type AddPeopleModalProps = {
	shouldUseInvitePeopleModal?: boolean;
	source?: string;
};

const AddPeopleModal = ({ shouldUseInvitePeopleModal, source }: AddPeopleModalProps) => {
	const { isModalOpen, closeAddPeopleModal } = useAddPeople();

	if (isModalOpen) {
		return (
			<ProjectInvitePeopleModal
				onModalClose={closeAddPeopleModal}
				shouldUseInvitePeopleModal={shouldUseInvitePeopleModal}
				source={source}
			/>
		);
	}
	return null;
};

const AddPeople = ({ children, shouldUseInvitePeopleModal, source }: AddPeopleProps) => (
	<AddPeopleProvider>
		<ErrorBoundary
			id="jira-business.invite-people.ui.add-people"
			packageName="jira-business.invite-people.ui.add-people"
			teamName="jira-warepil"
		>
			{children}
			<InviteWrapper data-testid="business-invite-people.ui.add-people-with-avatars.add-people.invite-wrapper">
				<AddPeopleModal shouldUseInvitePeopleModal={shouldUseInvitePeopleModal} source={source} />
			</InviteWrapper>
		</ErrorBoundary>
	</AddPeopleProvider>
);

export default memo<AddPeopleProps>(AddPeople);

// this wrapper prevents the modal component to inherit any css logic from its parent, which could cause interferences in the consumer's UI
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InviteWrapper = styled.div({
	display: 'none',
});

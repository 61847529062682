import { RESOLUTION_TYPE } from '@atlassian/jira-platform-field-config';
import type { Sorting } from '../../../sorting/types';
import type { TransformedResolutionField } from '../types';

const getResolutionSorting: Sorting<TransformedResolutionField> = (field) =>
	field.value?.name ?? '';

export const resolutionSortingHandler = {
	[RESOLUTION_TYPE]: getResolutionSorting,
};

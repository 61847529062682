import { JastBuilder, print, type JQLParseError } from '@atlaskit/jql-ast';

// Using the filters menu, users can modify saved filters to other values.
// We should sanitise them so changes that would crash the page (e.g. including an order by)
// will not impact the page.
export const sanitiseJql = (jql: string | undefined) => {
	if (!jql) {
		return jql;
	}

	const builder = new JastBuilder();
	const jast = builder.build(jql);

	if (jast.query && jast.query.orderBy) {
		jast.query.orderBy = undefined;
	}

	return print(jast, {
		operatorCase: 'upper',
		printWidth: null,
	});
};

export const getJqlErrors = (jql: string | undefined | null): JQLParseError[] => {
	if (!jql) {
		return [];
	}

	const builder = new JastBuilder();
	const jast = builder.build(jql);

	return jast.errors;
};

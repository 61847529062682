type EmbedParam = 'inlineCreate' | 'selectedIssue' | 'inviteOpen';

type Param = {
	key: EmbedParam;
	value: string;
};

export const openViewFromEmbed = (param?: Param): void => {
	const cleanUrl = window.location.href.replace('/embed', '');
	const currentUrl = new URL(cleanUrl);
	if (param != null) {
		currentUrl.searchParams.append(param.key, param.value);
	}
	window.open(currentUrl.href, '_blank');
};

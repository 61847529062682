import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ThemedContainer = styled.div<{
	shouldAddPadding?: boolean;
	isSidebarOpen?: boolean;
	withSidebar?: boolean;
	withDynamicHeight?: boolean;
	withOverflow?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: ({ withSidebar }) => (withSidebar ? undefined : Tokens.COLOR_TEXT),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ withDynamicHeight }) => (withDynamicHeight ? 'auto' : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ withDynamicHeight }) => (withDynamicHeight ? '100%' : 'auto'),
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'flex-start',
	paddingTop: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ shouldAddPadding, isSidebarOpen }) =>
		!shouldAddPadding || isSidebarOpen ? 0 : token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ shouldAddPadding, isSidebarOpen }) =>
		!shouldAddPadding || isSidebarOpen ? 0 : token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ shouldAddPadding }) => (shouldAddPadding ? token('space.300', '24px') : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ withOverflow }) => (withOverflow ? 'auto' : 'visible'),
});

import React, { memo, useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { asyncIssueViewCriticalDataLoaderEntrypoint } from './entrypoint';

type Props = {
	issueKey: string;
};

const entryPointParams = {};
export const AsyncIssueViewCriticalDataLoader = memo<Props>((props) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncIssueViewCriticalDataLoaderEntrypoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jwm-issue-view-critical-data-loader"
			packageName="jiraBusinessIssueViewCriticalDataLoader"
			runtimeProps={props}
		/>
	);
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	workflowsErrorTitle: {
		id: 'business-workflows.controllers.workflows-context.workflows-error-title',
		defaultMessage: 'Failed to load workflows details',
		description: 'Error title informing the user loading workflows details failed.',
	},
	workflowsErrorDescription: {
		id: 'business-workflows.controllers.workflows-context.workflows-error-description',
		defaultMessage:
			"We couldn't load your workflow details, try refreshing the page or try again later.",
		description: 'Error message informing the user loading workflows details failed.',
	},
});

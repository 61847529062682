import { gqlTagGira } from '@atlassian/jira-apollo-gira';
import { CREATE_ISSUE_FRAGMENT } from '@atlassian/jira-business-issue-create/src/services/create-issue/gql.tsx';
import {
	JIS2_JWMBOARD_CONFLUENCE_LINKS_FRAGMENT,
	JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT,
	JIS2_JWMBOARD_ITEM_FRAGMENT,
	JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT,
} from '../board-issues/gql';

const CREATED_ITEM_FRAGMENT = gqlTagGira`
fragment CreatedBoardItemFragment on JiraWorkManagementViewItem {
    ...JIS2_JWMBoardItemFragment
    ...JIS2_JWMBoardConfluenceLinksFragment
}
${JIS2_JWMBOARD_ITEM_FRAGMENT}
${JIS2_JWMBOARD_CONFLUENCE_LINKS_FRAGMENT}
`;

export const CREATE_ISSUE_MUTATION = gqlTagGira`
mutation JWMBoardInlineCreateIssue($input: JiraCreateIssueInput!, $fieldIds: [String]!, $withCoverMedia: Boolean!, $withIssueLinks: Boolean!, $withChildrenCount: Boolean!) {
    createJiraBusinessIssue(input: $input, fieldIdsToReturn: $fieldIds) {
        item {
            ...CreatedBoardItemFragment
            ...JIS2_JWMBoardItemCoverMediaFragment @include(if: $withCoverMedia)
            ...JIS2_JWMBoardIssueLinksFragment @include(if: $withIssueLinks)
        }
        ...CreateIssueFragment
    }
}
${CREATED_ITEM_FRAGMENT}
${CREATE_ISSUE_FRAGMENT}
${JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT}
${JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT}
`;

export const ISSUE_REFETCH_QUERY = gqlTagGira`
query JWMBoardInlineCreateRefetchQuery($cloudId: ID!, $jql: String!, $fieldIds: [String]!, $first: Int, $withCoverMedia: Boolean!, $withIssueLinks: Boolean!, $withChildrenCount: Boolean!) {
    jira {
        jwmViewItems(cloudId: $cloudId, jql: $jql, first: $first) {
            ... on JiraWorkManagementViewItemConnection {
                edges {
                    node {
                        ...CreatedBoardItemFragment
                        ...JIS2_JWMBoardItemCoverMediaFragment @include(if: $withCoverMedia)
                        ...JIS2_JWMBoardIssueLinksFragment @include(if: $withIssueLinks)
                    }
                }
            }
        }
    }
}
${CREATED_ITEM_FRAGMENT}
${JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT}
${JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT}
`;

import React, {
	useReducer,
	createContext,
	useCallback,
	useContext,
	useMemo,
	type ReactNode,
	type Dispatch,
} from 'react';
import uuid from 'uuid';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import {
	GROUP_BY_STATUS,
	GROUP_BY_ASSIGNEE,
	GROUP_BY_PRIORITY,
	GROUP_BY_CATEGORY,
} from '@atlassian/jira-business-constants';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { ASSIGNEE_TYPE, CATEGORY_TYPE, PRIORITY_TYPE } from '@atlassian/jira-platform-field-config';
import { ISSUE_TYPE_ID } from '../../../common/constants';
import type { BoardIssue, Group } from '../../../common/types';
import { useIssueTransitions } from '../../issue-transitions';
import { useCardDragDropAnalytics } from '../use-card-drag-drop-analytics';

export type DropTarget = {
	groupId: string | null;
	transition: Transition | null;
};

type State = {
	draggedIssue: BoardIssue | null;
	sourceGroup: Group | null;
	dropTarget: DropTarget;
	updateSessionId: string | null;
	highlightedIssueId: number | null;
};

type Action =
	| {
			type: 'startDrag';
			value: { draggedIssue: BoardIssue; sourceGroup: Group; updateSessionId: string };
	  }
	| {
			type: 'cancelDrop';
	  }
	| {
			type: 'confirmDrop';
	  }
	| {
			type: 'updateDropTarget';
			value: Partial<DropTarget>;
	  }
	| {
			type: 'resetDropTarget';
	  }
	| {
			type: 'resetHighlightedIssue';
	  };

type Context = State & {
	dispatch: Dispatch<Action>;
};

const emptyDropTarget: DropTarget = {
	groupId: null,
	transition: null,
};

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'startDrag':
			return { ...state, ...action.value };
		case 'cancelDrop':
			return {
				...state,
				draggedIssue: null,
				sourceGroup: null,
				dropTarget: emptyDropTarget,
				updateSessionId: null,
			};
		case 'confirmDrop':
			return {
				...state,
				draggedIssue: null,
				sourceGroup: null,
				dropTarget: emptyDropTarget,
				updateSessionId: null,
				highlightedIssueId: state.draggedIssue?.id ?? null,
			};
		case 'updateDropTarget':
			return {
				...state,
				dropTarget: { ...state.dropTarget, ...action.value },
			};
		case 'resetDropTarget':
			return { ...state, dropTarget: emptyDropTarget };
		case 'resetHighlightedIssue':
			return { ...state, highlightedIssueId: null };
		default:
			return state;
	}
};

const initialState: State = {
	draggedIssue: null,
	sourceGroup: null,
	dropTarget: emptyDropTarget,
	updateSessionId: null,
	highlightedIssueId: null,
};

const CardDragDropContext = createContext<Context | null>(null);

export const CardDragDropProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value: Context = useMemo(
		() => ({
			...state,
			dispatch,
		}),
		[state],
	);

	return <CardDragDropContext.Provider value={value}>{children}</CardDragDropContext.Provider>;
};

const useCardDragDropContext = (): Context => {
	const context = useContext(CardDragDropContext);

	if (!context) {
		throw new Error('useCardDragDropContext must be used within a CardDragDropProvider');
	}

	return context;
};

export const useStartCardDrag = () => {
	const { dispatch } = useCardDragDropContext();
	const { fetch: fetchIssueTransitions } = useIssueTransitions();
	const { fireStartCardDragAnalytics } = useCardDragDropAnalytics();

	return useCallback(
		(issue: BoardIssue, group: Group) => {
			const updateSessionId = uuid.v4();

			dispatch({
				type: 'startDrag',
				value: { draggedIssue: issue, sourceGroup: group, updateSessionId },
			});

			fireStartCardDragAnalytics(updateSessionId);

			if (group.type === GROUP_BY_STATUS) {
				fetchIssueTransitions(issue);
			}
		},
		[dispatch, fetchIssueTransitions, fireStartCardDragAnalytics],
	);
};

export const useCancelConfirmCardDrop = () => {
	const { dispatch } = useCardDragDropContext();

	const cancelCardDrop = useCallback(() => dispatch({ type: 'cancelDrop' }), [dispatch]);
	const confirmCardDrop = useCallback(() => dispatch({ type: 'confirmDrop' }), [dispatch]);

	return {
		cancelCardDrop,
		confirmCardDrop,
	};
};

export const useUpdateDropTarget = () => {
	const { dispatch } = useCardDragDropContext();

	return useCallback(
		(newDropTarget: Partial<DropTarget>) =>
			dispatch({ type: 'updateDropTarget', value: newDropTarget }),
		[dispatch],
	);
};

export const useResetDropTarget = () => {
	const { dispatch } = useCardDragDropContext();

	return useCallback(() => dispatch({ type: 'resetDropTarget' }), [dispatch]);
};

export const useHighlightedIssue = () => {
	const { dispatch, highlightedIssueId } = useCardDragDropContext();

	const resetHighlightedIssue = useCallback(
		() => dispatch({ type: 'resetHighlightedIssue' }),
		[dispatch],
	);

	return { highlightedIssueId, resetHighlightedIssue };
};

export const useCardDragDrop = (): State => useCardDragDropContext();

const GROUP_TYPE_TO_FIELD_TYPE: Record<
	typeof GROUP_BY_ASSIGNEE | typeof GROUP_BY_CATEGORY | typeof GROUP_BY_PRIORITY,
	string
> = {
	[GROUP_BY_ASSIGNEE]: ASSIGNEE_TYPE,
	[GROUP_BY_CATEGORY]: CATEGORY_TYPE,
	[GROUP_BY_PRIORITY]: PRIORITY_TYPE,
};

type CanDropCardReturn = {
	canDropInGroup: 'yes' | 'no' | 'choice-available' | 'choice-required' | 'unknown';
	canDropAgainstCard: 'yes' | 'no' | 'unknown';
};

// eslint-disable-next-line complexity
export const useCanDropCard = (group: Group): CanDropCardReturn => {
	const project = useProject();
	const { typesWithFields } = useIssueTypesAndFields();
	const { sourceGroup, draggedIssue, dropTarget } = useCardDragDropContext();
	const { transitions, loading, hasError } = useIssueTransitions();
	const canRank = project.permissions.scheduleIssues;
	const isSameGroup = sourceGroup?.id === group.id;
	const areTransitionsUnavailable = loading || hasError;

	if (!draggedIssue) {
		return { canDropInGroup: 'unknown', canDropAgainstCard: 'unknown' };
	}

	if (group.type !== GROUP_BY_STATUS) {
		let canDropInGroup: CanDropCardReturn['canDropInGroup'] = 'unknown';
		if (!isSameGroup) {
			// check if the field is available for the dragged issue's issue type
			const fieldType = GROUP_TYPE_TO_FIELD_TYPE[group.type];
			const issueType = typesWithFields.find(
				(type) => type.issueType.id === draggedIssue.fields[ISSUE_TYPE_ID].issueType.id,
			);
			const hasField = issueType?.fields.some((field) => field.type === fieldType);
			canDropInGroup = hasField ? 'yes' : 'no';
		}

		return {
			canDropInGroup,
			canDropAgainstCard: canRank && canDropInGroup !== 'no' ? 'yes' : 'no',
		};
	}

	if (isSameGroup && areTransitionsUnavailable) {
		return { canDropInGroup: 'unknown', canDropAgainstCard: canRank ? 'yes' : 'no' };
	}

	if (areTransitionsUnavailable) {
		return { canDropInGroup: 'unknown', canDropAgainstCard: 'unknown' };
	}

	const transitionsToGroup = transitions.filter(
		(transition) => String(transition.toStatusId) === group.id,
	);

	let canDropInGroup: CanDropCardReturn['canDropInGroup'] = 'no';
	let canDropAgainstCard: CanDropCardReturn['canDropAgainstCard'] = canRank ? 'yes' : 'no';

	if (isSameGroup) {
		const selfTransitions = transitionsToGroup.filter((t) => !t.isGlobal);
		canDropInGroup = selfTransitions.length > 0 ? 'choice-available' : 'unknown';
		if (dropTarget.transition != null) {
			canDropInGroup = 'yes';
		}
	} else if (transitionsToGroup.length === 0) {
		canDropAgainstCard = 'no';
	} else if (transitionsToGroup.length === 1) {
		canDropInGroup = 'yes';
	} else if (transitionsToGroup.length > 1) {
		canDropInGroup = dropTarget.transition == null ? 'choice-required' : 'yes';
		canDropAgainstCard = canRank && canDropInGroup === 'yes' ? 'yes' : 'no';
	}

	return {
		canDropInGroup,
		canDropAgainstCard,
	};
};

export const useInferredDropTargetTransition = (group: Group) => {
	const { sourceGroup } = useCardDragDropContext();
	const { transitions } = useIssueTransitions();

	const destinationGroupId = group.id;

	if (
		sourceGroup?.type !== GROUP_BY_STATUS ||
		sourceGroup.id === destinationGroupId ||
		transitions.length === 0
	) {
		return null;
	}

	const transitionsToDestinationGroup = transitions.filter(
		(transition) => String(transition.toStatusId) === destinationGroupId,
	);

	return transitionsToDestinationGroup.length === 1 ? transitionsToDestinationGroup[0] : null;
};

export const useUpdateSessionId = () => {
	const { updateSessionId } = useCardDragDropContext();

	return updateSessionId;
};

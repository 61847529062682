import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupBySectionTitle: {
		id: 'work-management-board.header.group-selector.group-by-section-title',
		defaultMessage: 'GROUP BY',
		description: 'Section title for group by menu on JWM board',
	},
	groupByButtonAriaLabel: {
		id: 'work-management-board.header.group-selector.group-by-button-aria-label',
		defaultMessage: 'Group by {label}',
		description: 'Accessibility label for the group by button',
	},
	groupByButtonLabelOld: {
		id: 'work-management-board.header.group-selector.group-by-button-label-old',
		defaultMessage: 'Group by: ',
		description: 'First part of the group by button label',
	},
	groupByButtonLabel: {
		id: 'work-management-board.header.group-selector.group-by-button-label',
		defaultMessage: 'Group: ',
		description: 'First part of the group by button label',
	},
	groupByStatusLabel: {
		id: 'work-management-board.header.group-selector.group-by-status-label',
		defaultMessage: 'Status',
		description: 'Label to show on group by button if board is grouped by Status',
	},
	groupByStatusDefaultLabel: {
		id: 'work-management-board.header.group-selector.group-by-status-default-label',
		defaultMessage: 'Status (default)',
		description: 'Label to show on group by menu options for status',
	},
	groupByPriorityLabel: {
		id: 'work-management-board.header.group-selector.group-by-priority-label',
		defaultMessage: 'Priority',
		description: 'Label to show on group by button and as a group by menu option for priority',
	},
	groupByAssigneeLabel: {
		id: 'work-management-board.header.group-selector.group-by-assignee-label',
		defaultMessage: 'Assignee',
		description: 'Label to show on group by button and as a group by menu option for assignee',
	},
	groupByCategoryLabel: {
		id: 'work-management-board.header.group-selector.group-by-category-label',
		defaultMessage: 'Category',
		description: 'Label to show on group by button and as a group by menu option for category',
	},
	groupBySelected: {
		id: 'work-management-board.header.group-selector.group-by-selected',
		defaultMessage: 'Selected',
		description: 'Label for check icon that marks group by button as selected',
	},
	clearGroup: {
		id: 'work-management-board.header.group-selector.clear-group',
		defaultMessage: 'Clear',
		description: 'Content for button that resets board grouping to default status',
	},
	clearGroupLabel: {
		id: 'work-management-board.header.group-selector.clear-group-label',
		defaultMessage: 'Clear grouping',
		description: 'Label for button that resets board grouping to default status',
	},
});

const FIELD_VALUE_NODE_FRAGMENT_OLD = `
fragment FieldValueNodeFragment on JiraJqlFieldValue {
    jqlTerm
    displayName
    ... on JiraJqlUserFieldValue {
        user {
            picture
        }
    }
    ... on JiraJqlPriorityFieldValue {
        priority {
            iconUrl
        }
    }
    ... on JiraJqlIssueTypeFieldValue {
        issueTypes {
            issueTypeId
            avatar {
                medium
            }
            hierarchy {
                level
            }
        }
    }
    ... on JiraJqlStatusFieldValue {
        statusCategory {
            statusCategoryId
        }
    }
    ... on JiraJqlSprintFieldValue {
        sprint {
            sprintId
            name
        }
    }
    ... on JiraJqlIssueFieldValue {
        issue {
            fieldsByIdOrAlias(idsOrAliases: ["issuetype"]) {
                ... on JiraIssueTypeField {
                    issueType {
                        avatar {
                            medium
                        }
                    }
                }
            }
        }
    }
}
`;

export const HYDRATE_VALUES_QUERY_OLD = `
query JwmHydrateFilterValues($cloudId: ID!, $jql: String!) {
	jira {
        jqlBuilder(cloudId: $cloudId) {
            hydrateJqlQuery(query: $jql, viewContext: JWM) {
                ... on JiraJqlHydratedQuery {
                    fields {
                        ... on JiraJqlQueryHydratedField {
                            field {
                                jqlTerm
                            }
                            values {
                                ... on JiraJqlQueryHydratedValue {
                                    values {
                                        jqlTerm
                                        displayName
                                        ...FieldValueNodeFragment
                                    }
                                }
                            }
                        }
                    }
                }
                ... on QueryError {
                    message
                    extensions {
                      statusCode
                    }
                }
                __typename
            }
        }
	}
}
${FIELD_VALUE_NODE_FRAGMENT_OLD}
`;

const FIELD_VALUE_NODE_FRAGMENT = `
fragment FieldValueNodeFragment on JiraJqlFieldValue {
    jqlTerm
    displayName
    ... on JiraJqlUserFieldValue {
        user {
            picture
        }
    }
    ... on JiraJqlPriorityFieldValue {
        priority {
            iconUrl
        }
    }
    ... on JiraJqlIssueTypeFieldValue {
        issueTypes {
            issueTypeId
            avatar {
                medium
            }
            hierarchy {
                level
            }
        }
    }
    ... on JiraJqlStatusFieldValue {
        statusCategory {
            statusCategoryId
        }
    }
    ... on JiraJqlSprintFieldValue {
        sprint {
            sprintId
            name
        }
    }
    ... on JiraJqlGoalsFieldValue {
        goal {
            name
            status
        }
    }
    ... on JiraJqlIssueFieldValue {
        issue {
            fieldsByIdOrAlias(idsOrAliases: ["issuetype"]) {
                ... on JiraIssueTypeField {
                    issueType {
                        avatar {
                            medium
                        }
                    }
                }
            }
        }
    }
}
`;

export const HYDRATE_VALUES_QUERY = `
query JwmHydrateFilterValues($cloudId: ID!, $jql: String!) {
	jira {
        jqlBuilder(cloudId: $cloudId) {
            hydrateJqlQuery(query: $jql, viewContext: JWM) {
                ... on JiraJqlHydratedQuery {
                    fields {
                        ... on JiraJqlQueryHydratedField {
                            field {
                                jqlTerm
                            }
                            values {
                                ... on JiraJqlQueryHydratedValue {
                                    values {
                                        jqlTerm
                                        displayName
                                        ...FieldValueNodeFragment
                                    }
                                }
                            }
                        }
                    }
                }
                ... on QueryError {
                    message
                    extensions {
                      statusCode
                    }
                }
                __typename
            }
        }
	}
}
${FIELD_VALUE_NODE_FRAGMENT}
`;

import React, { type ComponentType } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import Placeholder from '@atlassian/jira-placeholder';
import ColumnCreateButton from './main';

const AsyncColumnCreate = lazyForPaint<ComponentType>(
	() => import(/* webpackChunkName: "async-jwm-board-create-column" */ './main'),
);

const ColumnCreate = componentWithFG(
	'jwm_board_create_column_modal_to_entrypoint',
	ColumnCreateButton,
	() => (
		<ErrorBoundary
			id="async.jira-business.board.column-create"
			packageName="async.jira-business.board.column-create"
		>
			<Placeholder name="column-create" fallback={null}>
				<AsyncColumnCreate />
			</Placeholder>
		</ErrorBoundary>
	),
);

export default ColumnCreate;

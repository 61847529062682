import React, { type ReactNode, createContext, useContext } from 'react';

type AriContextType = string;

export type AriContextProviderProps = {
	children: ReactNode;
	ari: string;
};

const AriContext = createContext<AriContextType>('');

export const AriProvider = ({ ari, children }: AriContextProviderProps) => (
	<AriContext.Provider value={ari}>{children}</AriContext.Provider>
);

export const useEntityAri = (): AriContextType => useContext(AriContext);

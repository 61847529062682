import React, { memo } from 'react';
import Spinner from '@atlaskit/spinner';
import { SpinnerWrapper } from './styled';

const MenuFallback = () => (
	<SpinnerWrapper>
		<Spinner size="medium" />
	</SpinnerWrapper>
);

export default memo(MenuFallback);

import React from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useInviteModalHandlers } from '../../../../controllers/modal-handlers';
import { AddPeopleModal } from './add-people-modal';
import { InvitePeopleModal } from './invite-people-modal';

type Props = {
	onModalClose: () => void;
	shouldUseInvitePeopleModal?: boolean;
	source?: string;
};

export const ProjectInvitePeopleModal = ({
	onModalClose,
	shouldUseInvitePeopleModal = false,
	source,
}: Props) => {
	const projectData = useProject();
	const { handleAddSuccess, handleError } = useInviteModalHandlers();

	if (!shouldUseInvitePeopleModal && projectData.isSimplified) {
		return (
			<AddPeopleModal
				onModalClose={onModalClose}
				onInviteSuccess={handleAddSuccess}
				onInviteError={handleError}
			/>
		);
	}

	return (
		<InvitePeopleModal
			onModalClose={onModalClose}
			onInviteSuccess={handleAddSuccess}
			onInviteError={handleError}
			source={source}
		/>
	);
};

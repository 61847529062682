import React, { memo, useMemo, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AvatarLite } from '@atlassian/jira-business-avatar-lite';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants';
import { isOptimisticIssue } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/index.tsx';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import {
	statusCategoryForId,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories';
import { CardDueDate } from '@atlassian/jira-platform-card/src/common/ui/due-date/index.tsx';
import { CardKey } from '@atlassian/jira-platform-card/src/common/ui/key/index.tsx';
import { CardLabels } from '@atlassian/jira-platform-card/src/common/ui/labels/index.tsx';
import { CardPriority } from '@atlassian/jira-platform-card/src/common/ui/priority/index.tsx';
import { CardSpinner } from '@atlassian/jira-platform-card/src/common/ui/spinner/index.tsx';
import { CardTick } from '@atlassian/jira-platform-card/src/common/ui/tick/index.tsx';
import {
	LABEL_ID,
	STATUS_ID,
	DUE_DATE_ID,
	ISSUE_TYPE_ID,
	PRIORITY_ID,
	ASSIGNEE_ID,
	ISSUE_KEY_ID,
} from '../../../../../common/constants';
import type { BoardIssue } from '../../../../../common/types';
import { useSelectedFields } from '../../../../../controllers/fields-preference';
import { useGroupByField } from '../../../../../controllers/group-by';
import CardDetailFields from './card-detail-fields';
import CardIssueType from './card-issue-type';
import Subtasks from './subtasks';

type Props = {
	highlight?: string[];
	issue: BoardIssue;
	onIssueKeyClick: (event: MouseEvent<HTMLElement>) => void;
};

export const CardContent = memo(({ highlight, issue, onIssueKeyClick }: Props) => {
	const groupBy = useGroupByField();
	const selectedFields = useSelectedFields();
	const isNewOptimisticIssue = isOptimisticIssue(issue.id);

	const labels = issue.fields[LABEL_ID]?.value ?? undefined;
	const isDone = issue.fields[STATUS_ID].status.statusCategoryId === StatusCategoryIds.done;
	const isStandardIssue = issue.fields[ISSUE_TYPE_ID].issueType.hierarchyLevel === 0;

	const renderedLabels = useMemo(
		() =>
			labels && selectedFields.labels ? (
				<SingleFieldRow>
					<CardLabels labels={labels} highlight={highlight} />
				</SingleFieldRow>
			) : null,
		[selectedFields.labels, labels, highlight],
	);

	const renderedDueDate = useMemo(
		() =>
			issue.fields[DUE_DATE_ID] != null && selectedFields.duedate ? (
				<SingleFieldRow>
					<CardDueDate dueDate={issue.fields[DUE_DATE_ID].value} isCompleted={isDone} />
				</SingleFieldRow>
			) : null,
		[issue.fields, isDone, selectedFields.duedate],
	);

	const renderedIssueType = useMemo(
		() =>
			selectedFields.issuetype ? <CardIssueType issueType={issue.fields[ISSUE_TYPE_ID]} /> : null,
		[issue.fields, selectedFields.issuetype],
	);

	const renderedPriority = useMemo(() => {
		const priority = issue.fields[PRIORITY_ID];
		return priority && selectedFields.priority ? (
			<CardPriority uri={priority.priority.iconUrl} name={priority.priority.name} />
		) : null;
	}, [selectedFields.priority, issue.fields]);

	const renderedAssignee = useMemo(() => {
		const assignee = issue.fields[ASSIGNEE_ID];
		if (assignee?.user && selectedFields.assignee) {
			return (
				<AvatarLite
					highlight={highlight}
					avatarUrl={assignee.user.avatarURL ?? undefined}
					name={assignee.user.name}
				/>
			);
		}

		return null;
	}, [highlight, issue.fields, selectedFields.assignee]);

	const renderedKey = useMemo(
		() =>
			selectedFields.key && (
				<IssueKeyWrapper>
					<CardKey
						highlight={highlight}
						onClick={onIssueKeyClick}
						text={issue.fields[ISSUE_KEY_ID].value}
					/>
				</IssueKeyWrapper>
			),
		[highlight, issue.fields, onIssueKeyClick, selectedFields.key],
	);

	const renderedStatus = useMemo(() => {
		const { status } = issue.fields[STATUS_ID];

		return (
			groupBy !== GROUP_BY_STATUS &&
			status.statusCategoryId &&
			status.name && (
				<SingleFieldRow>
					<StatusLozenge
						name={status.name}
						category={statusCategoryForId(status.statusCategoryId)}
					/>
				</SingleFieldRow>
			)
		);
	}, [groupBy, issue.fields]);

	const showMultiFieldRow =
		renderedIssueType || renderedKey || isDone || renderedPriority || renderedAssignee;

	return (
		<>
			<ContentWrapper>
				{renderedStatus}
				{renderedLabels}
				{renderedDueDate}
				{showMultiFieldRow && (
					<MultiFieldRow>
						{renderedIssueType}
						{renderedKey}
						{isDone && <CardTick />}
						{renderedPriority}
						{renderedAssignee}
					</MultiFieldRow>
				)}
				{selectedFields.subtasks && isStandardIssue && <Subtasks issue={issue} />}
				<CardDetailFields issue={issue} />
			</ContentWrapper>
			{isNewOptimisticIssue && (
				<SpinnerContainer>
					<CardSpinner />
				</SpinnerContainer>
			)}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
	padding: `0 ${token('space.150', '12px')} ${token('space.150', '12px')} ${token('space.150', '12px')}`,
	'&:empty': {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	position: 'absolute',
	top: token('space.200', '16px'),
	right: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiFieldRow = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	// prevent layout shift when fields appear
	minHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKeyWrapper = styled.div({
	display: 'flex',
	flex: 1,
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleFieldRow = styled.div({
	display: 'flex',
	alignItems: 'center',
});

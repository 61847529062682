import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	doneItems: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtask-list.subtask-progress-bar.done-items',
		defaultMessage: '{itemCount} of {totalCount} done',
		description:
			'Tooltip around section of subtask progress bar indicating how many subtasks are complete',
	},
	inProgressItems: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtask-list.subtask-progress-bar.in-progress-items',
		defaultMessage: '{itemCount} of {totalCount} in progress',
		description:
			'Tooltip around section of subtask progress bar indicating how many subtasks are in progress',
	},
	todoItems: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtask-list.subtask-progress-bar.todo-items',
		defaultMessage: '{itemCount} of {totalCount} to do',
		description:
			'Tooltip around section of subtask progress bar indicating how many subtasks are in todo status',
	},
});

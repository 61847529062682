import React, { forwardRef, memo, type Ref } from 'react';
import { styled } from '@compiled/react';
import { LoadingButton, type LoadingButtonProps } from '@atlaskit/button';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';

export const ThemedButton = memo(
	forwardRef(({ as, ...props }: LoadingButtonProps, ref: Ref<HTMLElement>) => (
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		<CustomButton {...props} as={as as keyof JSX.IntrinsicElements} ref={ref} />
	)),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomButton = styled(LoadingButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-brand-bold': Tokens.COLOR_BACKGROUND_BRAND_BOLD,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-brand-bold-hovered': Tokens.COLOR_BACKGROUND_BRAND_BOLD_HOVERED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-brand-bold-pressed': Tokens.COLOR_BACKGROUND_BRAND_BOLD_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-disabled': Tokens.COLOR_BACKGROUND_DISABLED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-selected': Tokens.COLOR_BACKGROUND_SELECTED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral': Tokens.COLOR_BACKGROUND_NEUTRAL,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-hovered': Tokens.COLOR_BACKGROUND_NEUTRAL_HOVERED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-pressed': Tokens.COLOR_BACKGROUND_NEUTRAL_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-subtle-hovered': Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-subtle-pressed': Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-icon-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-link': Tokens.COLOR_LINK,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-link-pressed': Tokens.COLOR_LINK_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text': Tokens.COLOR_TEXT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-disabled': Tokens.COLOR_TEXT_DISABLED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-inverse': Tokens.COLOR_TEXT_INVERSE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-selected': Tokens.COLOR_TEXT_SELECTED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-border': Tokens.COLOR_BORDER,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-border-selected': Tokens.COLOR_BORDER_SELECTED,
});

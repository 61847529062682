import { useCallback } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsSource as useIssueViewAnalyticsSource } from '@atlassian/jira-issue-context-service';
import { useIssueTransitionUIAnalyticsEvent } from '@atlassian/jira-issue-transition-analytics';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIssueTransitionLabsOptIn } from '@atlassian/jira-router-resources-issue-transition-labs-opt-in';
import type {
	CloseIssueTransitionModal,
	IssueTransitionModalPayload,
	OpenIssueTransitionModal,
	State,
	TriggerIssueTransitionModalActions,
} from '../../common/types';
import { usePublishOpenIssueTransitionModalPubSub } from '../../controllers/open-issue-transition-modal-pub-sub/use-publish-open-issue-transition-modal-pub-sub';
import {
	useTriggerIssueTransitionModalActions,
	useTriggerIssueTransitionModalState,
} from '../../controllers/trigger-issue-transition-modal';
import { openLegacyIssueTransitionModal } from '../../controllers/utils';

export const useOpenIssueTransitionModal = (): OpenIssueTransitionModal => {
	const { openIssueTransitionModal: openIssueTransitionModalAction } =
		useTriggerIssueTransitionModalActions();
	const { stateGet: { state: { isEnabled = true } = {} } = {} } = useIssueTransitionLabsOptIn();
	const publish = usePublishOpenIssueTransitionModalPubSub();

	const source = useIssueViewAnalyticsSource();
	const { fireUIEvent } = useIssueTransitionUIAnalyticsEvent();

	return useCallback(
		(nextState?: IssueTransitionModalPayload) => {
			if (ff('modernised-issue-transition-iframe-apps-fix_mlwcn') && source === 'connect-issue') {
				if (fg('its_third_party_apps_instrumentation')) {
					// fire UI event when issue transition is opened via connect API
					fireUIEvent({
						actionSubject: 'issueTransition',
						actionSubjectId: 'connectIssue',
						action: 'opened',
					});
				}
				publish(nextState, isEnabled);
			} else {
				openIssueTransitionModalAction(nextState, isEnabled);
			}
		},
		[fireUIEvent, isEnabled, openIssueTransitionModalAction, publish, source],
	);
};

export const useCloseIssueTransitionModal = (): CloseIssueTransitionModal => {
	const { closeIssueTransitionModal: closeIssueTransitionModalAction } =
		useTriggerIssueTransitionModalActions();
	const {
		payload: { issueKey, transitionId },
		onDialogCancel,
		onDialogError,
		onDialogSuccess,
	} = useTriggerIssueTransitionModalState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(shouldOpenLegacyModal = false) => {
			closeIssueTransitionModalAction();
			if (shouldOpenLegacyModal) {
				const onOpenDialog = () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'switch to legacy transition screen');
				};
				openLegacyIssueTransitionModal({
					issueKey,
					isReopenScenario: true,
					transitionId,
					onDialogCancel,
					onDialogError,
					onDialogSuccess,
					onOpenDialog,
				});
			}
		},
		[
			closeIssueTransitionModalAction,
			createAnalyticsEvent,
			issueKey,
			onDialogCancel,
			onDialogError,
			onDialogSuccess,
			transitionId,
		],
	);
};

export const useTriggerIssueTransitionModal = (): [State, TriggerIssueTransitionModalActions] => {
	const state = useTriggerIssueTransitionModalState();
	const actions = useTriggerIssueTransitionModalActions();
	const openIssueTransitionModal = useOpenIssueTransitionModal();

	return [state, { ...actions, openIssueTransitionModal }];
};

export default useTriggerIssueTransitionModal;

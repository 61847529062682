import React, { memo, useCallback } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { IssueLinksStats } from '@atlassian/jira-issue-links-stats-business';
import type { BoardIssueId, BoardIssueIssueTypeField } from '../../../../../../common/types';
import { useRefetchIssues } from '../../../../../../controllers/refetch-issues';

type Props = {
	issueKey: string;
	issueId: BoardIssueId;
	issueSummary: string;
	issueLinksCount: number;
	issueTypeField?: BoardIssueIssueTypeField;
};

const IssueLinksNotMemo = ({
	issueKey,
	issueId,
	issueSummary,
	issueLinksCount,
	issueTypeField,
}: Props) => {
	const {
		permissions: { linkIssues },
	} = useProject();
	const refetchIssues = useRefetchIssues();

	const onIssueUnlink = useCallback(
		(issueIds: string[]) => {
			refetchIssues(issueIds.map(Number));
		},
		[refetchIssues],
	);

	const onIssueLink = useCallback(
		(originalIssueId: string, updateIssueIds: string[]) => {
			const issueIds = [originalIssueId, ...updateIssueIds];
			refetchIssues(issueIds.map(Number));
		},
		[refetchIssues],
	);

	return (
		<IssueLinksStats
			testId="work-management-board.ui.board.column.card.card-footer.issue-links"
			issueLinksCount={issueLinksCount}
			issueKey={issueKey}
			issueId={String(issueId)}
			issueSummary={issueSummary}
			issueType={issueTypeField?.issueType}
			feedbackCollectorPrefix="business-board"
			isLinkingEnabled={linkIssues}
			sourceProductType={CORE_PROJECT}
			onIssueUnlink={onIssueUnlink}
			onIssueLink={onIssueLink}
			maxIssueLinksStatsCount={9}
		/>
	);
};

export const IssueLinks = memo(IssueLinksNotMemo);

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueLinksDetailsErrorFlagTitle: {
		id: 'issue-links-stats-business.issue-links-details-error-fallback.issue-links-details-error-flag-title',
		defaultMessage: "We couldn't load your data",
		description: 'Title for the error flag shown when it fails when entrypoint fails to load',
	},
	issueLinksDetailsErrorFlagDescription: {
		id: 'issue-links-stats-business.issue-links-details-error-fallback.issue-links-details-error-flag-description',
		defaultMessage: 'Refresh the page and try again, or contact your admin.',
		description: 'Main Message for the error flag shown when when entrypoint fails to load',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	multipleWorkflowsBoardActionsDisabled: {
		id: 'work-management-board.common.open-project-settings-multiple-workflows.multiple-workflows-board-actions-disabled',
		defaultMessage:
			"You can't add, delete, or rename columns when a board has multiple workflows. You can {linkStart}change your workflows in project settings{linkEnd}.",
		description:
			'Message shown when the project has multiple workflows, so it needs to be edited through project settings',
	},
});

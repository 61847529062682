import identity from 'lodash/identity';
import type { Preference } from './types';

export const createSimplePreference = <T,>(id: string): Preference<T> => ({
	id,
	serialize: identity,
	deserialize: identity,
});

export const createSimpleUrlPreference = (queryParameter: string): Preference<string> => ({
	id: queryParameter,
	serialize: identity,
	deserialize: identity,
	queryParameter: {
		serialize: (value: string | undefined) => ({ [queryParameter]: value }),
		deserialize: (value: { [key: string]: string }) => value[queryParameter],
	},
});

export const PREDICATE_OPERATOR_AFTER = 'after';
export const PREDICATE_OPERATOR_BEFORE = 'before';
export const PREDICATE_OPERATOR_BY = 'by';
export const PREDICATE_OPERATOR_DURING = 'during';
export const PREDICATE_OPERATOR_FROM = 'from';
export const PREDICATE_OPERATOR_ON = 'on';
export const PREDICATE_OPERATOR_TO = 'to';

export const PREDICATE_OPERATORS = [
	PREDICATE_OPERATOR_AFTER,
	PREDICATE_OPERATOR_BEFORE,
	PREDICATE_OPERATOR_BY,
	PREDICATE_OPERATOR_DURING,
	PREDICATE_OPERATOR_FROM,
	PREDICATE_OPERATOR_ON,
	PREDICATE_OPERATOR_TO,
] as const;

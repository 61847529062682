export type ModalType = 'Project' | 'Instance';

export const InvitationStatusEnum = {
	ACCESS_REQUESTED: 'ACCESS_REQUESTED',
	INVITED: 'INVITED',
} as const;

export type InvitationStatusEnum = (typeof InvitationStatusEnum)[keyof typeof InvitationStatusEnum];

export type ModalProps = {
	onInviteSuccess: (props: {
		totalPeopleAdded: number;
		email: string;
		modalType: ModalType;
		inviteStatus?: InvitationStatusEnum;
	}) => void;
	onInviteError: (props: {
		modalType: ModalType;
		errorMessage: string;
		statusCode?: number;
	}) => void;
	onModalClose: () => void;
	source?: string;
};

import React from 'react';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import { MULTI_GROUP_CF_TYPE, GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getGroupIcons: GetIconFunction = (size) => ({
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[GROUP_CF_TYPE]: <PeopleGroupIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[MULTI_GROUP_CF_TYPE]: <PeopleGroupIcon size={size} label="" />,
});

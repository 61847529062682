import { useCallback } from 'react';
import uniq from 'lodash/uniq';
import { EXPANDED_ISSUES_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';

const EXPANDED_ISSUES_LIMIT = 20;

export const useExpandCollapseSubtasks = (issueId: number) => {
	const [expandedIssues, setExpandedIssues] = useViewPreference(EXPANDED_ISSUES_PREFERENCE);

	const isExpanded = Boolean(expandedIssues?.includes(issueId));

	const collapseSubtasks = useCallback(() => {
		const newExpandedIssues = expandedIssues?.filter((id) => issueId !== id);
		setExpandedIssues(newExpandedIssues);
	}, [expandedIssues, issueId, setExpandedIssues]);

	const expandSubtasks = useCallback(() => {
		const newExpandedIssues = uniq([...(expandedIssues || []), issueId]);

		// Trim to the last 20 items as we don't want to store too many expanded issues
		if (newExpandedIssues.length > EXPANDED_ISSUES_LIMIT) {
			newExpandedIssues.splice(0, newExpandedIssues.length - EXPANDED_ISSUES_LIMIT);
		}

		setExpandedIssues(newExpandedIssues);
	}, [expandedIssues, issueId, setExpandedIssues]);

	const toggleSubtasks = useCallback(() => {
		isExpanded ? collapseSubtasks() : expandSubtasks();
	}, [isExpanded, collapseSubtasks, expandSubtasks]);

	return {
		isExpanded,
		toggleSubtasks,
	};
};

import { useEffect } from 'react';
import { INLINE_CREATE_PARAM } from '@atlassian/jira-business-constants';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useQueryParam } from '@atlassian/jira-router';
import { useCreateIssueDialog } from '../create-issue-dialog';

export const useCreateParamHandler = () => {
	const [inlineCreateParam, setInlineCreateParam] = useQueryParam(INLINE_CREATE_PARAM);
	const showCreateIssueDialog = useCreateIssueDialog();
	const project = useProject();

	useEffect(() => {
		if (inlineCreateParam != null) {
			showCreateIssueDialog({
				projectId: String(project.id),
			}).catch(() => {
				// we don't care about the error in this scenario
			});
			setInlineCreateParam(undefined);
		}
	}, [inlineCreateParam, project, setInlineCreateParam, showCreateIssueDialog]);
};

import React, {
	createContext,
	useContext,
	useMemo,
	useState,
	type ReactNode,
	useCallback,
	type Dispatch,
	type SetStateAction,
} from 'react';
import type { DraggableState } from '../../../common/types';

type Context = {
	draggedColumnId: DragState['draggedColumnId'];
	dragPreviewState: DragState['dragPreviewState'];
	setDragState: Dispatch<SetStateAction<DragState>>;
};

type DragState = {
	draggedColumnId: string | null;
	dragPreviewState: DraggableState;
};

const ColumnDragDropContext = createContext<Context | null>(null);

export const ColumnDragDropProvider = ({ children }: { children: ReactNode }) => {
	const [dragState, setDragState] = useState<DragState>({
		draggedColumnId: null,
		dragPreviewState: { type: 'idle' },
	});

	const value: Context = useMemo(
		() => ({
			...dragState,
			setDragState,
		}),
		[dragState],
	);

	return <ColumnDragDropContext.Provider value={value}>{children}</ColumnDragDropContext.Provider>;
};

const useColumnDragDropContext = () => {
	const context = useContext(ColumnDragDropContext);

	if (!context) {
		throw new Error('useColumnDragDropContext must be used within a ColumnDragDropProvider');
	}

	return context;
};

export const useColumnDragDrop = (columnId: string) => {
	const { draggedColumnId, dragPreviewState, setDragState } = useColumnDragDropContext();

	const startColumnDrag = useCallback(
		() =>
			setDragState((prevDragState) => ({
				...prevDragState,
				dragPreviewState: {
					...prevDragState.dragPreviewState,
					draggedColumnId: columnId,
				},
			})),
		[columnId, setDragState],
	);

	const resetDraggedColumn = useCallback(
		() => setDragState((prevDragState) => ({ ...prevDragState, draggedColumnId: null })),
		[setDragState],
	);

	const renderDragPreview = useCallback(
		(container: HTMLElement) =>
			setDragState((prevDragState) => ({
				...prevDragState,
				draggedColumnId: columnId,
				dragPreviewState: { container, type: 'preview' },
			})),
		[columnId, setDragState],
	);

	const cleanupDragPreview = useCallback(
		() =>
			setDragState((prevDragState) => ({
				...prevDragState,
				dragPreviewState: { type: 'idle' },
			})),
		[setDragState],
	);

	const isDragging = draggedColumnId === columnId;

	return {
		cleanupDragPreview,
		dragPreviewState,
		isDragging,
		renderDragPreview,
		resetDraggedColumn,
		startColumnDrag,
	};
};

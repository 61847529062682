import { PubSubHub } from './pubsub';
import type { PubSub } from './pubsub/types';
import type { ITSPubSubEvents, ITSPubSubPayloads } from './types';

export const ITSPubSub: PubSub<ITSPubSubEvents, ITSPubSubPayloads> = new PubSubHub<
	ITSPubSubEvents,
	ITSPubSubPayloads
>();

export const useIssueTransitionScreenPubSub = () => ({
	...ITSPubSub,
});

import get from 'lodash/get';

export type Field = {
	fieldKey?: string;
	fieldId?: string;
	aliasFieldId?: string;
};

export const getFieldValue = <T extends Field>(
	fields: ReadonlyArray<T> | undefined | null,
	fieldKey: string | undefined,
): T | undefined => {
	if (fields && fieldKey != null) {
		return fields.find(
			(field) =>
				(field?.fieldKey && field.fieldKey === fieldKey) ||
				(field?.fieldId && field.fieldId === fieldKey) ||
				(field?.aliasFieldId && field.aliasFieldId === fieldKey),
		);
	}
	return undefined;
};

export const safeGetValue = <T,>(
	fields: ReadonlyArray<Field> | undefined | null,
	fieldKey: string | undefined,
	attributeName: string,
	defaultValue: T,
): T => {
	const field = getFieldValue(fields, fieldKey);
	return get(field, attributeName, defaultValue);
};

export const safeGetTransformedFieldValue = <T,>(
	fields: ReadonlyArray<Field> | undefined | null,
	fieldKey: string | undefined,
	attributeName: string,
	defaultValue: T,
): T => {
	const field = getFieldValue(fields, fieldKey);
	return safeGetTransformedValue(field, defaultValue);
};

export const safeGetTransformedValue = <T,>(field: Field | undefined, defaultValue: T): T =>
	get(field, 'value', defaultValue);

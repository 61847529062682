type MutationErrorExtension = {
	__typename?: string;
	statusCode?: number | null;
	errorType?: string | null;
	fieldId?: string | null;
};

export const MUTATION_ERROR_TYPE = 'MutationError';

export type MutationErrorPayload = {
	readonly __typename?: string;
	readonly message?: string | null;
	readonly extensions?: MutationErrorExtension | null;
};

export class MutationError extends Error {
	extensions: MutationErrorExtension | undefined | null;

	statusCode: number | null;

	constructor(payload: MutationErrorPayload) {
		super(payload.message ?? undefined);
		this.message = payload.message ?? '';
		this.extensions = payload.extensions;
		this.statusCode = payload.extensions?.statusCode ?? null;
	}
}

import { ATLASSIAN_ACCOUNT, SITE, SITE_USER, ORG, ORG_USER } from './constants';
import type { TraitName, TraitsState, Trait, TraitType } from './types';

export const getTotalTraits = (traitsData: TraitsState) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.entries(traitsData).reduce((acc: any, [, val]) => {
		if (val?.attributes) {
			acc.push(...val.attributes.map((singleTrait) => singleTrait));
		}
		return acc;
	}, []);

export const buildTraitEndpointEndingFragment = ({
	traitType,
	accountId,
	cloudId,
	orgId,
}: {
	traitType: TraitType;
	cloudId?: string | null;
	accountId?: string | null;
	orgId?: string | null;
}) => {
	switch (traitType) {
		case ATLASSIAN_ACCOUNT:
			return `user/${accountId}`;
		case SITE:
			return `site/${cloudId}`;
		case SITE_USER:
			return `site/${cloudId}/user/${accountId}`;
		case ORG:
			return `org/${orgId}`;
		case ORG_USER:
			return `org/${orgId}/user/${accountId}`;
		default:
			return null;
	}
};

export function getTraitByName<T = TraitName>(traitsData: TraitsState, traitName: NoInfer<T>) {
	return getTotalTraits(traitsData)?.find((trait: Trait) => trait.name === traitName);
}

export const getTraitsByName = <T extends Array<string> = TraitName[]>(
	traitsData: TraitsState,
	traitNames: NoInfer<T>,
) => getTotalTraits(traitsData)?.filter((trait: Trait) => traitNames.includes(trait.name));

export const userCreatedAfter = (traitsData: TraitsState, date: Date) => {
	const accountCreationDate = getTraitByName(traitsData, 'jira_first_active')?.value;
	if (!accountCreationDate) return false;

	return new Date(accountCreationDate) > date;
};

import React, {
	createContext,
	useContext,
	useMemo,
	useState,
	useCallback,
	type ReactNode,
	useRef,
} from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type OnClose = () => void;
type AddPeopleContextType = {
	triggerBtnRef: {
		current: HTMLButtonElement | null;
	};
	isModalOpen: boolean;
	openAddPeopleModal: (onClose?: OnClose, source?: string) => void;
	closeAddPeopleModal: () => void;
};

type AddPeopleProviderProps = {
	children: ReactNode;
};

const AddPeopleContext = createContext<AddPeopleContextType>({
	triggerBtnRef: {
		current: null,
	},
	isModalOpen: false,
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	openAddPeopleModal: () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	closeAddPeopleModal: () => {},
});

export const AddPeopleProvider = ({ children }: AddPeopleProviderProps) => {
	const projectData = useProject();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const triggerBtnRef = useRef<HTMLButtonElement | null>(null);
	const onCloseCallbackRef = useRef<unknown>();

	const openAddPeopleModal = useCallback(
		(onClose?: OnClose, source?: string) => {
			setIsModalOpen(true);

			const eventName = projectData.isSimplified
				? 'jwmProjectInviteComponent'
				: 'jwmInstanceInviteComponent';

			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', eventName, { source });

			if (onClose) {
				onCloseCallbackRef.current = onClose;
			}
		},
		[createAnalyticsEvent, projectData.isSimplified],
	);

	const closeAddPeopleModal = useCallback(() => {
		setIsModalOpen(false);

		if (typeof onCloseCallbackRef.current === 'function') {
			onCloseCallbackRef.current();

			onCloseCallbackRef.current = null;
		}
	}, []);

	const context = useMemo(
		() => ({
			triggerBtnRef,
			isModalOpen,
			openAddPeopleModal,
			closeAddPeopleModal,
		}),
		[triggerBtnRef, isModalOpen, openAddPeopleModal, closeAddPeopleModal],
	);

	return <AddPeopleContext.Provider value={context}>{children}</AddPeopleContext.Provider>;
};

export const useAddPeople = (): AddPeopleContextType => useContext(AddPeopleContext);

import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Response } from './types';

export const fetchIssueTransitions = async (
	signal: AbortSignal,
	issueId: number,
): Promise<Transition[]> => {
	try {
		const response = await performGetRequest<Response>(`/rest/api/3/issue/${issueId}/transitions`, {
			signal,
		});

		return response.transitions.map((transition) => ({
			transitionId: Number(transition.id),
			name: transition.name,
			isConditional: transition.isConditional,
			isGlobal: transition.isGlobal,
			isInitial: transition.isInitial,
			hasScreen: transition.hasScreen,
			toStatusId: Number(transition.to.id),
		}));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (!isHttpClientErrorResponse(error) && !signal.aborted) {
			fireErrorAnalytics({
				meta: {
					id: 'fetchIssueTransitions',
					packageName: 'jiraWorkManagementBoard',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		throw error;
	}
};

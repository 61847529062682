import type { Workflow } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import type { WorkflowDetails } from '@atlassian/jira-business-workflows/src/services/workflows/types.tsx';

export const transformWorkflow = ({
	issueTypes,
	transitions,
	workflowId,
	workflowName,
}: Workflow): WorkflowDetails => ({
	issueTypes: issueTypes.map(({ iconUrl, hierarchyLevel, id, name }) => ({
		avatarUrl: iconUrl,
		hierarchyLevel,
		issueTypeId: id,
		name,
	})),
	name: workflowName,
	transitions: transitions.map(
		({ isConditional, isGlobal, isInitial, name, toStatusId, transitionId }) => ({
			isConditional,
			isGlobal,
			isInitial,
			name,
			to: {
				statusId: String(toStatusId),
			},
			transitionId: String(transitionId),
		}),
	),
	workflowId,
});

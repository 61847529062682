import React, { useMemo, type ReactNode } from 'react';
import { AriProvider } from '@atlassian/jira-business-entity-common/src/controllers/ari-context/index.tsx';
import { BusinessEntityProvider } from '@atlassian/jira-business-entity-common/src/controllers/business-entity-context/index.tsx';
import { JqlProvider } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { RealtimeProvider } from '@atlassian/jira-business-entity-common/src/controllers/realtime-context/index.tsx';
import { ProjectHookProvider } from '@atlassian/jira-business-entity-project-hook';
import {
	ExperienceStart,
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { FiltersProvider } from '@atlassian/jira-business-filters/src/controllers/filters-context/index.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-business-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/jira-router';
import { useProject } from '@atlassian/jira-router-resources-business-project-details';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { FETCH_PROJECT_EXPERIENCE } from '../../constants';
import NotFound from './not-found';

type Props = {
	children: ReactNode;
};

const ProjectContextProvider = ({ children }: Props) => {
	const { data: project, loading, error } = useProject();
	const { replace } = useRouterActions();
	const cloudId = useCloudId();

	const projectId = project?.id;
	const projectKey = project?.key;
	const projectType = project?.type;
	const isTeamManaged = Boolean(project?.isSimplified);

	const jqlContext = projectKey ? `project = '${projectKey}'` : '';

	const analyticsAttributes = useMemo(
		() => ({
			projectId,
			isTeamManaged,
			projectType,
		}),
		[projectId, isTeamManaged, projectType],
	);

	const entityData = useMemo(
		() =>
			project?.permissions?.viewIssues
				? {
						name: project.name,
						id: String(project.id),
					}
				: null,
		[project],
	);

	const projects = useMemo(() => (project ? [project] : undefined), [project]);
	const projectIdList = useMemo(() => (projectId ? [projectId] : undefined), [projectId]);
	const projectAri = `ari:cloud:jira:${cloudId}:project/${projectId}`;

	if (project?.status === 'ARCHIVED') {
		replace('/jira/errors/project-archived');
		return null;
	}

	let content;

	if (loading) {
		content = <ThemedLazySkeleton />;
	} else if (project == null || entityData == null || error) {
		content = (
			<>
				<NotFound />
				{error && (
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					<ExperienceFailed experience={FETCH_PROJECT_EXPERIENCE} error={error as any} />
				)}
			</>
		);
	} else {
		content = (
			<ProjectHookProvider project={project}>
				<BusinessEntityProvider data={entityData}>
					<RealtimeProvider ids={projectIdList}>
						<AriProvider ari={projectAri}>
							<JqlProvider jqlContext={jqlContext}>
								<FiltersProvider projects={projects}>{children}</FiltersProvider>
							</JqlProvider>
						</AriProvider>
					</RealtimeProvider>
					<ExperienceSuccess experience={FETCH_PROJECT_EXPERIENCE} />
				</BusinessEntityProvider>
			</ProjectHookProvider>
		);
	}

	return (
		<ContextualAnalyticsData
			containerId={String(project?.id)}
			containerType="project"
			sourceType={SCREEN}
			sourceName="businessProjectContext"
			attributes={analyticsAttributes}
			// we set a key on the first element to force React to re-render when the context changes
			// to avoid query race-conditions that would lead to promises from a previous context
			// resolving in the current context view, and thus seeing unrelated data
			key={String(project?.id)}
		>
			<ExperienceStart experience={FETCH_PROJECT_EXPERIENCE} />
			{content}
		</ContextualAnalyticsData>
	);
};

export default ProjectContextProvider;

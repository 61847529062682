import { type Flag, toFlagId } from '@atlassian/jira-flags/src/services/types';
import type { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const ISSUE_TRANSITION_SUCCESS_FLAG_ID = 'issueTransitionSuccessful';
const ISSUE_TRANSITION_FAILURE_FLAG_ID = 'issueTransitionFailure';

export const getTransitionSuccessFlag = (
	issueKey: string,
	status: string,
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): Flag => {
	const showLinkToIssue = Boolean(issueKey && status);
	if (showLinkToIssue) {
		return {
			id: toFlagId(ISSUE_TRANSITION_SUCCESS_FLAG_ID),
			type: 'success',
			title: formatMessage(messages.issueTransitionSuccessfulWithIssueKeyAndStatus, {
				issueKey,
				status,
			}),
			description: messages.issueTransitionSuccessfulFlagDescription,
		};
	}
	return {
		id: toFlagId(ISSUE_TRANSITION_SUCCESS_FLAG_ID),
		type: 'success',
		title: formatMessage(messages.issueTransitionSuccessful),
	};
};

export const getTransitionFailureFlag = (
	issueKey: string,
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): Flag => ({
	id: toFlagId(ISSUE_TRANSITION_FAILURE_FLAG_ID),
	type: 'error',
	title: issueKey
		? formatMessage(messages.issueTransitionFailureWithKey, { issueKey })
		: formatMessage(messages.issueTransitionFailure),
});

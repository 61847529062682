import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showSubtasksTooltip: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtasks-expand-collapse.show-subtasks-tooltip',
		defaultMessage: 'Show subtasks',
		description: 'Tooltip around a button indicating user can click to see subtasks for the issue',
	},
	hideSubtasksTooltip: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtasks-expand-collapse.hide-subtasks-tooltip',
		defaultMessage: 'Hide subtasks',
		description: 'Tooltip around a button indicating user can click to hide subtasks for the issue',
	},
	showSubtasksAriaLabel: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtasks-expand-collapse.show-subtasks-aria-label',
		defaultMessage:
			'Show subtasks ({done} of {total, plural, one {# subtask} other {# subtasks}} complete)',
		description: 'Aria label for a button indicating user can click to see subtasks for the issue',
	},
	hideSubtasksAriaLabel: {
		id: 'work-management-board.board.column.card.card-content.subtasks.subtasks-expand-collapse.hide-subtasks-aria-label',
		defaultMessage:
			'Hide subtasks ({done} of {total, plural, one {# subtask} other {# subtasks}} complete)',
		description: 'Aria label for a button indicating user can click to hide subtasks for the issue',
	},
});

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const boardConfettiEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-jwm-board-confetti" */
				'./main'
			),
	),
	getPreloadProps: () => ({}),
});

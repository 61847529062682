import React, { type ReactNode, type Ref } from 'react';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import {
	type ModalEntryPoint,
	ModalEntryPointPressableTrigger,
	type ModalEntryPointPressableTriggerProps,
} from '@atlassian/jira-modal-entry-point-pressable-trigger';

type ModalEntryPointIconButtonTriggerProps<TEntryPoint extends ModalEntryPoint> = Omit<
	ModalEntryPointPressableTriggerProps<TEntryPoint>,
	'children' | 'interactionName' | 'title' | 'width'
> & {
	interactionName: string;
	modalTitle?: ReactNode;
} & Omit<IconButtonProps, 'interactionName'>;

export function ModalEntryPointIconButtonTrigger<TEntryPoint extends ModalEntryPoint>({
	entryPoint,
	entryPointParams,
	entryPointProps,
	errorAttributes,
	Fallback,
	modalTitle,
	modalProps,
	useInternalModal,
	...buttonProps
}: ModalEntryPointIconButtonTriggerProps<TEntryPoint>) {
	return (
		<ModalEntryPointPressableTrigger
			entryPoint={entryPoint}
			entryPointParams={entryPointParams}
			entryPointProps={entryPointProps}
			errorAttributes={errorAttributes}
			Fallback={Fallback}
			modalTitle={modalTitle}
			modalProps={modalProps}
			useInternalModal={useInternalModal}
		>
			{({ ref }) => (
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				<IconButton ref={ref as Ref<HTMLButtonElement>} {...buttonProps} />
			)}
		</ModalEntryPointPressableTrigger>
	);
}

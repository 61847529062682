import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterPlaceholder: {
		id: 'business-filters.controllers.field-config.filter-placeholder',
		defaultMessage: 'Search',
		description: 'Filter searchbar placeholder text',
	},
	dateRangeLabel: {
		id: 'business-filters.controllers.field-config.date-range-label',
		defaultMessage: 'Date range',
		description: 'Date range label',
	},
	typeLabel: {
		id: 'business-filters.controllers.field-config.type-label',
		defaultMessage: 'Type',
		description: 'Type label',
	},
	unassignedLabel: {
		id: 'business-filters.controllers.field-config.unassigned-label',
		defaultMessage: 'Unassigned',
		description: 'Label of the filter option for a user field with no assignee',
	},
	noneOption: {
		id: 'business-filters.controllers.field-config.none-option',
		defaultMessage: 'None',
		description: 'Label of the empty filter option for a select field',
	},
});

import React, { memo } from 'react';
import { styled } from '@compiled/react';
import AddIcon from '@atlaskit/icon/glyph/add';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fontSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { InlineCreateStyles } from '../../types';
import messages from './messages';

export type Props = InlineCreateStyles & {
	onClick?: () => void;
};

const InlineCreateTrigger = ({ onClick, minHeight, borderRadius, horizontalPadding }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Trigger
			borderRadius={borderRadius}
			data-testid="business-issue-create.ui.inline-create-trigger"
			horizontalPadding={horizontalPadding}
			minHeight={minHeight}
			onClick={onClick}
		>
			<AddIcon label="" size="small" />
			<Label>{formatMessage(messages.createLabel)}</Label>
		</Trigger>
	);
};

export default memo(InlineCreateTrigger);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Trigger = styled.button<InlineCreateStyles>({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N200),
	background: 'transparent',
	border: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	fontWeight: 500,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ borderRadius }) => borderRadius ?? 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ minHeight }) => minHeight ?? '32px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ horizontalPadding }) => `0 ${horizontalPadding ?? token('space.100', '8px')}`,
	gap: token('space.100', '4px'),
	width: '100%',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered', '#091E420F'),
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.subtle.pressed', '#091E4224'),
	},
	'&:focus, &:active, &:focus-visible': {
		outline: `${token('border.width.outline', '2px')} auto ${token(
			'color.border.focused',
			'#388bff',
		)}`,
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		outlineOffset: '-1px', // this value is used so the focus ring works nicely with overflow hidden
	},
});

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({});

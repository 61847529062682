import {
	type Action,
	createStateHook,
	createActionsHook,
	createStore,
} from '@atlassian/react-sweet-state';

/**
 * The minimum allowed time between two consecutive issue data prefetches.
 */
const ALLOWED_TIME_BETWEEN_PREFETCHES = 500;

const MAX_DELAY = 300;
const MIN_DELAY = 0;

// limit the number of cached issues in memory
export const getCacheLimit = () => 100;

type StoreType = {
	timestampOfTheLastPrefetch: number | null;
	prefetchedIssues: { [issueKey: string]: boolean };
};

const actions = {
	// prefetches issue view data on hover
	prefetchIssue:
		(issueKey: string): Action<StoreType> =>
		({ getState, setState }) => {
			const { timestampOfTheLastPrefetch, prefetchedIssues } = getState();
			const currentTimestamp = Date.now();

			const delay =
				timestampOfTheLastPrefetch !== null &&
				currentTimestamp - timestampOfTheLastPrefetch < ALLOWED_TIME_BETWEEN_PREFETCHES
					? MAX_DELAY
					: MIN_DELAY;

			// clear cache if it exceeds the limit so that it doesn't grow indefinitely
			const nextPrefetchedIssues =
				Object.keys(prefetchedIssues).length === getCacheLimit() ? {} : prefetchedIssues;

			setState({
				// prefetch the issue only if the time from the last prefetch is greater than ALLOWED_TIME_BETWEEN_PREFETCHES
				prefetchedIssues:
					delay === MIN_DELAY && !prefetchedIssues[issueKey]
						? { ...nextPrefetchedIssues, [issueKey]: true }
						: prefetchedIssues,

				timestampOfTheLastPrefetch: currentTimestamp,
			});
		},

	// clears cached issues (ie on view unmount)
	clearCache:
		(): Action<StoreType> =>
		({ setState }) => {
			setState({ timestampOfTheLastPrefetch: null, prefetchedIssues: {} });
		},
} as const;

type Actions = typeof actions;

const PrefetchIssueViewStore = createStore<StoreType, Actions>({
	initialState: { timestampOfTheLastPrefetch: null, prefetchedIssues: {} },
	actions,
	name: 'JWMPrefetchIssueViewStore',
});

export const usePrefetchIssueViewData = createStateHook(PrefetchIssueViewStore);
export const usePrefetchIssueViewDataActions = createActionsHook(PrefetchIssueViewStore);

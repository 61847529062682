import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cardActions: {
		id: 'work-management-board.board.column.card.card-actions.card-actions',
		defaultMessage: 'Card actions',
		description:
			'Label for the button that opens the issue actions menu on a card in the Jira board.',
	},
	cardActionsWithIssueName: {
		id: 'work-management-board.board.column.card.card-actions.card-actions-with-issue-name',
		defaultMessage: 'Card actions on issue {issueKey} of column {columnName}',
		description:
			'Label for the button that opens the issue actions menu on a card of a specific column in the Jira board.',
	},
});

import React, { memo, useCallback, type MouseEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import InlineCreateTrigger from '@atlassian/jira-business-inline-create-trigger/src/ui/index.tsx';
import InlineCreateForm from '@atlassian/jira-business-issue-create/src/ui/inline-create-form/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Group, BoardIssue } from '../../../../../common/types';
import { useInlineCreateProps } from '../../../../../controllers/inline-create';
import messages from './messages';

const INLINE_CREATE_TRIGGER_OFFSET = 12;

type Props = {
	group: Group;
	isTopMost: boolean;
	isSiblingCreateFormOpen: boolean;
	issue: BoardIssue;
	toggleSiblingCreateForm: (issueId: number | null) => void;
};

const InlineSiblingCreate = ({
	group,
	isTopMost,
	isSiblingCreateFormOpen,
	issue,
	toggleSiblingCreateForm,
}: Props) => {
	const { formatMessage } = useIntl();
	const inlineCreateProps = useInlineCreateProps({
		group,
		rankBeforeIssue: issue,
	});

	// prevents onBlur from being called before the click event is finished
	const handleTriggerMouseDown = useCallback((e: MouseEvent) => {
		e.preventDefault();
	}, []);

	const openSiblingCreate = useCallback(
		() => toggleSiblingCreateForm(issue.id),
		[issue.id, toggleSiblingCreateForm],
	);

	const closeSiblingCreate = useCallback(
		() => toggleSiblingCreateForm(null),
		[toggleSiblingCreateForm],
	);

	return isSiblingCreateFormOpen ? (
		<Box xcss={inlineFormWrapperStyles}>
			<InlineCreateForm
				{...inlineCreateProps}
				location="boardSibling"
				onBlur={closeSiblingCreate}
			/>
		</Box>
	) : (
		<InlineCreateTrigger
			direction="row"
			onClick={openSiblingCreate}
			onMouseDown={handleTriggerMouseDown}
			label={formatMessage(messages.createSiblingIssue)}
			offset={INLINE_CREATE_TRIGGER_OFFSET}
			position={isTopMost ? 1 : -2}
		/>
	);
};

export default memo(InlineSiblingCreate);

const inlineFormWrapperStyles = xcss({
	marginBottom: 'space.050',
});

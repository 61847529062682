import memoize from 'lodash/memoize';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { getCascadingSelectIcons } from '../field-types/cascading-select/icon';
import { getDevSummaryIcons } from '../field-types/dev-summary/icon';
import { getGoalsIcons } from '../field-types/goals/icon';
import { getGroupIcons } from '../field-types/group/icon';
import { getParentIcon } from '../field-types/parent/icon';
import { getProjectIcons } from '../field-types/project/icon';
import { getRadioSelectIcon } from '../field-types/radio-select/icon';
import { getResolutionIcons } from '../field-types/resolution/icon';
import { getStatusCategoryIcons } from '../field-types/status-category/icon';
import { getTeamIcons } from '../field-types/team/icon';
import { getVersionIcons } from '../field-types/version/icon';
import { getLegacyFieldIcons } from './legacy-icons';
import type { GetIconFunction, IconSize } from './types';

export const getFieldIcons = memoize<GetIconFunction>((size: IconSize) => {
	const legacyFieldIcons = getLegacyFieldIcons({ size });

	return {
		...legacyFieldIcons,
		...(fg('jsw_list_view_-_all_the_fields')
			? {
					...getVersionIcons(size),
					...getParentIcon(size),
					...getDevSummaryIcons(size),
					...getTeamIcons(size),
					...getGoalsIcons(size),
				}
			: {}),
		...(ff('jira.list.field-support-part1')
			? {
					...getResolutionIcons(size),
					...getGroupIcons(size),
					...getStatusCategoryIcons(size),
					...getRadioSelectIcon(size),
					...getProjectIcons(size),
					...getCascadingSelectIcons(size),
				}
			: {}),
	};
});

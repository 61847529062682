import { colors as themeColors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import * as colors from '../../../utils/colors/constants';
import messages from './messages';
import type { CategoryColorMeta } from './types';

export const GREY_LIGHTEST_META: CategoryColorMeta = {
	name: 'GREY_LIGHTEST',
	backgroundColor: token('color.background.neutral', colors.N100),
	textColor: token('color.text.accent.gray.bolder', themeColors.N900),
	messageLabel: messages.greyLightest,
};

export const GREY_LIGHTER_META: CategoryColorMeta = {
	name: 'GREY_LIGHTER',
	backgroundColor: token('color.background.neutral.hovered', colors.N200),
	textColor: token('color.text.accent.gray.bolder', themeColors.N900),
	messageLabel: messages.greyLighter,
};

export const GREY_META: CategoryColorMeta = {
	name: 'GREY',
	backgroundColor: token('color.background.neutral.pressed', colors.N300),
	textColor: token('color.text.accent.gray.bolder', themeColors.N900),
	messageLabel: messages.grey,
};

export const GREY_DARKER_META: CategoryColorMeta = {
	name: 'GREY_DARKER',
	backgroundColor: token('color.background.neutral.bold', colors.N400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greyDarker,
};

export const GREY_DARKEST_META: CategoryColorMeta = {
	name: 'GREY_DARKEST',
	backgroundColor: token('color.background.neutral.bold.pressed', colors.N500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greyDarkest,
};

export const PURPLE_LIGHTEST_META: CategoryColorMeta = {
	name: 'PURPLE_LIGHTEST',
	backgroundColor: token('color.background.accent.purple.subtlest', colors.P100),
	textColor: token('color.text.accent.purple.bolder', colors.P500),
	messageLabel: messages.purpleLightest,
};

export const PURPLE_LIGHTER_META: CategoryColorMeta = {
	name: 'PURPLE_LIGHTER',
	backgroundColor: token('color.background.accent.purple.subtler', colors.P200),
	textColor: token('color.text.accent.purple.bolder', colors.P500),
	messageLabel: messages.purpleLighter,
};

export const PURPLE_META: CategoryColorMeta = {
	name: 'PURPLE',
	backgroundColor: token('color.background.accent.purple.subtle', colors.P300),
	textColor: token('color.text.accent.purple.bolder', colors.P500),
	messageLabel: messages.purple,
};

export const PURPLE_DARKER_META: CategoryColorMeta = {
	name: 'PURPLE_DARKER',
	backgroundColor: token('color.text.accent.purple', colors.P400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.purpleDarker,
};

export const PURPLE_DARKEST_META: CategoryColorMeta = {
	name: 'PURPLE_DARKEST',
	backgroundColor: token('color.text.accent.purple.bolder', colors.P500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.purpleDarkest,
};

export const BLUE_LIGHTEST_META: CategoryColorMeta = {
	name: 'BLUE_LIGHTEST',
	backgroundColor: token('color.background.accent.blue.subtlest', colors.B100),
	textColor: token('color.text.accent.blue.bolder', colors.B500),
	messageLabel: messages.blueLightest,
};

export const BLUE_LIGHTER_META: CategoryColorMeta = {
	name: 'BLUE_LIGHTER',
	backgroundColor: token('color.background.accent.blue.subtler', colors.B200),
	textColor: token('color.text.accent.blue.bolder', colors.B500),
	messageLabel: messages.blueLighter,
};

export const BLUE_META: CategoryColorMeta = {
	name: 'BLUE',
	backgroundColor: token('color.background.accent.blue.subtle', colors.B300),
	textColor: token('color.text.accent.blue.bolder', colors.B500),
	messageLabel: messages.blue,
};

export const BLUE_DARKER_META: CategoryColorMeta = {
	name: 'BLUE_DARKER',
	backgroundColor: token('color.text.accent.blue', colors.B400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.blueDarker,
};

export const BLUE_DARKEST_META: CategoryColorMeta = {
	name: 'BLUE_DARKEST',
	backgroundColor: token('color.text.accent.blue.bolder', colors.B500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.blueDarkest,
};

export const TEAL_LIGHTEST_META: CategoryColorMeta = {
	name: 'TEAL_LIGHTEST',
	backgroundColor: token('color.background.accent.teal.subtlest', colors.T100),
	textColor: token('color.text.accent.teal.bolder', colors.T500),
	messageLabel: messages.tealLightest,
};

export const TEAL_LIGHTER_META: CategoryColorMeta = {
	name: 'TEAL_LIGHTER',
	backgroundColor: token('color.background.accent.teal.subtler', colors.T200),
	textColor: token('color.text.accent.teal.bolder', colors.T500),
	messageLabel: messages.tealLighter,
};

export const TEAL_META: CategoryColorMeta = {
	name: 'TEAL',
	backgroundColor: token('color.background.accent.teal.subtle', colors.T300),
	textColor: token('color.text.accent.teal.bolder', colors.T500),
	messageLabel: messages.teal,
};

export const TEAL_DARKER_META: CategoryColorMeta = {
	name: 'TEAL_DARKER',
	backgroundColor: token('color.text.accent.teal', colors.T400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.tealDarker,
};

export const TEAL_DARKEST_META: CategoryColorMeta = {
	name: 'TEAL_DARKEST',
	backgroundColor: token('color.text.accent.teal.bolder', colors.T500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.tealDarkest,
};

export const GREEN_LIGHTEST_META: CategoryColorMeta = {
	name: 'GREEN_LIGHTEST',
	backgroundColor: token('color.background.accent.green.subtlest', colors.G100),
	textColor: token('color.text.accent.green.bolder', colors.G500),
	messageLabel: messages.greenLightest,
};

export const GREEN_LIGHTER_META: CategoryColorMeta = {
	name: 'GREEN_LIGHTER',
	backgroundColor: token('color.background.accent.green.subtler', colors.G200),
	textColor: token('color.text.accent.green.bolder', colors.G500),
	messageLabel: messages.greenLighter,
};

export const GREEN_META: CategoryColorMeta = {
	name: 'GREEN',
	backgroundColor: token('color.background.accent.green.subtle', colors.G300),
	textColor: token('color.text.accent.green.bolder', colors.G500),
	messageLabel: messages.green,
};

export const GREEN_DARKER_META: CategoryColorMeta = {
	name: 'GREEN_DARKER',
	backgroundColor: token('color.background.accent.green.bolder', colors.G400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greenDarker,
};

export const GREEN_DARKEST_META: CategoryColorMeta = {
	name: 'GREEN_DARKEST',
	backgroundColor: token('color.text.accent.green.bolder', colors.G500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greenDarkest,
};

export const LIME_LIGHTEST_META: CategoryColorMeta = {
	name: 'LIME_LIGHTEST',
	backgroundColor: token('color.background.accent.lime.subtlest', colors.L100),
	textColor: token('color.text.accent.lime.bolder', colors.L500),
	messageLabel: messages.limeLightest,
};

export const LIME_LIGHTER_META: CategoryColorMeta = {
	name: 'LIME_LIGHTER',
	backgroundColor: token('color.background.accent.lime.subtler', colors.L200),
	textColor: token('color.text.accent.lime.bolder', colors.L500),
	messageLabel: messages.limeLighter,
};

export const LIME_META: CategoryColorMeta = {
	name: 'LIME',
	backgroundColor: token('color.background.accent.lime.subtle', colors.L300),
	textColor: token('color.text.accent.lime.bolder', colors.L500),
	messageLabel: messages.lime,
};

export const LIME_DARKER_META: CategoryColorMeta = {
	name: 'LIME_DARKER',
	backgroundColor: token('color.text.accent.lime', colors.L400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.limeDarker,
};
export const LIME_DARKEST_META: CategoryColorMeta = {
	name: 'LIME_DARKEST',
	backgroundColor: token('color.text.accent.lime.bolder', colors.L500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.limeDarkest,
};

export const YELLOW_LIGHTEST_META: CategoryColorMeta = {
	name: 'YELLOW_LIGHTEST',
	backgroundColor: token('color.background.accent.yellow.subtlest', colors.Y100),
	textColor: token('color.text.accent.yellow.bolder', colors.Y500),
	messageLabel: messages.yellowLightest,
};
export const YELLOW_LIGHTER_META: CategoryColorMeta = {
	name: 'YELLOW_LIGHTER',
	backgroundColor: token('color.background.accent.yellow.subtler', colors.Y200),
	textColor: token('color.text.accent.yellow.bolder', colors.Y500),
	messageLabel: messages.yellowLighter,
};

export const YELLOW_META: CategoryColorMeta = {
	name: 'YELLOW',
	backgroundColor: token('color.background.accent.yellow.subtle', colors.Y300),
	textColor: token('color.text.accent.yellow.bolder', colors.Y500),
	messageLabel: messages.yellow,
};

export const YELLOW_DARKER_META: CategoryColorMeta = {
	name: 'YELLOW_DARKER',
	backgroundColor: token('color.text.accent.yellow', colors.Y400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.yellowDarker,
};

export const YELLOW_DARKEST_META: CategoryColorMeta = {
	name: 'YELLOW_DARKEST',
	backgroundColor: token('color.text.accent.yellow.bolder', colors.Y500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.yellowDarkest,
};

export const ORANGE_LIGHTEST_META: CategoryColorMeta = {
	name: 'ORANGE_LIGHTEST',
	backgroundColor: token('color.background.accent.orange.subtlest', colors.O100),
	textColor: token('color.text.accent.orange.bolder', colors.O500),
	messageLabel: messages.orangeLightest,
};

export const ORANGE_LIGHTER_META: CategoryColorMeta = {
	name: 'ORANGE_LIGHTER',
	backgroundColor: token('color.background.accent.orange.subtler', colors.O200),
	textColor: token('color.text.accent.orange.bolder', colors.O500),
	messageLabel: messages.orangeLighter,
};

export const ORANGE_META: CategoryColorMeta = {
	name: 'ORANGE',
	backgroundColor: token('color.background.accent.orange.subtle', colors.O300),
	textColor: token('color.text.accent.orange.bolder', colors.O500),
	messageLabel: messages.orange,
};

export const ORANGE_DARKER_META: CategoryColorMeta = {
	name: 'ORANGE_DARKER',
	backgroundColor: token('color.background.accent.orange.bolder', colors.O400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.orangeDarker,
};

export const ORANGE_DARKEST_META: CategoryColorMeta = {
	name: 'ORANGE_DARKEST',
	backgroundColor: token('color.text.accent.orange.bolder', colors.O500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.orangeDarkest,
};

export const RED_LIGHTEST_META: CategoryColorMeta = {
	name: 'RED_LIGHTEST',
	backgroundColor: token('color.background.accent.red.subtlest', colors.R100),
	textColor: token('color.text.accent.red.bolder', colors.R500),
	messageLabel: messages.redLightest,
};

export const RED_LIGHTER_META: CategoryColorMeta = {
	name: 'RED_LIGHTER',
	backgroundColor: token('color.background.accent.red.subtler', colors.R200),
	textColor: token('color.text.accent.red.bolder', colors.R500),
	messageLabel: messages.redLighter,
};

export const RED_META: CategoryColorMeta = {
	name: 'RED',
	backgroundColor: token('color.background.accent.red.subtle', colors.R300),
	textColor: token('color.text.accent.red.bolder', colors.R500),
	messageLabel: messages.red,
};

export const RED_DARKER_META: CategoryColorMeta = {
	name: 'RED_DARKER',
	backgroundColor: token('color.background.accent.red.bolder', colors.R400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.redDarker,
};

export const RED_DARKEST_META: CategoryColorMeta = {
	name: 'RED_DARKEST',
	backgroundColor: token('color.text.accent.red.bolder', colors.R500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.redDarkest,
};

export const MAGENTA_LIGHTEST_META: CategoryColorMeta = {
	name: 'MAGENTA_LIGHTEST',
	backgroundColor: token('color.background.accent.magenta.subtlest', colors.M100),
	textColor: token('color.text.accent.magenta.bolder', colors.R500),
	messageLabel: messages.magentaLightest,
};

export const MAGENTA_LIGHTER_META: CategoryColorMeta = {
	name: 'MAGENTA_LIGHTER',
	backgroundColor: token('color.background.accent.magenta.subtler', colors.M200),
	textColor: token('color.text.accent.magenta.bolder', colors.R500),
	messageLabel: messages.magentaLighter,
};

export const MAGENTA_META: CategoryColorMeta = {
	name: 'MAGENTA',
	backgroundColor: token('color.background.accent.magenta.subtle.hovered', colors.M300),
	textColor: token('color.text.accent.magenta.bolder', colors.R500),
	messageLabel: messages.magenta,
};

export const MAGENTA_DARKER_META: CategoryColorMeta = {
	name: 'MAGENTA_DARKER',
	backgroundColor: token('color.text.accent.magenta', colors.M400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.magentaDarker,
};

export const MAGENTA_DARKEST_META: CategoryColorMeta = {
	name: 'MAGENTA_DARKEST',
	backgroundColor: token('color.text.accent.magenta.bolder', colors.M500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.magentaDarkest,
};

export const CHECK_MARK_COLOR_DARK = token('color.text.inverse', themeColors.N0);
export const CHECK_MARK_COLOR_LIGHT = token('color.text', themeColors.N500);

export const CATEGORY_THEMES: CategoryColorMeta[] = [
	GREY_LIGHTEST_META,
	PURPLE_LIGHTEST_META,
	BLUE_LIGHTEST_META,
	TEAL_LIGHTEST_META,
	GREEN_LIGHTEST_META,
	LIME_LIGHTEST_META,
	YELLOW_LIGHTEST_META,
	ORANGE_LIGHTEST_META,
	RED_LIGHTEST_META,
	MAGENTA_LIGHTEST_META,
	GREY_LIGHTER_META,
	PURPLE_LIGHTER_META,
	BLUE_LIGHTER_META,
	TEAL_LIGHTER_META,
	GREEN_LIGHTER_META,
	LIME_LIGHTER_META,
	YELLOW_LIGHTER_META,
	ORANGE_LIGHTER_META,
	RED_LIGHTER_META,
	MAGENTA_LIGHTER_META,
	GREY_META,
	PURPLE_META,
	BLUE_META,
	TEAL_META,
	GREEN_META,
	LIME_META,
	YELLOW_META,
	ORANGE_META,
	RED_META,
	MAGENTA_META,
	GREY_DARKER_META,
	PURPLE_DARKER_META,
	BLUE_DARKER_META,
	TEAL_DARKER_META,
	GREEN_DARKER_META,
	LIME_DARKER_META,
	YELLOW_DARKER_META,
	ORANGE_DARKER_META,
	RED_DARKER_META,
	MAGENTA_DARKER_META,
	GREY_DARKEST_META,
	PURPLE_DARKEST_META,
	BLUE_DARKEST_META,
	TEAL_DARKEST_META,
	GREEN_DARKEST_META,
	LIME_DARKEST_META,
	YELLOW_DARKEST_META,
	ORANGE_DARKEST_META,
	RED_DARKEST_META,
	MAGENTA_DARKEST_META,
];

import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { MenuFallback } from '../../../common/ui/menu-fallback';
import { DropdownWrapper } from './styled';
import type { Props } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMenuContent = lazy<typeof MenuContent>(
	() => import(/* webpackChunkName: "async-business-filters-menu-content" */ './main'),
);

export const MenuContent = (props: Props) => (
	<DropdownWrapper data-testid="business-filters.ui.filters.menu-content">
		<Placeholder name="menu-content" fallback={<MenuFallback />}>
			<LazyMenuContent {...props} />
		</Placeholder>
	</DropdownWrapper>
);

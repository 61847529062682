import { useEffect } from 'react';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Group } from '../../../common/types';
import { useGroupMoveHandler } from '../../column-move-handler';
import { useGroups } from '../../groups-context';
import { useIssueTransitions } from '../../issue-transitions';
import { useMoveIssue } from '../../move-issue';
import { useCardDragDrop, useCancelConfirmCardDrop } from '../card-drag-drop-context';
import { isCard, isClosestEdgeAdjacentToSource, isColumn, isTransitionZone } from '../utils';

export const useDropHandler = (initialPageLoading: boolean) => {
	const onGroupMove = useGroupMoveHandler();
	const moveIssue = useMoveIssue();
	const groups = useGroups();
	const { cancelCardDrop, confirmCardDrop } = useCancelConfirmCardDrop();
	const { transitions } = useIssueTransitions();
	const { draggedIssue, dropTarget } = useCardDragDrop();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(
		() =>
			monitorForElements({
				canMonitor: () => !initialPageLoading,
				onDrop: ({ location, source }) => {
					const sourceData = source.data;

					if (location.current.dropTargets[0]?.data == null) {
						if (isCard(sourceData)) {
							cancelCardDrop();
							fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
						}
						return;
					}

					const destinationData = location.current.dropTargets[0].data;

					// Dropping a card.
					if (isCard(sourceData) && draggedIssue != null) {
						// Dropping a card next to another card.
						if (isCard(destinationData)) {
							const closestEdgeOfTarget = extractClosestEdge(destinationData);
							const sourceIndex = sourceData.cardIndex;
							const destinationIndex = destinationData.cardIndex;
							const sourceColumnId = sourceData.columnId;
							const destinationColumnId = destinationData.columnId;

							// Return early if unable to determine the closest edge of the target.
							if (closestEdgeOfTarget == null) {
								cancelCardDrop();
								fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
								return;
							}

							// Return early if the card is dropped in the same place.
							if (sourceData.issueId === destinationData.issueId) {
								cancelCardDrop();
								fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
								return;
							}

							// Return early if the drop location would not result in a change in rank.
							// e.g. dropping a card near the bottom of the previous card or near the top of the next card.
							if (
								sourceColumnId === destinationColumnId &&
								isClosestEdgeAdjacentToSource(sourceIndex, destinationIndex, closestEdgeOfTarget)
							) {
								cancelCardDrop();
								fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
								return;
							}

							moveIssue({
								issue: draggedIssue,
								sourceColumnId,
								destinationColumnId,
								rank: {
									position: closestEdgeOfTarget === 'top' ? 'before' : 'after',
									relativeIssueId: destinationData.issueId,
								},
								transition: dropTarget.transition,
							});
							confirmCardDrop();
						}
						// Dropping a card into a column or a transition zone.
						else if (isColumn(destinationData) || isTransitionZone(destinationData)) {
							const transition = isColumn(destinationData)
								? dropTarget.transition
								: transitions.find((t) => t.transitionId === destinationData.transitionId);

							moveIssue({
								issue: draggedIssue,
								sourceColumnId: sourceData.columnId,
								destinationColumnId: String(destinationData.columnId),
								transition,
							});
							confirmCardDrop();
						} else {
							cancelCardDrop();
							fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
						}
					}

					// Dropping a column.
					if (isColumn(sourceData) && isColumn(destinationData)) {
						const sourceIndex = sourceData.index;
						const destinationIndex = destinationData.index;

						if (sourceIndex === destinationIndex) {
							return;
						}

						const closestEdgeOfTarget = extractClosestEdge(destinationData);

						// Return early if the drop location would not change the order of the columns.
						// e.g. dropping a column near the right edge of the previous column or near the left edge of the next column.
						if (isClosestEdgeAdjacentToSource(sourceIndex, destinationIndex, closestEdgeOfTarget)) {
							return;
						}

						const updatedGroups: Group[] = reorderWithEdge({
							list: groups,
							startIndex: sourceIndex,
							indexOfTarget: destinationIndex,
							closestEdgeOfTarget,
							axis: 'horizontal',
						});
						const updatedGroupIds = updatedGroups.map((group) => group.id);

						onGroupMove(updatedGroupIds);
					}
				},
			}),
		[
			cancelCardDrop,
			confirmCardDrop,
			createAnalyticsEvent,
			draggedIssue,
			dropTarget.transition,
			groups,
			initialPageLoading,
			moveIssue,
			onGroupMove,
			transitions,
		],
	);
};

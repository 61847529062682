import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';

export const getPresenceNodeId = ({
	projectId,
	viewId,
	nodeId,
}: {
	projectId: string;
	viewId: JWMView;
	nodeId: string;
}) => `project:${projectId}:view/${viewId}/node/${nodeId}`;

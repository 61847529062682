import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnNameButtonLabel: {
		id: 'work-management-board.board.column.column-header.header-content.editable-column-header.column-name-button-label',
		defaultMessage: 'Edit {statusName} status column',
		description:
			'Label (used by screen readers) of the column name button shown in the column header that can be clicked to edit the column name',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'work-management-board.controllers.issue-subtasks.error-title',
		defaultMessage: 'Failed to load subtasks',
		description:
			'Error message title informing the user that we were unable to load subtasks for the issue',
	},
	errorMessage: {
		id: 'work-management-board.controllers.issue-subtasks.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description:
			'Error message description informing the user that we were unable to load subtasks for the issue',
	},
});

/* eslint-disable max-classes-per-file */
import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

const getDeletionUrl = (issueKey: string) => `/rest/api/3/issue/${issueKey}?deleteSubtasks=true`;

export class IssueNotFound extends Error {}

export class PermissionDenied extends Error {}

export type DeleteIssueRequest = {
	issueKey: string;
};

export const useDeleteIssue = () =>
	useCallback(
		({ issueKey }: { issueKey: string }) =>
			performDeleteRequest(getDeletionUrl(issueKey), { method: 'DELETE' }).catch((error) => {
				if (error.statusCode === 404) {
					throw new IssueNotFound();
				}

				if (error.statusCode === 403) {
					throw new PermissionDenied();
				}

				fireErrorAnalytics({
					meta: {
						id: 'jwmBoardIssueDelete',
						packageName: 'jiraWorkManagementBoard',
						teamName: 'wanjel',
					},
					attributes: {
						message: 'Failed to delete issue in JWM board',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});

				throw error;
			}),
		[],
	);

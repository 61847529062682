import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpinnerWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: token('space.200', '16px'),
});

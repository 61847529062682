import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';

const DISABLED = 'disabled';
const DUPLICATED = 'duplicated';
const REPLACED = 'replaced';

const DEFAULT_FF = 'replace-data-test-id-with-data-testid-in-bento-m2';

const getDataTestIdFlagValue = (featureFlag: string = DEFAULT_FF) =>
	// eslint-disable-next-line jira/ff/static-feature-flags
	getMultivariateFeatureFlag(featureFlag, DISABLED, [DISABLED, DUPLICATED, REPLACED]);

export const generateDataTestId = (testId: string, featureFlag: string = DEFAULT_FF) => {
	const flagValue = getDataTestIdFlagValue(featureFlag);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let testIdProps: Record<string, any> = {};

	switch (flagValue) {
		case 'duplicated':
			testIdProps = { 'data-test-id': testId, 'data-testid': testId };
			break;
		case 'replaced':
			testIdProps = { 'data-testid': testId };
			break;
		case 'disabled':
		default:
			testIdProps = { 'data-test-id': testId };
			break;
	}

	return testIdProps;
};

import React, {
	useState,
	type RefCallback,
	type MouseEvent,
	type KeyboardEvent,
	type SyntheticEvent,
	useCallback,
	useMemo,
} from 'react';
import Button from '@atlaskit/button/new';
import IssuesIcon from '@atlaskit/icon/core/migration/issues';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { xcss, Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { metrics } from '@atlassian/browser-metrics';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { issueLinksDetailsContentEntryPoint } from '@atlassian/jira-issue-links-details-content/entrypoint';
import { linkIssuePopupEntryPoint } from '@atlassian/jira-link-issue-popup/entrypoint';
import { IssueLinksPopupFallback } from '@atlassian/jira-link-issue-popup/src/ui/issue-links-popup-fallback/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { IssueLinksDetailsErrorFallback } from './issue-links-details-error-fallback';
import { IssueLinksDetailsPopupSkeleton } from './issue-links-details-popup-skeleton';
import { messages } from './messages';
import type { IssueLinksStatsProps } from './types';

// Remove once stopPropagationOnClick prop is removed
export const isFocusOutsideJFE = (target: EventTarget | null) => {
	if (target instanceof HTMLElement) {
		if (!document.querySelector('#jira-frontend')?.contains(target)) {
			return true;
		}
	}
	return false;
};

const MAX_ISSUE_LINKS_COUNT = 50;

const asyncIssueLinksDetailsLoad = metrics.interaction({
	key: 'issue-links-details-content-load',
});
const asyncIssueLinkPopupLoad = metrics.interaction({
	key: 'issue-link-popup-load',
});

export const IssueLinksStats = ({
	issueLinksCount,
	maxIssueLinksStatsCount,
	testId,
	issueKey,
	issueId,
	issueSummary,
	issueType,
	feedbackCollectorPrefix,
	isLinkingEnabled,
	onIssueUnlink: onIssueUnlinkProp,
	onIssueLink: onIssueLinkProp,
	sourceProductType,
	stopPropagationOnClick = false,
}: IssueLinksStatsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();

	const [isOpen, setIsOpen] = useState(false);
	const [isLinkPopupOpen, setIsLinkPopupOpen] = useState(false);

	const { entryPointReferenceSubject, entryPointActions, stopMetric } = useEntryPoint(
		issueLinksDetailsContentEntryPoint,
		{
			cloudId,
			issueKey,
			// Fetch 1 more than required so that we show view all linked issues button
			issueCount: MAX_ISSUE_LINKS_COUNT + 1,
			withDueDate: true,
			fields: ['issuetype', 'summary', 'status', 'duedate'],
		},
		asyncIssueLinksDetailsLoad,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const {
		entryPointReferenceSubject: linkIssuePopupEntryPointReferenceSubject,
		entryPointActions: linkIssuePopupEntryPointActions,
		stopMetric: linkIssuePopupStopMetric,
	} = useEntryPoint(
		linkIssuePopupEntryPoint,
		{
			cloudId,
		},
		asyncIssueLinkPopupLoad,
	);

	const onTriggerButtonClick = (
		event: React.MouseEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (isOpen) {
			onPopupClose();
		} else {
			setIsOpen(true);
		}
		fireUIAnalytics(analyticsEvent, 'issueLinksStats', {
			isToOpenPopup: !isOpen,
		});
	};

	const onPopupClose = useCallback(
		(event?: Event | MouseEvent | KeyboardEvent) => {
			if (stopPropagationOnClick && event?.target && isFocusOutsideJFE(event.target)) {
				return;
			}
			if (isLinkPopupOpen) {
				if (event instanceof KeyboardEvent && (event.key === 'Escape' || event.key === 'Esc')) {
					fireUIAnalytics(createAnalyticsEvent({}), 'linkIssuePopup dismissed', {
						reason: 'escapeKey',
					});
				}
				if (event instanceof MouseEvent) {
					fireUIAnalytics(createAnalyticsEvent({}), 'linkIssuePopup dismissed', {
						reason: 'clickOutsidePopup',
					});
				}
			}
			setIsOpen(false);
			setIsLinkPopupOpen(false);
		},
		[createAnalyticsEvent, isLinkPopupOpen, stopPropagationOnClick],
	);

	const onIssueUnlink = useCallback(
		(issueIds: string[]) => {
			onIssueUnlinkProp(issueIds);
			setIsOpen(false);
		},
		[onIssueUnlinkProp],
	);

	const onIssueLink = useCallback(
		(id: string, updateIssueIds: string[]) => {
			onIssueLinkProp(id, updateIssueIds);
			onPopupClose();
		},
		[onIssueLinkProp, onPopupClose],
	);

	const onIssueLinkButtonClick = useCallback(() => {
		setIsLinkPopupOpen(true);
		linkIssuePopupEntryPointActions.load();
	}, [linkIssuePopupEntryPointActions]);

	const onIssueLinkPopupClose = () => {
		setIsLinkPopupOpen(false);
	};

	const tooltipContent =
		issueLinksCount > maxIssueLinksStatsCount
			? formatMessage(messages.genericMaxIssueLinksStatsTooltipLabel, {
					maxIssueLinksStatsCount,
				})
			: formatMessage(messages.issueLinksTooltipLabel, {
					issueLinksCount,
				});

	const renderTriggerButton = (triggerProps: TriggerProps) => (
		<Tooltip
			content={tooltipContent}
			testId="issue-links-stats-business.ui.linked-issue-stats-tooltip"
		>
			<Button
				{...triggerProps}
				appearance="subtle"
				spacing="compact"
				testId={`issue-links-stats-business.ui.issue-links-stats-button.custom-theme-button-${issueKey}`}
				ref={(element) => {
					triggerRef(element);
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(triggerProps.ref as RefCallback<HTMLElement>)(element);
				}}
				isSelected={isOpen}
				onClick={onTriggerButtonClick}
				aria-label={tooltipContent}
				iconBefore={() => (
					<IssuesIcon
						LEGACY_size="small"
						color={
							isOpen
								? token('color.icon.accent.blue', colors.B500)
								: token('color.icon', colors.N500)
						}
						label=""
					/>
				)}
			>
				{issueLinksCount > maxIssueLinksStatsCount
					? `${maxIssueLinksStatsCount}+`
					: issueLinksCount}
			</Button>
		</Tooltip>
	);

	const popupRuntimeProps = useMemo(
		() => ({
			issueId,
			issueKey,
			onSuccessfulIssueLink: onIssueLink,
			onClose: onIssueLinkPopupClose,
			trackOriginSource: 'JWM',
			onReady: linkIssuePopupStopMetric,
		}),
		[issueId, issueKey, linkIssuePopupStopMetric, onIssueLink],
	);

	const contentRuntimeProps = useMemo(
		() => ({
			cloudId,
			issueKey,
			issueSummary,
			issueId,
			issueType,
			canUserLinkIssue: isLinkingEnabled,
			onIssueUnlink,
			onPopupClose,
			maxIssuesToDisplay: MAX_ISSUE_LINKS_COUNT,
			feedbackCollectorPrefix,
			onReady: stopMetric,
			sourceProductType,
			onIssueLinkButtonClick,
			openIssueInNewTabOnExceedLimitClick: true,
		}),
		[
			cloudId,
			feedbackCollectorPrefix,
			isLinkingEnabled,
			issueId,
			issueKey,
			issueSummary,
			issueType,
			onIssueLinkButtonClick,
			onIssueUnlink,
			onPopupClose,
			sourceProductType,
			stopMetric,
		],
	);

	const renderContent = () => {
		if (isLinkPopupOpen) {
			return (
				<JiraEntryPointContainer
					id="businessLinkIssuePopupEntryPoint"
					packageName="@atlassian/jira-link-issue-popup"
					teamName="jira-werewolves"
					entryPointReferenceSubject={linkIssuePopupEntryPointReferenceSubject}
					runtimeProps={popupRuntimeProps}
					errorFallback="flag"
					fallback={<IssueLinksPopupFallback />}
				/>
			);
		}
		return (
			<Box padding="space.200" xcss={ContainerStyles}>
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="async-issue-links-details-content"
					runtimeProps={contentRuntimeProps}
					packageName="@atlassian/jira-issue-links-details-content"
					teamName="jira-werewolves"
					errorFallback={({ error }) => (
						<IssueLinksDetailsErrorFallback
							callback={onPopupClose}
							error={error}
							testId="issue-links-stats-business.ui.issue-links-details-popup-entrypoint-container"
						/>
					)}
					fallback={
						<IssueLinksDetailsPopupSkeleton
							noPadding
							smallerWidth
							testId="issue-links-stats-business.ui.skeleton-loader"
						/>
					}
				/>
			</Box>
		);
	};

	return (
		<ContextualAnalyticsData
			sourceName="issueLinksStats"
			attributes={{
				application: sourceProductType && getApplicationForProject(sourceProductType),
			}}
		>
			<Box
				testId={testId}
				onClick={(event: SyntheticEvent) => stopPropagationOnClick && event.stopPropagation()}
			>
				<Popup
					isOpen={isOpen}
					onClose={onPopupClose}
					placement="bottom-start"
					testId="issue-links-stats-business.ui.popup"
					trigger={renderTriggerButton}
					content={renderContent}
					shouldUseCaptureOnOutsideClick={stopPropagationOnClick}
					label={formatMessage(messages.issueLinksPopup)}
				/>
			</Box>
		</ContextualAnalyticsData>
	);
};

const ContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 64}px`,
});

import memoizeOne from 'memoize-one';
import { cascadingSelectSortingHandler } from '../field-types/cascading-select/sorting';
import { devSummaryDisabledSorting } from '../field-types/dev-summary/sorting';
import { goalsDisabledSorting } from '../field-types/goals/sorting';
import { groupSortingHandler } from '../field-types/group/sorting';
import { parentSortingHandler } from '../field-types/parent/sorting';
import { projectSortingHandler } from '../field-types/project/sorting';
import { radioSelectSortingHandler } from '../field-types/radio-select/sorting';
import { resolutionSortingHandler } from '../field-types/resolution/sorting';
import { statusCategoryDisabledSorting } from '../field-types/status-category/sorting';
import { teamDisabledSorting } from '../field-types/team/sorting';
import { versionSortingHandler } from '../field-types/version/sorting';
import type { ListIssueFieldTransformed } from '../types';
import type { Sorting } from './types';

const createSortingHandlers = memoizeOne(() => ({
	...parentSortingHandler,
	...versionSortingHandler,
	...resolutionSortingHandler,
	...groupSortingHandler,
	...radioSelectSortingHandler,
	...cascadingSelectSortingHandler,
	...projectSortingHandler,
}));

type SUPPORTED_SORTING_TYPES = keyof ReturnType<typeof createSortingHandlers>;

export const getSorting = (
	fieldType: SUPPORTED_SORTING_TYPES,
	...args: Parameters<Sorting<ListIssueFieldTransformed>>
): ReturnType<Sorting<ListIssueFieldTransformed>> | undefined => {
	const handlers = createSortingHandlers();
	if (handlers[fieldType] == null) {
		return;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (handlers[fieldType] as Sorting<ListIssueFieldTransformed>)?.(...args);
};

export const isSortingSupported = (fieldType: SUPPORTED_SORTING_TYPES) => {
	const handlers = createSortingHandlers();
	return handlers[fieldType] != null;
};

export const isSortingSupportedFieldTypePredicated = (
	fieldType: string,
): fieldType is SUPPORTED_SORTING_TYPES => true;

export const getSortingDisabledFields = memoizeOne(() => ({
	...devSummaryDisabledSorting,
	...teamDisabledSorting,
	...statusCategoryDisabledSorting,
	...goalsDisabledSorting,
}));

import React, { type ReactNode, createContext, useContext } from 'react';

type JqlContextType = string;

export type JqlContextProviderProps = {
	children: ReactNode;
	jqlContext: string;
};

const JqlContext = createContext<JqlContextType>('');

export const JqlProvider = ({ jqlContext, children }: JqlContextProviderProps) => (
	<JqlContext.Provider value={jqlContext}>{children}</JqlContext.Provider>
);

export const useJqlContext = (): JqlContextType => useContext(JqlContext);

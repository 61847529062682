import { useMemo } from 'react';
import type { JQLParseError } from '@atlaskit/jql-ast';
import {
	TASK,
	type HierarchyParent,
} from '@atlassian/jira-business-common/src/common/types/hierarchy.tsx';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import type { SortingAttributes } from '@atlassian/jira-business-common/src/common/types/sorting.tsx';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { FIX_VERSIONS_TYPE } from '@atlassian/jira-platform-field-config';
import { useSorting } from '../../services/sorting';
import { useHideDoneItems } from '../hide-done-items';
import { useFilters } from '../index';
import { useSimpleSearchJQL } from '../simple-search';
import { getJqlErrors, sanitiseJql } from './utils';

export const useFilteredViewJQL = () => {
	const { addSimpleSearchToJQL } = useSimpleSearchJQL();
	const { jql } = useFilters();
	const [isHidingDoneItems] = useHideDoneItems();

	// ensure views recieve filters jql that will not cause a page crash
	const jqlSanitised = useMemo(() => sanitiseJql(jql), [jql]);

	const filtersJQL = useMemo(() => {
		if (isHidingDoneItems) {
			const filtersJql = [jqlSanitised, "StatusCategory != 'Complete'"]
				.filter(Boolean)
				.join(' AND ');
			return addSimpleSearchToJQL(filtersJql);
		}
		return addSimpleSearchToJQL(jqlSanitised || null);
	}, [jqlSanitised, addSimpleSearchToJQL, isHidingDoneItems]);

	return filtersJQL;
};

export type DefaultInput = {
	jqlContext: string;
	quickFiltersJql: string | null;
	sorting: SortingAttributes;
	isFiltering: boolean;
	hierarchyParent?: HierarchyParent;
	isHidingDoneItems: boolean;
	jqlErrors: JQLParseError[];
};

const FIX_VERSION_SORTING_JQL = 'fixVersion';

export const useFilteredViewContextInput = ({ view }: { view: JWMView }): DefaultInput => {
	const filtersJQL = useFilteredViewJQL();
	const { isSearching } = useSimpleSearchJQL();
	const [sortingBy] = useSorting();
	const { jql } = useFilters();
	const isFiltering = jql != null || isSearching;
	const [isHidingDoneItems] = useHideDoneItems();
	const jqlContext = useJqlContext();

	const hierarchyParent =
		(view === 'list' || view === 'timeline') && !isFiltering ? TASK : undefined;

	const jqlErrors = useMemo(() => {
		if (ff('jwm.filters.handle-invalid-jql')) {
			return getJqlErrors(jql);
		}
		return [];
	}, [jql]);

	const sorting = useMemo(() => {
		/*
			When sorting by a specific field, we use the fieldId in the JQL query.
			For the fix version field, the fieldId doesn’t work in JQL must be “fixVersion”.
			Apparently, "fixVersion" is the clause name. It's a legacy choice that was made from the very beginning, and now we're unable to change it.
		*/
		if (sortingBy.sortBy === FIX_VERSIONS_TYPE && fg('jsw_list_view_-_all_the_fields')) {
			return { ...sortingBy, sortBy: FIX_VERSION_SORTING_JQL };
		}
		return sortingBy;
	}, [sortingBy]);

	return {
		jqlContext,
		quickFiltersJql: filtersJQL,
		sorting,
		isFiltering,
		hierarchyParent,
		isHidingDoneItems,
		jqlErrors,
	};
};

import { useCallback } from 'react';
import { useIsHighlighted } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/index.tsx';
import { PARENT_ID } from '../../common/constants';
import { useIssueStoreActions } from '../board-issue-store';
import { useIssues } from '../board-issue-store/selectors';
import { useRefetchIssues } from '../refetch-issues';

export const useHandleIssueEvents = () => {
	const issues = useIssues();
	const getIsHighlighted = useIsHighlighted();
	const refetchIssues = useRefetchIssues();
	const { removeIssues } = useIssueStoreActions();

	const handleUpdatedIssue = useCallback(
		async (issueId: number) => {
			// Return early when we receive realtime events for an issue that was
			// recently created via the issue create component in the current window.
			// This avoid race conditions.
			if (getIsHighlighted(issueId)) {
				return;
			}

			const [updatedIssue] = await refetchIssues([issueId]);

			if (updatedIssue) {
				const parentIssueId = updatedIssue.fields[PARENT_ID]?.value;
				if (parentIssueId) {
					refetchIssues([Number(parentIssueId)]);
				}
			}
		},
		[getIsHighlighted, refetchIssues],
	);

	const handleDeletedIssue = useCallback(
		(issueId: number) => {
			const deletedIssue = issues.get(issueId);

			if (deletedIssue == null) {
				return;
			}

			const parentIssue = issues.get(Number(deletedIssue.fields[PARENT_ID]?.value));

			if (parentIssue) {
				refetchIssues([parentIssue.id]);
			}

			removeIssues([issueId]);
		},
		[issues, refetchIssues, removeIssues],
	);

	return { handleUpdatedIssue, handleDeletedIssue };
};

import { useCallback } from 'react';
import difference from 'lodash/difference';
import { useFetchIssueDetails } from '../board-issue-details';
import { useIssueStoreActions } from '../board-issue-store';

export const useRefetchIssues = () => {
	const refetchIssuesWithThrow = useRefetchIssuesWithThrow();

	return useCallback(
		async (issueIds: number[]) => {
			try {
				return await refetchIssuesWithThrow(issueIds);
			} catch (error) {
				// do nothing
				return [];
			}
		},
		[refetchIssuesWithThrow],
	);
};

/** Does not catch the errors and throws them to the consumer */
export const useRefetchIssuesWithThrow = () => {
	const fetchIssueDetails = useFetchIssueDetails();
	const { setIssues, removeIssues } = useIssueStoreActions();

	return useCallback(
		async (issueIds: number[]) => {
			const issues = await fetchIssueDetails(issueIds);

			setIssues(issues);

			const fetchedIssueIds = issues.map((issue) => issue.id);
			const removedIssueIds = difference(issueIds, fetchedIssueIds);

			if (removedIssueIds.length > 0) {
				removeIssues(removedIssueIds);
			}

			return issues;
		},
		[fetchIssueDetails, setIssues, removeIssues],
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

export const ABORTED = Symbol('ABORTED');

type Fetch<T> = (signal: AbortSignal, ...args: any) => Promise<T>;

export const useAbortable = <T,>(fetch: Fetch<T>) => {
	const prevControllerRef = useRef<AbortController | null>(null);

	return useCallback(
		(...args: any[]) => {
			if (prevControllerRef.current) {
				prevControllerRef.current.abort();
			}

			const controller = new AbortController();
			prevControllerRef.current = controller;

			return fetch(controller.signal, ...args).catch((error: Error) => {
				if (controller.signal.aborted) {
					throw ABORTED;
				} else {
					throw error;
				}
			});
		},
		[fetch],
	);
};

import {
	MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
	AFFECTS_VERSIONS_TYPE,
	FIX_VERSIONS_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { Sorting } from '../../../sorting/types';
import type { TransformedSingleVersion, TransformedMultipleVersion } from '../types';

const getSingleVersionSorting: Sorting<TransformedSingleVersion> = (field) =>
	field.value?.name ?? '';
const getMultipleVersionSorting: Sorting<TransformedMultipleVersion> = (field) =>
	field.value == null ? '' : field.value.map((v) => v.name).join(',');

export const versionSortingHandler = {
	[VERSION_CF_TYPE]: getSingleVersionSorting,
	[MULTI_VERSION_CF_TYPE]: getMultipleVersionSorting,
	[FIX_VERSIONS_TYPE]: getMultipleVersionSorting,
	[AFFECTS_VERSIONS_TYPE]: getMultipleVersionSorting,
};

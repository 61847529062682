export const INVALID_DATE_ERROR_MESSAGE = 'Invalid date or date format';

const toISOString = (year: number, month: number, day: number): string =>
	`${String(year).padStart(4, '0')}-${String(month).padStart(2, '0')}-${String(day).padStart(
		2,
		'0',
	)}`;

const isLeapYear = (year: number): boolean =>
	(year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
/**
 Returns a boolean to indicate that the date specified is valid.

 Note: Dates before Year zero are treated to be invalid.
 */
const isDateValid = (year: number, month: number, day: number): boolean => {
	if ([year, month, day].findIndex((n) => typeof n !== 'number' || Number.isNaN(n)) !== -1)
		return false;
	if (year < 0) return false;
	if (month < 1 || month > 12) return false;
	if (day < 1) return false;
	if (day > (month === 2 && isLeapYear(year) ? 29 : daysInMonth[month - 1])) {
		return false;
	}
	return true;
};

type CompareResult = -1 | 0 | 1;

const dateFormatRegExp = /^([0-9][0-9][0-9][0-9])-([0-1][0-9])-([0-3][0-9])$/;

export class DateOnly {
	year: number;

	month: number;

	day: number;

	/**
	 * Return true if the string is a valid ISO date string in the format 'YYYY-MM-DD'.
	 * @param dateString
	 * @returns {boolean}
	 */
	static isISODateString(dateString: string): boolean {
		try {
			DateOnly.fromISODateString(dateString);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			if (e?.message === INVALID_DATE_ERROR_MESSAGE) {
				return false;
			}
			throw e;
		}
		return true;
	}

	/**
	 * Create a DateOnly object from a string in the format 'YYYY-MM-DD'.
	 */
	// go/jfe-eslint
	static fromISODateString(dateString: string): DateOnly {
		const matches = dateFormatRegExp.exec(dateString);
		if (matches === null) {
			throw new Error(INVALID_DATE_ERROR_MESSAGE);
		}
		const [, year, month, day] = matches;
		// @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
		return new DateOnly(...[year, month, day].map((s) => Number.parseInt(s, 10)));
	}

	/**
	 * DISCLAIMER:
	 * Date() does NOT store timezone information.
	 *
	 * Date.prototype.toString() leverages the system's timezone to serialize it into a string according to local time.
	 * Methods getDate(), getMonth(), getFullYear() behave the same and also return values according to local time.
	 *
	 * Methods toUTCString(), getUTCDate(), getUTCMonth(), getUTCFullYear() return values according to universal time.
	 *
	 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
	 */

	/**
	 * Create a DateOnly object that reflects the local year, month & day for the specified Date.
	 *
	 * See the disclaimer above for further details.
	 */
	// go/jfe-eslint
	static fromDateAccordingToLocalTime(date: Date): DateOnly {
		return new DateOnly(date.getFullYear(), date.getMonth() + 1, date.getDate());
	}

	/**
	 * Create a DateOnly object that reflects the UTC year, month & day of the specified Date.
	 *
	 * See the disclaimer above for further details.
	 */
	// go/jfe-eslint
	static fromDateAccordingToUTC(date: Date): DateOnly {
		return new DateOnly(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
	}

	/**
	 * Compare 2 DateOnly objects.
	 *
	 * (Similar to the TC39 proposal Temporal.PlainDate.compare).
	 *
	 * If a comes before b, return -1.
	 * If a is equal to b, return 0.
	 * If a is greater than b, return 1.
	 */
	static compare(a: DateOnly, b: DateOnly): CompareResult {
		if (a.year === b.year) {
			if (a.month === b.month) {
				if (a.day === b.day) {
					return 0;
				}
				return a.day < b.day ? -1 : 1;
			}
			return a.month < b.month ? -1 : 1;
		}
		return a.year < b.year ? -1 : 1;
	}

	/**
	 * Create a DateOnly object which represents a day. It does not have a time component.
	 * @param year - A year, ranging between 0 and 9999 inclusive.
	 * @param month - A month, ranging between 1 and 12 inclusive.
	 * @param day - A day of the month, ranging between 1 and 31 inclusive.
	 */
	constructor(year: number, month: number, day: number) {
		if (!isDateValid(year, month, day)) {
			throw new Error(INVALID_DATE_ERROR_MESSAGE);
		}
		this.year = year;
		this.month = month;
		this.day = day;
	}

	getYear(): number {
		return this.year;
	}

	getMonth(): number {
		return this.month;
	}

	getDay(): number {
		return this.day;
	}

	toISOString(): string {
		return toISOString(this.year, this.month, this.day);
	}

	/**
	 * Returns a Date object with the same date, with the time component set to 00:00:00 which corresponds to the UTC timezone.
	 */
	toUTCDate(): Date {
		return new Date(Date.UTC(this.year, this.month - 1, this.day));
	}

	/**
	 * Returns a Date object with the same date, with the time component set to 00:00:00 for the local timezone.
	 */
	toLocalDate(): Date {
		// take the current date (e.g. 1.06.2020)
		const localDate = new Date();
		// store current month in a const to compare below
		const currentMonth = localDate.getMonth();
		// set the year
		localDate.setFullYear(this.year);
		localDate.setHours(0);
		localDate.setMinutes(0);
		localDate.setSeconds(0);
		localDate.setMilliseconds(0);

		localDate.setDate(this.day);
		// if this.day doesn't exist in the current month (e.g. 31 doesn't exist in June ),
		// it will set the month to July instead. We need to make sure setDate doesn't change the month
		if (localDate.getMonth() !== currentMonth) {
			localDate.setMonth(this.month - 1);
			// reset the date after updating the month
			localDate.setDate(this.day);
		} else {
			localDate.setMonth(this.month - 1);
		}
		return localDate;
	}

	equals(dateOnly: DateOnly): boolean {
		return DateOnly.compare(this, dateOnly) === 0;
	}
}

export const toDateOnly = (date?: string): DateOnly | null =>
	typeof date === 'string' ? DateOnly.fromISODateString(date.replace(/T.*/, '')) : null;

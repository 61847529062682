import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

type BoardColumnOrderRequest = {
	workflowName: string;
	columns: { statusId: string }[];
};

export const setBoardColumnOrder = async ({
	projectId,
	columnRequest,
}: {
	projectId: string;
	columnRequest: BoardColumnOrderRequest;
}): Promise<null> => {
	const url = `/rest/board-api/1/project/${projectId}/columnOrder`;
	try {
		await performPutRequest(url, {
			body: JSON.stringify(columnRequest),
		});
		return Promise.resolve(null);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'jwmBoardSetColumnOrder',
				packageName: 'jiraWorkManagementBoard',
				teamName: 'wanjel',
			},
			attributes: {
				message: 'Failed set board column order',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};

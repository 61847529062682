import React, { memo, type ComponentType } from 'react';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { UI_RENDER_START, UI_RENDER_END } from '../../constants';
import type { JWM_VIEW, PRODUCT } from '../../types';
import { markViewMetric, pageLoadMetrics } from './utils';

type Props = {
	view: JWM_VIEW;
	attributes?: Attributes;
	product?: PRODUCT;
};

export const RenderStartMark = ({ view, loading }: { view: JWM_VIEW; loading?: boolean }) => {
	if (loading === true) {
		return null;
	}

	markViewMetric(view, UI_RENDER_START);

	return null;
};

export const PageLoadMetrics: ComponentType<Props> = memo(
	({ view, attributes, product = 'jwm' }: Props) => {
		// set UI_RENDER_END mark to indicate the end of UI render
		markViewMetric(view, UI_RENDER_END);

		const metric = pageLoadMetrics[view];
		return (
			metric && <SubmitApdex appName={`${product}.${view}`} metric={metric} extra={attributes} />
		);
	},
);

import { gqlTagGira } from '@atlassian/jira-apollo-gira';
import {
	JIS2_JWMBOARD_CONFLUENCE_LINKS_FRAGMENT,
	JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT,
	JIS2_JWMBOARD_ITEM_FRAGMENT,
	JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT,
} from '../board-issues/gql';

export const TRANSITION_AND_RANK_ISSUE_MUTATION = gqlTagGira`
mutation JWMBoardTransitionAndRankIssue($input: JWMTransitionAndRankItemInput!, $fieldIds: [String]!, $withConfluenceLinks: Boolean!, $withCoverMedia: Boolean!, $withIssueLinks: Boolean!, $withChildrenCount: Boolean!) {
    transitionAndRankJwmItems(input: $input, fieldIdsToReturn: $fieldIds) {
      items {
        ...JIS2_JWMBoardItemFragment
        ...JIS2_JWMBoardConfluenceLinksFragment @include(if: $withConfluenceLinks)
        ...JIS2_JWMBoardItemCoverMediaFragment @include(if: $withCoverMedia)
        ...JIS2_JWMBoardIssueLinksFragment @include(if: $withIssueLinks)
      }
      success
      errors {
        message
        extensions {
          statusCode
          errorType
          __typename
        }
        __typename
      }
      __typename
    }
}
${JIS2_JWMBOARD_ITEM_FRAGMENT}
${JIS2_JWMBOARD_CONFLUENCE_LINKS_FRAGMENT}
${JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT}
${JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT}
`;

import { initialState } from '../../../common/constants';
import type { Actions } from '../../../common/types';

const closeIssueTransitionModal: Actions['closeIssueTransitionModal'] =
	() =>
	({ setState }) => {
		setState({
			...initialState,
			isModalOpen: false,
			isSubsequentLoad: true,
		});
	};

export default closeIssueTransitionModal;

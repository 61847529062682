import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessageNameIsTaken: {
		id: 'business-create-status-modal.error-message-name-is-taken',
		defaultMessage: '"{name}" is already a status name in this project',
		description:
			'Title of the warning message to let the user know that they have chosen a column name that already exists',
	},
	errorMessageNameIsTooLong: {
		id: 'business-create-status-modal.error-message-name-is-too-long',
		defaultMessage: "The status name can't exceed 60 characters",
		description:
			'Title of the warning message to let the user know that they reached the maximum character limit for the column name',
	},
	title: {
		id: 'business-create-status-modal.title',
		defaultMessage: 'Create status',
		description: 'Modal title for the create status modal',
	},
	nameFieldLabel: {
		id: 'business-create-status-modal.name-field-label',
		defaultMessage: 'Name',
		description: 'Label for the status name input field in the create status modal',
	},
	categoryFieldLabel: {
		id: 'business-create-status-modal.category-field-label',
		defaultMessage: 'Category',
		description: 'Label for the status category select field in the create status modal',
	},
	categoryFieldDescription: {
		id: 'business-create-status-modal.category-field-description',
		defaultMessage: 'Categories group multiple statuses of the same kind',
		description: 'Description for the status category select field in the create status modal',
	},
	toDoCategoryOption: {
		id: 'business-create-status-modal.to-do-category-option',
		defaultMessage: 'To do status',
		description: 'The To-do status category in the status category dropdown',
	},
	inProgressCategoryOption: {
		id: 'business-create-status-modal.in-progress-category-option',
		defaultMessage: 'In progress status',
		description: 'The In-progress status category in the status category dropdown',
	},
	doneCategoryOption: {
		id: 'business-create-status-modal.done-category-option',
		defaultMessage: 'Done status',
		description: 'The Done status category in the status category dropdown',
	},
	cancelButton: {
		id: 'business-create-status-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button text in the create column modal',
	},
	submitButton: {
		id: 'business-create-status-modal.submit-button',
		defaultMessage: 'Submit',
		description: 'Submit button text in the create column modal',
	},
});

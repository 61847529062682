import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { Sorting } from '../../../sorting/types';
import type { TransformedCascadingSelectField } from '../types';
import { getCascadingSelectStringValue } from '../value';

const getCascadingSelectSorting: Sorting<TransformedCascadingSelectField> = (field) =>
	getCascadingSelectStringValue(field.value);

export const cascadingSelectSortingHandler = {
	[CASCADING_SELECT_CF_TYPE]: getCascadingSelectSorting,
};

/** @jsx jsx */
import React, { useEffect, type PropsWithChildren, type ReactNode } from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { useSidebarQueryParams } from '../../../controllers/query-params';
import { useResizer } from '../../../controllers/resizer';
import { useSidebar } from '../../../controllers/sidebar';
import { Wrapper, SidebarContent, ResizeHandle } from './styled';

export type SidebarTheme = 'business' | 'software';

type SidebarProps = { sidebarWidth: number; theme: SidebarTheme };

const Sidebar = ({ children, sidebarWidth, theme }: PropsWithChildren<SidebarProps>) => (
	<Box
		xcss={[sidebarStyles, theme === 'business' && businessSidebarStyles]}
		// eslint-disable-next-line jira/react/no-style-attribute
		style={{ width: sidebarWidth }}
	>
		{children}
	</Box>
);

type Props = {
	children: ReactNode;
	content?: ReactNode | null;
	theme?: SidebarTheme;
};

export const SidebarLayout = ({ children, theme = 'business' }: Props) => {
	useSidebarQueryParams();
	const [{ content, sidebarWidth }, { setSidebarContent }] = useSidebar();
	const { onMouseDown, isResizing } = useResizer();

	// reset sidebar content on view unmount
	useEffect(
		() => () => {
			setSidebarContent(null);
		},
		[setSidebarContent],
	);

	return (
		<Wrapper
			isResizing={isResizing}
			data-testid="business-app-wrapper.ui.app-wrapper.sidebar-layout.sidebar-layout-wrapper"
		>
			{children}
			{content && (
				<Sidebar sidebarWidth={sidebarWidth} theme={theme}>
					<ResizeHandle onMouseDown={onMouseDown} />
					<SidebarContent>{content}</SidebarContent>
				</Sidebar>
			)}
		</Wrapper>
	);
};

const sidebarStyles = xcss({
	backgroundColor: 'elevation.surface',
	flexGrow: 0,
	flexShrink: 0,
	position: 'relative',
	zIndex: 'card',
	maxWidth: '75%',
});

const businessSidebarStyles = xcss({
	borderTopLeftRadius: 'border.radius.200',
	borderTopRightRadius: 'border.radius.200',
	boxShadow: 'elevation.shadow.overlay',
	marginRight: 'space.300',
	marginTop: 'space.300',
});

import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import {
	STORY_POINTS_ALIAS_FIELD_ID,
	STORY_POINT_ESTIMATE_ALIAS_FIELD_ID,
	START_DATE_ALIAS_FIELD_ID,
} from '@atlassian/jira-business-constants';
import type { FieldConfig as IssueTypeFieldConfig } from '@atlassian/jira-business-entity-project/src/services/issue-type-field-config/index.tsx';
import type {
	DataType,
	BusinessIssueField,
} from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import type { Status } from '@atlassian/jira-business-fetch-statuses/src/services/fetch-statuses/types.tsx';
import type { UserResolver } from '@atlassian/jira-business-fetch-users/src/services/get-users/index.tsx';
import type { User } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import {
	CATEGORY_TYPE,
	ISSUE_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	REPORTER_TYPE,
	CREATED,
	UPDATED,
	LABELS_TYPE,
	PRIORITY_TYPE,
	USER_CF_TYPE,
	COMPONENTS_TYPE,
	PEOPLE_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	DATE_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { DATE_RANGE_TYPE, EXTRA_CUSTOM_FIELD_TYPES } from '../../common/constants';
import { type AvatarOption, FieldType, type FieldConfig } from '../../common/types';
import { EMPTY_FILTER_VALUE } from '../../constants';
import type { Option, Resolvers } from '../types';
import messages from './messages';
import type {
	LabelSuggestion,
	LabelSuggestions,
	GetFilterFieldConfigAndResolversArgs,
	FieldOptionsData,
	PriorityOption,
	IssueTypeOption,
	StatusOption,
} from './types';

export const transformPriority = memoizeOne(
	(priorityFieldConfig: IssueTypeFieldConfig | null): PriorityOption[] =>
		[...(priorityFieldConfig?.options || [])].reduce<Array<PriorityOption>>((acc, next) => {
			acc.push({
				value: next.name,
				label: next.name,
				avatar: next.iconUrl || '',
			});
			return acc;
		}, []),
);

export const transformIssueType = memoizeOne((issueTypeData: DataType): IssueTypeOption[] =>
	issueTypeData.issueTypes.reduce<Array<IssueTypeOption>>((acc, next) => {
		acc.push({
			value: next.id,
			label: next.name,
			id: next.id,
			avatar: next.avatarUrl,
			square: true,
		});
		return acc;
	}, []),
);

export const transformStatuses = memoizeOne((statuses: Status[]): StatusOption[] =>
	statuses.reduce<Array<StatusOption>>((acc, next) => {
		acc.push({
			value: next.untranslatedName,
			label: next.name,
			categoryId: next.statusCategory.id,
			// Board needs the status id as number for client side filtering
			id: Number(next.id),
		});
		return acc;
	}, []),
);

export const transformUsers = memoizeOne((users: User[]): AvatarOption[] =>
	users.reduce<Array<AvatarOption>>((acc, next) => {
		acc.push({
			value: next.accountId || '',
			label: next.displayName,
			avatar: get(next, ['avatarUrls', '24x24'], ''),
		});
		return acc;
	}, []),
);

export const transformLabels = memoizeOne((labels: string[]): Option[] =>
	labels.reduce<Array<Option>>((acc, next) => {
		acc.push({
			value: next || '',
			label: next,
		});
		return acc;
	}, []),
);

export const transformLabelSuggestions = memoizeOne((labels: LabelSuggestions): Option[] =>
	labels?.suggestions.reduce(
		(
			acc: Array<Option>,
			next:
				| LabelSuggestion
				| {
						html: string;
						label: string;
				  },
		) => {
			acc.push({
				value: next.label || '',
				label: next.label,
			});
			return acc;
		},
		[],
	),
);

type GetTypeArgs = {
	type: string;
	aliasFieldId?: string;
};

const getType = ({ type }: GetTypeArgs) => {
	switch (type) {
		case PRIORITY_TYPE:
			return FieldType.PrioritySelectFilter;
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
		case USER_CF_TYPE:
		case PEOPLE_CF_TYPE:
			return FieldType.AvatarAsyncSelectFilter;
		case ISSUE_TYPE:
			return FieldType.AvatarSelectFilter;
		case STATUS_TYPE:
			return FieldType.LozengeSelectFilter;
		case LABELS_TYPE:
		case COMPONENTS_TYPE:
		case SELECT_CF_TYPE:
		case MULTI_SELECT_CF_TYPE:
			return FieldType.AsyncSelectFilter;
		case CREATED:
		case UPDATED:
		case DATE_CF_TYPE:
			return FieldType.DateFilter;
		case CATEGORY_TYPE:
			return FieldType.CategorySelectFilter;
		case NUMBER_CF_TYPE:
		case STORY_POINT_ESTIMATE_CF_TYPE:
		default:
			return FieldType.SelectFilter;
	}
};

const getSelectCustomFieldValues = (field?: BusinessIssueField) =>
	(field?.fieldConfig?.selectOptions ?? []).map(({ value }) => ({
		label: value,
		value,
	}));

type GetValuesArgs = {
	type: string;
	aliasFieldId?: string;
	fieldOptionsData: FieldOptionsData;
	field?: BusinessIssueField;
};

const getValues = ({ type, fieldOptionsData, field, aliasFieldId }: GetValuesArgs) => {
	switch (type) {
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
		case USER_CF_TYPE:
		case PEOPLE_CF_TYPE:
			return fieldOptionsData.users;
		case SELECT_CF_TYPE:
		case MULTI_SELECT_CF_TYPE:
		case MULTI_CHECKBOXES_CF_TYPE:
			return getSelectCustomFieldValues(field);
		case NUMBER_CF_TYPE:
			if (aliasFieldId === STORY_POINTS_ALIAS_FIELD_ID) {
				return fieldOptionsData[STORY_POINTS_ALIAS_FIELD_ID];
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return fieldOptionsData[type as keyof FieldOptionsData];

		case STORY_POINT_ESTIMATE_CF_TYPE:
			return fieldOptionsData[STORY_POINT_ESTIMATE_ALIAS_FIELD_ID];
		default:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return fieldOptionsData[type as keyof FieldOptionsData];
	}
};

const isUserField = (type: string) => {
	switch (type) {
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
		case USER_CF_TYPE:
		case PEOPLE_CF_TYPE:
			return true;
		default:
			return false;
	}
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const isCustomField = (type: string) => EXTRA_CUSTOM_FIELD_TYPES.has(type as any);

const createUserValueGetter = (userResolver: UserResolver) =>
	memoizeOne((accountIds: string[]) =>
		// @ts-expect-error - TS7006 - Parameter 'response' implicitly has an 'any' type.
		userResolver.getUsersFromAccountId(accountIds).then((response) => transformUsers(response)),
	);

export const getFilterFieldConfigAndResolvers = ({
	formatMessage,
	allowedFieldTypes,
	allowedAliasFieldId = new Set(),
	allFields,
	fieldOptionsData,
	fetchUsers,
	fetchLabels,
	fetchComponents,
	fetchCategories,
	userResolver,
}: GetFilterFieldConfigAndResolversArgs): {
	fieldConfig: FieldConfig;
	resolvers: Resolvers;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fieldConfig: Record<string, any> = {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const resolvers: Record<string, any> = {};

	if (allowedFieldTypes.has(DATE_RANGE_TYPE)) {
		// manually add dateRange to fieldConfig
		fieldConfig.dateRange = {
			label: formatMessage(messages.dateRangeLabel),
			type: FieldType.DateFilter,
		};
	}

	const fields = allFields.filter(
		(field) =>
			allowedFieldTypes.has(field.type ?? '') || allowedAliasFieldId.has(field.aliasFieldId || ''),
	);

	fields.forEach((field) => {
		const { name, type, id, aliasFieldId } = field;

		// if the type is unsupported
		// or it's the start date field (which is handled separately as 'date range')
		// then skip
		if (type == null || aliasFieldId === START_DATE_ALIAS_FIELD_ID) {
			return;
		}

		const selectType = getType({ type });

		fieldConfig[id] = {
			systemType: type,
			label: name,
			type: selectType,
			placeholder: formatMessage(messages.filterPlaceholder),
			options: getValues({ type, fieldOptionsData, field, aliasFieldId }),
			isCustomField: isCustomField(type),
			fieldConfigVersion: 1,
		};
		if (aliasFieldId != null && allowedAliasFieldId.has(aliasFieldId)) {
			fieldConfig[id].aliasFieldId = aliasFieldId;
		}

		if (type === ISSUE_TYPE) {
			fieldConfig[id].label = formatMessage(messages.typeLabel);
		}

		if (isUserField(type)) {
			fieldConfig[id].loadOptions = fetchUsers;
			fieldConfig[id].defaultOptions = getValues({ type, fieldOptionsData });
			resolvers[id] = createUserValueGetter(userResolver);
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: 'EMPTY',
					label: formatMessage(messages.unassignedLabel),
					isEmptyOption: true,
				},
			];
		}

		if (type === LABELS_TYPE) {
			fieldConfig[id].loadOptions = fetchLabels;
			fieldConfig[id].defaultOptions = getValues({ type, fieldOptionsData });
		}

		if (type === COMPONENTS_TYPE) {
			fieldConfig[id].loadOptions = fetchComponents;
			fieldConfig[id].defaultOptions = getValues({ type, fieldOptionsData });
		}

		if (type === CATEGORY_TYPE) {
			fieldConfig[id].loadOptions = fetchCategories;
			fieldConfig[id].defaultOptions = getValues({ type, fieldOptionsData });
		}

		if (
			type === SELECT_CF_TYPE ||
			type === MULTI_SELECT_CF_TYPE ||
			type === MULTI_CHECKBOXES_CF_TYPE
		) {
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noneOption),
					isEmptyOption: true,
				},
			];
		}
	});

	return { fieldConfig, resolvers };
};

import { useState, useCallback } from 'react';
import { EMBEDDED_CONFLUENCE_MODE } from '@atlassian/embedded-confluence';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src';
import type { CreatePageProps } from './create-page-modal/types';

export const useConfluenceCreatePageModal = () => {
	const [createModalProps, setCreateModalProps] = useState<
		Pick<CreatePageProps, 'showCreateModal' | 'onCreate' | 'onCloseComplete' | 'onCancel'>
	>({ showCreateModal: false, onCreate: undefined });

	const showCreatePage = useCallback(
		({
			onCreate,
			onCloseComplete,
			onCancel,
		}: Pick<CreatePageProps, 'onCreate' | 'onCloseComplete' | 'onCancel'> = {}) =>
			setCreateModalProps({ showCreateModal: true, onCreate, onCloseComplete, onCancel }),
		[],
	);

	const hideCreatePage = useCallback(
		() => setCreateModalProps({ showCreateModal: false, onCreate: undefined }),
		[],
	);

	return {
		createModalProps,
		showCreatePage,
		hideCreatePage,
	};
};

export const useConfluenceViewModalNew = () => {
	const [viewModalProps, setViewModalProps] = useState<{
		confluencePageUrl: string | null;
		viewMode?: EMBEDDED_CONFLUENCE_MODE;
		onCloseCompleteCallback?: () => void;
	}>({
		confluencePageUrl: null,
		viewMode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
		onCloseCompleteCallback: undefined,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const showConfluencePage = useCallback(
		({
			confluenceUrl,
			mode,
			onCloseComplete,
		}: {
			confluenceUrl: string;
			mode?: EMBEDDED_CONFLUENCE_MODE;
			onCloseComplete?: () => void;
		}) => {
			setViewModalProps({
				confluencePageUrl: confluenceUrl,
				viewMode: mode || EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
				onCloseCompleteCallback: onCloseComplete,
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'jwmConfluenceModal viewed');
		},
		[createAnalyticsEvent],
	);

	/**
	 * Sets the modal props state back to a default state, which will cause the modal to close
	 *
	 * NOTE: There's a [StrictMode](https://react.dev/reference/react/StrictMode) bug using the
	 * `onCloseCompleteCallback` prop on `@atlaskit/modal-dialog`. This issue has been raised with the
	 * Design System team in the following [thread](https://atlassian.slack.com/archives/CFJ9DU39U/p1715670965075749).
	 * StrictMode is enabled by default in local development so this will not work in local dev
	 * until fixed but works in production.
	 */
	const hideConfluencePage = useCallback(() => {
		setViewModalProps({
			confluencePageUrl: null,
			viewMode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
			onCloseCompleteCallback: undefined,
		});
		fireTrackAnalytics(createAnalyticsEvent({}), 'jwmConfluenceModal closed');
	}, [createAnalyticsEvent]);

	return {
		viewModalProps,
		showConfluencePage,
		hideConfluencePage,
	};
};

import { useEffect, useCallback } from 'react';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants';
import { fg } from '@atlassian/jira-feature-gating';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useQueryParams } from '@atlassian/jira-software-router-utils/src/services/query-params/index.tsx';
import { useSidebar, getSidebarQueryParams } from '../sidebar';

const isEscapeEvent = (e: KeyboardEvent) =>
	e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;

export const useSidebarQueryParams = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ content }, { setSidebarContent, resetSidebarQueryParams }] = useSidebar();
	const sidebarQueryParams = getSidebarQueryParams();

	const [queryParams, setQueryParams] = useQueryParams(...sidebarQueryParams);

	const prevQueryParams = usePreviousWithInitial(queryParams);

	const closeSidebar = useCallback(() => {
		// reset sidebar query params (IssueApp, Comments)
		resetSidebarQueryParams();

		// reset sidebar content
		setSidebarContent(null);
	}, [resetSidebarQueryParams, setSidebarContent]);

	// keep only one sidebar-related query param at the time
	useEffect(() => {
		sidebarQueryParams.forEach((queryParam) => {
			if (!prevQueryParams[queryParam] && queryParams[queryParam]) {
				const newQueryParams = sidebarQueryParams
					.filter((param) => param !== queryParam)
					.reduce<
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						Record<string, any>
					>((acc, param) => Object.assign(acc, { [param]: undefined }), {});

				setQueryParams(newQueryParams);
			}
		});
	}, [prevQueryParams, queryParams, setQueryParams, sidebarQueryParams]);

	// handle closing sidebar on Escape
	useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (!queryParams[SELECTED_ISSUE_PARAM] && fg('jira_list_keyboard_nav')) {
				// sidebar is not open, exit
				return;
			}
			if (queryParams[SELECTED_ISSUE_PARAM] && content?.key === 'unscheduledSidebar') {
				// should not close the sidebar if the sidebar and modal is open
				return;
			}
			if (isEscapeEvent(event)) {
				fireUIAnalytics(createAnalyticsEvent({}), 'sidebar closed', 'esc');
				closeSidebar();
			}
		};
		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [closeSidebar, content?.key, createAnalyticsEvent, queryParams]);
};

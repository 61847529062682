import {
	DEFAULT_RANK_SORTING_DIRECTION,
	REMOTE_ISSUE_LINKS_TYPE,
	DEFAULT_PAGE_SIZE,
} from '@atlassian/jira-business-constants';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { GroupQuery } from '../../types/grouping';
import { TASK } from '../../types/hierarchy';
import type { GenerateJQLInput, UseIssuesInput, ParentIssue } from '../../types/query-input';
import type { SortingAttributes } from '../../types/sorting';
import { getJqlTerm } from '../get-jql-term';

const DEFAULT_CHILDREN_PER_PAGE = DEFAULT_PAGE_SIZE;
const IS_EMPTY = 'IS EMPTY';
const IS_NOT_EMPTY = 'IS NOT EMPTY';

// eslint-disable-next-line complexity
export const generateJql = (input: GenerateJQLInput, parentIssueForJql?: ParentIssue) => {
	const {
		groupQuery,
		hierarchyLevel,
		hierarchyParent,
		issueIds,
		jqlContext,
		projectIdOrKey,
		quickFiltersJql,
		sorting,
	} = input;

	const defaultSorting = `ORDER BY Rank ${DEFAULT_RANK_SORTING_DIRECTION}`;

	const jql: Array<string> = [];
	// Check if sorting direction is valid, the direction possible passed from the URL params which can be invalid
	const isValidDirection =
		sorting?.direction?.toUpperCase() === 'ASC' || sorting?.direction?.toUpperCase() === 'DESC';

	const sortByClause =
		sorting != null && sorting.sortBy != null && sorting.direction != null && isValidDirection
			? `ORDER By ${getJqlTerm(sorting.sortBy)} ${sorting.direction}, Rank ${sorting.direction}`
			: defaultSorting;

	if (Array.isArray(issueIds) && issueIds.length > 0) {
		jql.push(`(${issueIds.map((id) => `id = ${id}`).join(' or ')})`);
	}

	if (jqlContext) {
		jql.push(jqlContext);
	} else if (typeof projectIdOrKey !== 'undefined') {
		jql.push(`project = '${projectIdOrKey}'`);
	}

	if (typeof hierarchyLevel === 'number' && hierarchyParent === TASK) {
		// fetch items by hierarchyLevel + any item that doesn't have a parent (e.g. tasks that don't belong to any Epic)
		if (
			ff('list-uses-new-jql-parent-project-directive_v5gfs') &&
			typeof projectIdOrKey !== 'undefined'
		) {
			jql.push(
				`(hierarchyLevel = ${hierarchyLevel} OR parent ${IS_EMPTY} OR (parent ${IS_NOT_EMPTY} AND parentProject != '${projectIdOrKey}'))`,
			);
		} else {
			jql.push(`(hierarchyLevel = ${hierarchyLevel} OR parent ${IS_EMPTY})`);
		}
	}

	if (hierarchyParent === TASK) {
		jql.push('type in standardIssueTypes()');
	}

	// Controls the query to get all children for a single parent
	if (parentIssueForJql && parentIssueForJql.hierarchyType === TASK) {
		jql.push(`parent = '${parentIssueForJql.issueId}'`);
	}

	// Controls the query to get group items
	if (groupQuery) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { jqlTerm, value } = groupQuery as GroupQuery;
		if (!value) {
			jql.push(`${jqlTerm} ${IS_EMPTY}`);
		} else {
			jql.push(`${jqlTerm} = ${value}`);
		}

		if (hierarchyParent === TASK) {
			if (
				ff('list-uses-new-jql-parent-project-directive_v5gfs') &&
				typeof projectIdOrKey !== 'undefined'
			) {
				jql.push(
					`(parent ${IS_EMPTY} OR (parent ${IS_NOT_EMPTY} AND parentProject != '${projectIdOrKey}'))`,
				);
			} else {
				jql.push(`parent ${IS_EMPTY}`);
			}
		}
	}

	if (quickFiltersJql != null) {
		jql.push(`(${quickFiltersJql})`);
	}

	return `${jql.join(' AND ')} ${sortByClause}`;
};

export const getVariables = (input: UseIssuesInput) => {
	const { fieldIds, parentIssue } = input;

	const singleQueryJql = { jql: generateJql(input, parentIssue) };

	// REMOTE_ISSUE_LINKS_TYPE should not be part of the fieldIds as it is only used to determine
	// whether legacyConfluenceLinks(JIS2) is fetched
	const newFieldIds = Array.isArray(fieldIds) ? [...fieldIds] : [];
	const remoteIssueLinksIndex = newFieldIds.indexOf(REMOTE_ISSUE_LINKS_TYPE);
	const hasConfluenceLinks = remoteIssueLinksIndex > -1;
	if (hasConfluenceLinks) {
		newFieldIds.splice(remoteIssueLinksIndex, 1);
	}

	return {
		...singleQueryJql,
		first: parentIssue ? DEFAULT_CHILDREN_PER_PAGE : DEFAULT_PAGE_SIZE,
		fieldIds: newFieldIds,
		withConfluenceLinks: hasConfluenceLinks,
		includeDoneItems: input.isHidingDoneItems === false,
		isParentFieldVisible: input.isParentFieldVisible ?? false,
		isDevSummaryFieldVisible: input.isDevSummaryFieldVisible ?? false,
		withParentRank: ff('jwm-timeline-sort-parent-rank-first_waal4'),
		withParentIssueId: fg('jsw_list_view_-_all_the_fields'),
	};
};

export type { UseIssuesInput, SortingAttributes };

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	boardInitErrorTitle: {
		id: 'work-management-board.controllers.board-data.board-init-error-title',
		defaultMessage: 'Failed to load the board',
		description: 'Error title informing the user loading issue board data failed.',
	},
	boardInitErrorMessage: {
		id: 'work-management-board.controllers.board-data.board-init-error-message',
		defaultMessage: "We couldn't load your board, try refreshing the page or try again later.",
		description: 'Error message informing the user loading issue board data failed.',
	},
});

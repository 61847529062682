import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	inviteSuccessMessageTitle: {
		id: 'business-invite-people.controllers.modal-handlers.invite-success-message-title',
		defaultMessage: 'Invitation sent',
		description: 'Message to be shown as flag title when user successfully invite',
	},
	inviteSuccessMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.invite-success-message-description',
		defaultMessage:
			"You've invited {totalPeopleAdded, plural, one {{email}} =2 {{email} and {totalPeopleAddedMinusOne} other} other {{email} and {totalPeopleAddedMinusOne} others}} to this business project.",
		description:
			'Message to be shown as flag description when user successfully invite, with variations depending on number of users invited',
	},
	inviteSuccessMessageDescriptionAgnostic: {
		id: 'business-invite-people.controllers.modal-handlers.invite-success-message-description-agnostic',
		defaultMessage: "You've successfully invited people to your business project.",
		description:
			'Message to be shown as flag description when user successfully invited, agnostic of number of users invited',
	},
	inviteFailedMessageTitle: {
		id: 'business-invite-people.controllers.modal-handlers.invite-failed-message-title',
		defaultMessage: 'Something went wrong',
		description: 'Message to be shown as flag description when user fail to invite',
	},
	inviteFailedMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.invite-failed-message-description',
		defaultMessage: 'We weren’t able to invite any users. Contact your admin for help.',
		description: 'Message to be shown as flag description when user fail to invite',
	},
	inviteFailedMessageCta: {
		id: 'business-invite-people.controllers.modal-handlers.invite-failed-message-cta',
		defaultMessage: 'Contact your admin',
		description: 'Message to be shown as flag CTA when user fail to invite',
	},
	inviteFailedGenericMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.invite-failed-generic-message-description',
		defaultMessage: 'We weren’t able to invite any users, please try again.',
		description: 'Message to be shown as flag description when user fail to invite',
	},
	inviteFailedBadRequestMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.invite-failed-bad-request-message-description',
		defaultMessage:
			'We weren’t able to invite any users. Check your request, then give it another try.',
		description: 'Message to be shown as flag description when user fail to invite',
	},
	inviteFailedNetworkErrorMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.invite-failed-network-error-message-description',
		defaultMessage:
			'We weren’t able to invite any users. Check your network connection, then give it another try.',
		description: 'Message to be shown as flag description when user fail to invite',
	},
	requestInviteSuccessMessageTitle: {
		id: 'business-invite-people.controllers.modal-handlers.request-invite-success-message-title',
		defaultMessage: 'Access request sent to your admin',
		description:
			'Message to be shown as flag title when request to invite a user is successfully sent',
	},
	requestInviteSuccessMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.request-invite-success-message-description',
		defaultMessage:
			'Your admin needs to approve access for {totalPeopleAdded, plural, one {{email}} =2 {{email} and {totalPeopleAddedMinusOne} other} other {{email} and {totalPeopleAddedMinusOne} others}}.',
		description:
			'Message to be shown as flag description when user successfully invite, with variations depending on number of users invited',
	},
	requestInviteSuccessMessageDescriptionAgnostic: {
		id: 'business-invite-people.controllers.modal-handlers.request-invite-success-message-description-agnostic',
		defaultMessage: 'Your admin needs to approve access for people to your business project.',
		description:
			'Message to be shown as flag description when user successfully invited, agnostic of number of users invited',
	},
	peopleAddedSuccessMessageTitle: {
		id: 'business-invite-people.controllers.modal-handlers.people-added-success-message-title',
		defaultMessage: 'New people added',
		description: 'Message to be shown as flag title when user sucessfully added into the project',
	},
	peopleAddedSuccessMessageDescription: {
		id: 'business-invite-people.controllers.modal-handlers.people-added-success-message-description',
		defaultMessage:
			'{totalPeopleAdded, plural, one {{email}} =2 {{email} and {totalPeopleAddedMinusOne} other} other {{email} and {totalPeopleAddedMinusOne} others}} added to the project. You can also add teammates and manage their access to the project from Project settings',
		description:
			'Message to be shown as flag description when user sucessfully added into the project, with variations depending on number of users added',
	},
	peopleAddedSuccessMessageDescriptionAgnostic: {
		id: 'business-invite-people.controllers.modal-handlers.people-added-success-message-description-agnostic',
		defaultMessage:
			'People added to the project. You can also add teammates and manage their access to the project from Project settings',
		description:
			'Message to be shown as flag description when user sucessfully added into the project, agnostic of number of users added',
	},
	peopleAddedManageAccess: {
		id: 'business-invite-people.controllers.modal-handlers.people-added-manage-access',
		defaultMessage: 'Manage access',
		description: 'Manage access link to redirect to the project settings page',
	},
	userLimitInviteErrorTitle: {
		id: 'business-invite-people.controllers.modal-handlers.user-limit-invite-error-title',
		defaultMessage: "Can't add more people",
		description:
			'Message to be shown as flag title when user fails to invite if they have reached their user limit',
	},
	userLimitInviteErrorDescriptionAdmin: {
		id: 'business-invite-people.controllers.modal-handlers.user-limit-invite-error-description-admin',
		defaultMessage: "You've reached your user limit. Upgrade your plan or remove other users.",
		description:
			'Message to be shown as flag description when admin user fails to invite if they have reached their user limit',
	},
	userLimitInviteErrorDescription: {
		id: 'business-invite-people.controllers.modal-handlers.user-limit-invite-error-description',
		defaultMessage: "You've reached your user limit. Contact your admin for help.",
		description:
			'Message to be shown as flag description when non admin user fails to invite if they have reached their user limit',
	},
	viewUsers: {
		id: 'business-invite-people.controllers.modal-handlers.view-users',
		defaultMessage: 'View users',
		description:
			'The link label on a notification shown to admins so that they may click to see users in jira project settings > access',
	},
	upgradePlan: {
		id: 'business-invite-people.controllers.modal-handlers.upgrade-plan',
		defaultMessage: 'Upgrade plan',
		description:
			'The link label on a notification shown to users so that they may click to load a new page to upgrade their products plan',
	},
});

import {
	createStore,
	createContainer,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { initialState } from '../../common/constants';
import type { Actions, State } from '../../common/types';
import * as actions from '../actions';

const store = createStore<State, Actions>({
	name: 'issue-transition.issue-transition-form.use-trigger-issue-transition-modal',
	initialState,
	actions,
});

export const TriggerIssueTransitionModalContainer = createContainer(store);
export const useTriggerIssueTransitionModalActions = createActionsHook(store);
export const useTriggerIssueTransitionModalState = createStateHook(store);

import React, { type ReactNode, createContext, useContext } from 'react';
import type { JiraProject } from '@atlassian/jira-business-entity-common/src/types.tsx';

type ProviderProps = {
	children: ReactNode;
	project: JiraProject;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const Context = createContext<JiraProject>(null as any);

export const ProjectHookProvider = ({ project, children }: ProviderProps) => (
	<Context.Provider value={project}>{children}</Context.Provider>
);

export const useProject = (): JiraProject => {
	const project = useContext(Context);

	if (!project) {
		throw new Error('useProject must be used within a ProjectHookProvider');
	}

	return project;
};

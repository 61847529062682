import { isFedRamp } from '@atlassian/atl-context';
import { ff } from '@atlassian/jira-feature-flagging';
import type { TriggerPointKeyType } from '@atlassian/jira-issue-transition-common-types';

export const getTriggerPointsFlag = (triggerPointKey: TriggerPointKeyType): boolean => {
	const triggerPointsFlags = {
		'issue-transition-issue-view': ff('issue-transition-issue-view_yq5a8'),
		'issue-transition-issue-view-shortcut-dialog': ff('issue-transition-issue-view-shortcut_lv9l3'),
		'issue-transition-board-card-transition': ff('issue-transition-board-card-transition_ciq82'),
		'issue-transition-advanced-roadmaps-jira-transition': ff(
			'issue-transition-advanced-roadmaps-jira-transition_b1gmi',
		),
		'issue-transition-command-palette-status-field-transition': ff(
			'issue-transition-command-palette-status-field-transition_ddmcl',
		),
		'issue-transition-issue-results-shortcut-transition': ff(
			'issue-transition-issue-results-shortcut-transition_9jznw',
		),
		'issue-transition-backlog-inline-transition': ff(
			'issue-transition-backlog-inline-transition_2mpzc',
		),
		'issue-transition-status-field-trigger': ff('issue-transition-status-field-trigger_ygzr6'),
		'trigger-point-key-not-defined': ff('trigger-point-key-not-defined_cixw0'),
	};

	return triggerPointsFlags[triggerPointKey];
};

export const isNewTransitionScreenEnabled = (
	preferNewIssueTransitionScreen: boolean,
	triggerPointKey?: TriggerPointKeyType,
) => {
	if (isFedRamp()) {
		return true;
	}

	if (!ff('render-modern-issue-transition_cxt0z')) return false;

	/* DEFAULT VALUE IS FALSE FOR ANY TRIGGER POINT NOT AVAILABLE IN THE MAP ABOVE
	 *  If trigger key is not present, then by default overall ITS will be false.
	 *  For any new trigger point, it must be necessary to list the trigger point in the map above.
	 *  When we cleanup FF for trigger points, then this should be also removed.
	 */
	if (!triggerPointKey || !getTriggerPointsFlag(triggerPointKey)) return false;

	return preferNewIssueTransitionScreen || ff('issue-transition-customisation-for-srb_0eyvc');
};

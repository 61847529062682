import React, { type ReactElement, useMemo } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { confluencePagesContext } from '../../controllers/confluence-pages-context';
import CreatePage from './create-page-modal';
import { useConfluenceCreatePageModal, useConfluenceViewModalNew } from './utils';
import ConfluenceViewModal from './view-page-modal';

export const ConfluenceModalProvider = ({ children }: { children: ReactElement }) => {
	const viewModalControlNew = useConfluenceViewModalNew();
	const { createModalProps, showCreatePage, hideCreatePage } = useConfluenceCreatePageModal();

	const confluenceContext = useMemo(
		() => ({
			showConfluencePage: viewModalControlNew.showConfluencePage,
			showCreatePage,
		}),
		[viewModalControlNew.showConfluencePage, showCreatePage],
	);

	const { confluencePageUrl, viewMode, onCloseCompleteCallback } =
		viewModalControlNew.viewModalProps;

	return (
		<confluencePagesContext.Provider value={confluenceContext}>
			{children}
			<ModalTransition>
				{confluencePageUrl != null && (
					<ConfluenceViewModal
						onClose={viewModalControlNew.hideConfluencePage}
						confluenceUrl={confluencePageUrl || ''}
						mode={viewMode}
						onCloseComplete={onCloseCompleteCallback}
						isOpen
					/>
				)}
			</ModalTransition>
			<CreatePage onClose={hideCreatePage} {...createModalProps} />
		</confluencePagesContext.Provider>
	);
};

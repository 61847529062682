import type { SubtaskMetadata } from '../../common/types';

export const getSubtaskMetadata = (
	totalCount?: number,
	totalNotDoneCount?: number,
): SubtaskMetadata => {
	if (totalCount == null || totalNotDoneCount == null) {
		return {
			total: 0,
			done: 0,
		};
	}

	return {
		total: totalCount,
		done: totalCount - totalNotDoneCount,
	};
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	transitionValidationErrorTitle: {
		id: 'work-management-board.controllers.update-issue.transition-validation-error-title',
		defaultMessage: 'Transition failed',
		description:
			'Title of the error flag when an issue failed to be transitioned to a new status due to validation errors.',
	},
	failedToTransitionErrorTitle: {
		id: 'work-management-board.controllers.update-issue.failed-to-transition-error-title',
		defaultMessage: "We couldn't change the status of the item",
		description: 'Title of the error flag when an issue failed to be transitioned to a new status.',
	},
	failedToTransitionErrorDescription: {
		id: 'work-management-board.controllers.update-issue.failed-to-transition-error-description',
		defaultMessage: 'Check your connection, then give it another try.',
		description:
			'Description of the error flag when an issue failed to be transitioned to a new status.',
	},
	failedToRankErrorTitle: {
		id: 'work-management-board.controllers.update-issue.failed-to-rank-error-title',
		defaultMessage: "We couldn't move the item",
		description: 'Title of the error flag when an issue failed to be ranked.',
	},
	failedToRankErrorDescription: {
		id: 'work-management-board.controllers.update-issue.failed-to-rank-error-description',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Description of the error flag when an issue failed to be ranked.',
	},
	failedToUpdateFieldsErrorTitle: {
		id: 'work-management-board.controllers.update-issue.failed-to-update-fields-error-title',
		defaultMessage: "We couldn't update the item",
		description: 'Title of the error flag when an issue failed to have its fields updated.',
	},
	failedToUpdateFieldsErrorDescription: {
		id: 'work-management-board.controllers.update-issue.failed-to-update-fields-error-description',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Description of the error flag when an issue failed to have its fields updated.',
	},
});

import { colors as themeColors } from '@atlaskit/theme';

const colors: string[] = [
	themeColors.R300,
	themeColors.R400,
	themeColors.R500,
	themeColors.Y300,
	themeColors.Y400,
	themeColors.Y500,
	themeColors.G300,
	themeColors.G400,
	themeColors.G500,
	themeColors.T300,
	themeColors.T400,
	themeColors.T500,
	themeColors.P300,
	themeColors.P400,
	themeColors.P500,
	themeColors.N200,
	themeColors.N500,
	themeColors.N800,
];

export const getColorFromString = (
	str: string,
): {
	index: number;
	color: string;
} => {
	let hash = 0;

	for (let i = 0; i < str.length; i += 1) {
		/* eslint-disable no-bitwise */
		hash = (hash << 5) - hash + str.charCodeAt(i);
		hash &= hash;
		/* eslint-enable no-bitwise */
	}

	const index = Math.abs(hash) % colors.length;
	const hexColor = colors[index];

	return { index, color: hexColor };
};

import React from 'react';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const DoneColumnTick = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex
			xcss={containerStyles}
			testId="work-management-board.ui.board.column.column-header.header-content.done-column-tick"
		>
			<Tooltip content={formatMessage(messages.doneTickTooltip)}>
				<CheckIcon label="" color={token('color.icon.success')} LEGACY_size="small" />
			</Tooltip>
		</Flex>
	);
};

export default DoneColumnTick;

const containerStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	lineHeight: 1,
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noPermissionToModify: {
		id: 'work-management-board.common.contact-admin-to-modify-board.no-permission-to-modify',
		defaultMessage: '{linkStart}Contact your admin{linkEnd} to make changes to the board',
		description:
			'Message to be shown to user when they do not have permission to modify board columns',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholderText: {
		id: 'business-issue-create.inline-create-form.placeholder-text',
		defaultMessage: 'What needs to be done?',
		description: 'Placeholder text in the summary field to prompt the user to enter a summary.',
	},
	createButton: {
		id: 'business-issue-create.inline-create-form.create-button',
		defaultMessage: 'Create',
		description: 'Text on the create button.',
	},
	selectIssueTypeButton: {
		id: 'business-issue-create.inline-create-form.select-issue-type-button',
		defaultMessage: 'Select issue type. {issueType} currently selected.',
		description: 'Accessible label on the select issue type button.',
	},
	manageIssueTypes: {
		id: 'business-issue-create.inline-create-form.manage-issue-types',
		defaultMessage: 'Manage types',
		description:
			'Dropdown menu item. Clicking this will take the user to a page where they can manage the project issue types.',
	},
});

import type { Component, ComponentOption } from './types';

export const mapComponentsToOptions = (components: Component[]): ComponentOption[] =>
	components.map(({ id, componentId, name }) => ({
		label: name,
		value: componentId !== undefined ? String(componentId) : String(id),
	}));

export const mapComponentsToFilterOptions = (components: Component[]): ComponentOption[] =>
	components.map(({ name }) => ({ label: name, value: name }));

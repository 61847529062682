import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { TRANSITION_ZONE_DND_TYPE } from '@atlassian/jira-platform-board-kit/src/common/constants/drag-drop/index.tsx';
import type { DroppableTransitionZoneData } from '@atlassian/jira-platform-board-kit/src/common/types';
import { CARD_DND_TYPE, COLUMN_DND_TYPE } from '../../../common/constants';
import type { DraggableCardData, DraggableColumnData } from '../../../common/types';

export function isCard(data: Record<string | symbol, unknown>): data is DraggableCardData {
	return data.type === CARD_DND_TYPE;
}

export function isColumn(data: Record<string | symbol, unknown>): data is DraggableColumnData {
	return data.type === COLUMN_DND_TYPE;
}

export function isTransitionZone(
	data: Record<string | symbol, unknown>,
): data is DroppableTransitionZoneData {
	return data.type === TRANSITION_ZONE_DND_TYPE;
}

export const isClosestEdgeAdjacentToSource = (
	sourceIndex: number,
	destinationIndex: number,
	closestEdge: Edge | null,
) => {
	if (closestEdge == null) {
		return false;
	}

	const adjacentEdges = {
		top: sourceIndex + 1,
		bottom: sourceIndex - 1,
		left: sourceIndex + 1,
		right: sourceIndex - 1,
	};

	return destinationIndex === adjacentEdges[closestEdge];
};

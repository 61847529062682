import React, {
	memo,
	useCallback,
	useState,
	useEffect,
	type ComponentType,
	type ReactElement,
} from 'react';
import { styled } from '@compiled/react';
import Popup from '@atlaskit/popup';
import { ff } from '@atlassian/jira-feature-flagging';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { popupWidth } from '../constants';
import Trigger from './trigger';

type Props = {
	minWidth?: number;
	renderContent: (closeMenu: () => void) => ReactElement;
	forceVisibility?: boolean;
};

const MoreMenuCore: ComponentType<Props> = memo(
	({ renderContent, minWidth = popupWidth, forceVisibility = false }: Props) => {
		const [isOpen, setIsOpen] = useState(forceVisibility);
		useEffect(() => {
			setIsOpen(forceVisibility);
		}, [forceVisibility]);

		const closeMenu = useCallback(() => setIsOpen(false), []);
		const toggleMenu = useCallback(() => setIsOpen((oldIsOpen) => !oldIsOpen), []);

		const content = useCallback(
			() => <DropdownWrapper width={minWidth}>{renderContent(closeMenu)}</DropdownWrapper>,
			[closeMenu, renderContent, minWidth],
		);

		const trigger = useCallback(
			// @ts-expect-error - TS7006 - Parameter 'triggerProps' implicitly has an 'any' type.
			(triggerProps) => <Trigger {...triggerProps} isSelected={isOpen} onClick={toggleMenu} />,
			[isOpen, toggleMenu],
		);

		return (
			<UFOSegment name="business-more-menu">
				<Popup
					isOpen={isOpen}
					onClose={closeMenu}
					placement="bottom-end"
					content={content}
					trigger={trigger}
					shouldRenderToParent={ff('fix-should-render-to-parent-with-custom-backgrounds')}
					strategy={
						ff('fix-should-render-to-parent-with-custom-backgrounds') ? 'absolute' : undefined
					}
				/>
			</UFOSegment>
		);
	},
);

export default MoreMenuCore;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropdownWrapper = styled.div<{ width: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
});

export const MINUTE = 'minute' as const;
export const HOUR = 'hour' as const;
export const DAY = 'day' as const;
export const WEEK = 'week' as const;

export const DAYS = 'days' as const;
export const HOURS = 'hours' as const;
export const PRETTY = 'pretty' as const;

export const defaultTimeTrackingConfiguration = {
	isTimeTrackingEnabled: false,
	daysPerWeek: 5,
	hoursPerDay: 8,
	defaultUnit: MINUTE,
	format: PRETTY,
} as const;

export const LOCK_IN_NEW_ISSUE_VIEW = 'LOCK_IN_NEW_ISSUE_VIEW' as const;
export const DEFAULT_TO_NEW_ISSUE_VIEW = 'DEFAULT_TO_NEW_ISSUE_VIEW' as const;
export const DEFAULT_TO_OLD_ISSUE_VIEW = 'DEFAULT_TO_OLD_ISSUE_VIEW' as const;

import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const IssueLinksPopupFallback = () => (
	<Inline xcss={linkIssuePopupFallbackStyles} alignInline="center" alignBlock="center">
		<Spinner size="medium" />
	</Inline>
);

const linkIssuePopupFallbackStyles = xcss({
	width: '360px',
	height: '342px',
});

import React, { useCallback, useMemo } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import Tooltip from '@atlaskit/tooltip';
import { log } from '@atlassian/jira-common-util-logging';
import {
	FormattedRelativeV2 as FormattedRelative,
	// eslint-disable-next-line jira/restricted/@atlassian+jira-intl
	useIntlV2 as useIntl,
} from '@atlassian/jira-intl';
import { TIMESTAMP_DISPLAY_SETTING } from '@atlassian/jira-issue-fetch-services-common';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services';
import { getBrowserTimeZone } from '@atlassian/jira-platform-utils-datetime';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useUserSubscriber } from '@atlassian/jira-user-services';
import { ABSOLUTE, RELATIVE, type Props } from './types';
import { getADGTimestampDisplaySetting } from './utils';

const TimestampView = (props: Props) => {
	const { value, extraStyles = {}, tooltipPosition = 'top' } = props;
	const [{ data: loggedInUser }] = useUserSubscriber();
	const loggedInUserTimeZone = loggedInUser ? loggedInUser.timeZone : undefined;
	const userTimeZone = loggedInUserTimeZone || getBrowserTimeZone();
	const isADGTimestampDisplaySettingRelative = getADGTimestampDisplaySetting(value) === RELATIVE;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatDate } = useIntl();

	const [userDisplaySetting, { setValue: setTimestampDisplaySetting }] =
		useUserPreferencesValue(TIMESTAMP_DISPLAY_SETTING);

	// Use relative display setting if user hasn't set anything
	const displaySetting =
		isNil(userDisplaySetting) || userDisplaySetting === '' ? RELATIVE : userDisplaySetting;

	const formattedAbsoluteTimestamp = useMemo(
		() =>
			formatDate(value, {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				timeZone: userTimeZone,
			}),
		[formatDate, userTimeZone, value],
	);

	const onAbsoluteClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireUIAnalytics(analyticsEvent, 'absoluteTimeButton');

		setTimestampDisplaySetting(RELATIVE).catch((error) => {
			log.safeErrorWithoutCustomerData(
				'issue.fields.components.timestamp',
				'Failed to set timestamp display to RELATIVE',
				error,
			);
		});
	}, [createAnalyticsEvent, setTimestampDisplaySetting]);

	const onRelativeClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireUIAnalytics(analyticsEvent, 'relativeTimeButton');

		setTimestampDisplaySetting(ABSOLUTE).catch((error) => {
			log.safeErrorWithoutCustomerData(
				'issue.fields.components.timestamp',
				'Failed to set timestamp display to ABSOLUTE',
				error,
			);
		});
	}, [createAnalyticsEvent, setTimestampDisplaySetting]);

	const renderAbsolute = useCallback(
		(canSwitchDisplaySetting: boolean) => (
			<TimestampWrapper
				canSwitchDisplaySetting={canSwitchDisplaySetting}
				onKeyDown={(e) => {
					if ((e.key === 'Enter' || e.key === 'Space') && canSwitchDisplaySetting) {
						onAbsoluteClick();
					}
				}}
				role="presentation"
				onClick={canSwitchDisplaySetting ? onAbsoluteClick : noop}
				extraStyles={extraStyles}
			>
				{formattedAbsoluteTimestamp}
			</TimestampWrapper>
		),
		[extraStyles, formattedAbsoluteTimestamp, onAbsoluteClick],
	);

	const renderRelative = useCallback(
		() => (
			<TimestampWrapper
				canSwitchDisplaySetting
				onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && onRelativeClick()}
				role="presentation"
				onClick={onRelativeClick}
				extraStyles={extraStyles}
			>
				<Tooltip tag="span" content={formattedAbsoluteTimestamp} position={tooltipPosition}>
					<span data-testid="issue-timestamp.relative-time">
						<FormattedRelative value={value} />
					</span>
				</Tooltip>
			</TimestampWrapper>
		),
		[extraStyles, formattedAbsoluteTimestamp, onRelativeClick, tooltipPosition, value],
	);

	// BENTO-2569: Users have requested the ability to switch to absolute timestamps when the timestamp is to be displayed as relative.
	// When the timestamp is to be displayed as absolute then we cannot allow them to switch (to comply with ADG)
	return isADGTimestampDisplaySettingRelative && displaySetting === RELATIVE
		? renderRelative()
		: renderAbsolute(isADGTimestampDisplaySettingRelative);
};

TimestampView.displayName = 'TimestampView';

export default TimestampView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimestampWrapper = styled.span<{
	canSwitchDisplaySetting: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extraStyles: Record<string, any>;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		cursor: props.canSwitchDisplaySetting ? 'pointer' : 'default',
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ extraStyles }) => extraStyles.always,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({
		canSwitchDisplaySetting,
		extraStyles: { whenDisplaySettingSwitchable: extraStylesWhenSwitchable },
	}) => (canSwitchDisplaySetting ? extraStylesWhenSwitchable : ''),
);
TimestampWrapper.displayName = 'TimestampWrapper';

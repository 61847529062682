import React, { memo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { REMOTE_ISSUE_LINKS_TYPE } from '@atlassian/jira-business-constants';
import { ISSUE_LINKS_TYPE } from '@atlassian/jira-platform-field-config';
import { SUMMARY_ID, ISSUE_KEY_ID, ISSUE_TYPE_ID } from '../../../../../common/constants';
import type { BoardIssue } from '../../../../../common/types';
import { useSelectedFields } from '../../../../../controllers/fields-preference';
import ConfluencePages from './confluence-pages';
import { IssueLinks } from './issue-links';

type ContentProps = {
	issue: BoardIssue;
	hasConfluencePages: boolean;
	hasIssueLinks: boolean;
};

const CardFooterContent = memo(({ issue, hasConfluencePages, hasIssueLinks }: ContentProps) => {
	const confluencePages = hasConfluencePages ? <ConfluencePages issue={issue} /> : null;
	const issueLinks = hasIssueLinks ? (
		<IssueLinks
			issueId={issue.id}
			issueKey={issue.fields[ISSUE_KEY_ID].value}
			issueSummary={issue.fields[SUMMARY_ID].value}
			issueLinksCount={issue.fields[ISSUE_LINKS_TYPE]?.value ?? 0}
			issueTypeField={issue.fields[ISSUE_TYPE_ID]}
		/>
	) : null;

	if (confluencePages || issueLinks) {
		return (
			<Footer>
				{confluencePages}
				{issueLinks}
			</Footer>
		);
	}

	return null;
});

export const useCardFooter = (issue: BoardIssue) => {
	const selectedFields = useSelectedFields();

	const pageCount = issue.fields[REMOTE_ISSUE_LINKS_TYPE]?.value ?? 0;
	const hasConfluencePages = selectedFields[REMOTE_ISSUE_LINKS_TYPE] && pageCount > 0;
	const issueLinksCount = issue.fields[ISSUE_LINKS_TYPE]?.value ?? 0;
	const hasIssueLinks = selectedFields[ISSUE_LINKS_TYPE] && issueLinksCount > 0;

	const hasFooter = hasConfluencePages || hasIssueLinks;

	let footerContent: ReactNode = null;
	let footerPlaceholder: ReactNode = null;
	if (hasFooter) {
		footerContent = (
			<CardFooterContent
				issue={issue}
				hasConfluencePages={hasConfluencePages}
				hasIssueLinks={hasIssueLinks}
			/>
		);
		footerPlaceholder = <FooterPlaceholder />;
	}

	return { footerContent, footerPlaceholder };
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	position: 'absolute',
	bottom: 0,
	display: 'flex',
	gap: token('space.050', '4px'),
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterPlaceholder = styled.div({
	height: token('space.500', '40px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30A)}`,
	margin: `0 ${token('space.150', '12px')}`,
});

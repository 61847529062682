type QueryErrorExtension = {
	statusCode?: number | null | undefined;
	errorType?: string | null;
};

export const ERROR_TYPE = 'QueryError';

export type QueryErrorPayload = {
	readonly __typename?: string;
	readonly message?: string | null;
	readonly extensions?: readonly QueryErrorExtension[] | null | undefined;
};

export class QueryError extends Error {
	readonly extensions: readonly QueryErrorExtension[] | undefined | null;

	statusCode: number | null;

	constructor(payload: QueryErrorPayload) {
		super(payload.message ?? undefined);
		this.extensions = payload.extensions;
		this.message = payload.message ?? '';
		this.statusCode = payload.extensions?.[0]?.statusCode ?? -1;
	}
}

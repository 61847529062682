import { useCallback } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { getTransitionSuccessFlag, getTransitionFailureFlag } from './utils';
/**
 *
 * @returns a set of methods that can be used to shw flags.
 * The idea of this hook was to contain all the flags that we will use across the issue transition.
 * Keeps all the flags and their test can be separeted.
 */
export const useShowFlag = () => {
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const showIssueTransitionSuccessFlag = useCallback(
		(issueKey: string, status: string) =>
			showFlag(getTransitionSuccessFlag(issueKey, status, formatMessage)),
		[formatMessage, showFlag],
	);
	const showIssueTransitionFailureFlag = useCallback(
		(issueKey: string) => showFlag(getTransitionFailureFlag(issueKey, formatMessage)),
		[formatMessage, showFlag],
	);

	return {
		showIssueTransitionSuccessFlag,
		showIssueTransitionFailureFlag,
	};
};

import React, { useMemo, useEffect, useState, useCallback } from 'react';
import noop from 'lodash/noop';
import { lazy } from 'react-loosely-lazy';
import { useSidebar } from '@atlassian/jira-business-app-wrapper/src/controllers/sidebar/index.tsx';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import { useIssueContextActions } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async.tsx';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';
import Placeholder from '@atlassian/jira-placeholder';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	ContextualAnalyticsData,
	SCREEN,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';

export type Props = {
	issueKey: IssueKey;
	analyticsSource: AnalyticsSource;
	onClose: () => void;
	onChange?: (event: ChangeEvent) => void;
	onIssueDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
	isSoftwareProject?: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const IssueApp = lazy<typeof IssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);

export const SidebarIssueApp = ({
	analyticsSource,
	issueKey,
	onClose,
	onIssueDeleteSuccess,
	onChange,
	isSoftwareProject,
}: Props) => {
	const [currentIssueKey, setCurrentIssueKey] = useState(issueKey);
	const prevIssueKey = usePreviousWithInitial(issueKey);
	// @ts-expect-error - TS7031 - Binding element 'nextIssueKey' implicitly has an 'any' type.
	const onIssueKeyChange = useCallback(({ toIssueKey: nextIssueKey }) => {
		setCurrentIssueKey(nextIssueKey);
	}, []);
	const [, { setDetailViewAsSidebar, setDetailViewAsModal }] = useIssueContextActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { setSidebarContent }] = useSidebar();

	useEffect(() => {
		if (prevIssueKey !== issueKey) {
			setCurrentIssueKey(issueKey);
		}
	}, [currentIssueKey, issueKey, prevIssueKey]);

	const onSwitchToModal = useCallback(() => {
		setDetailViewAsModal();
		setSidebarContent(null);

		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToModal');
	}, [createAnalyticsEvent, setDetailViewAsModal, setSidebarContent]);

	const onSwitchToSidebar = useCallback(() => {
		setDetailViewAsSidebar();
		setSidebarContent(null);

		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToSidebar');
	}, [createAnalyticsEvent, setDetailViewAsSidebar, setSidebarContent]);

	const PACKAGE_NAME = useMemo(
		() => (isSoftwareProject ? 'jswSidebarIssueApp' : 'jwmSidebarIssueApp'),
		[isSoftwareProject],
	);

	return (
		<UFOSegment name="business-sidebar-issue-app">
			<ContextualAnalyticsData sourceType={SCREEN} sourceName={PACKAGE_NAME}>
				<Placeholder name="issue-app" fallback={<IssueViewSkeleton />}>
					<IssueBoundary packageName={PACKAGE_NAME}>
						<IssueApp
							viewModeOptions={{
								viewMode: 'SIDEBAR',
								viewModeSwitchEnabled: true,
								onSwitchToModal,
								onSwitchToSidebar,
							}}
							issueKey={currentIssueKey}
							analyticsSource={analyticsSource}
							shouldShowCloseButton
							onClose={onClose}
							onIssueKeyChange={onIssueKeyChange}
							shouldSetInitialFocus
							shouldShowRootProjectsBreadcrumb
							onIssueDeleteSuccess={onIssueDeleteSuccess}
							onIssueDeleteFailure={noop}
							isSpaEnabled={false}
							isLoadedWithPage={false}
							onChange={onChange}
						/>
					</IssueBoundary>
				</Placeholder>
			</ContextualAnalyticsData>
		</UFOSegment>
	);
};

import { MULTI_GROUP_CF_TYPE, GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { Sorting } from '../../../sorting/types';
import type { TransformedSingleGroup, TransformedMultipleGroup } from '../types';

const getSingleGroupSorting: Sorting<TransformedSingleGroup> = (field) => field.value?.name ?? '';
const getMultipleGroupSorting: Sorting<TransformedMultipleGroup> = (field) =>
	field.value == null ? '' : field.value.map((v) => v.name).join(',');

export const groupSortingHandler = {
	[GROUP_CF_TYPE]: getSingleGroupSorting,
	[MULTI_GROUP_CF_TYPE]: getMultipleGroupSorting,
};

import React from 'react';
import ShipIcon from '@atlaskit/icon/glyph/ship';
import {
	MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
	AFFECTS_VERSIONS_TYPE,
	FIX_VERSIONS_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getVersionIcons: GetIconFunction = (size) => ({
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[VERSION_CF_TYPE]: <ShipIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[MULTI_VERSION_CF_TYPE]: <ShipIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[AFFECTS_VERSIONS_TYPE]: <ShipIcon size={size} label="" />,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	[FIX_VERSIONS_TYPE]: <ShipIcon size={size} label="" />,
});

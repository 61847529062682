import { useCallback } from 'react';
import type { DocumentNode } from 'graphql';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { useWorkflows } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCreateIssue as useCreateIssueService } from '../../services/create-issue';
import { useRefetchIssue } from '../../services/refetch-issue';
import type { CreateIssueParameters, CreateIssueInput, ExtraCreateIssuePayload } from '../../types';
import { hasAllRequiredFields } from '../../utils/has-all-required-fields';
import { useCreateIssueDialog, CancelledError } from '../create-issue-dialog';
import { useShowCreateSuccessFlag } from '../show-create-success-flag';
import messages from './messages';

type CreateIssue<TIssue> = (input: {
	createParams: CreateIssueParameters;
	highlightIssue: (issueId: number) => void;
	unhighlightIssue: (issueId: number) => void;
	ignoreFiltersContext: boolean;
}) => Promise<TIssue | null>;

type CreateIssueResponse<TIssue> = {
	item: TIssue;
	itemIdentifiers: { issueId: number; issueKey: string };
};

export const useCreateIssueTransitionId = (): ((
	createInput: CreateIssueInput,
) => string | undefined) => {
	const workflows = useWorkflows();

	return useCallback(
		(createInput) => {
			if (createInput.status && workflows != null) {
				// find the workflow for the input issue type
				const workflow = workflows.find((w) =>
					w.issueTypes.some((it) => it.issueTypeId === createInput.issueType.id),
				);
				// find the first transition to the input status that is initial, or global but not conditional
				const { statusId } = createInput.status;
				const transition = workflow?.transitions.find(
					(t) => t.to.statusId === statusId && (t.isInitial || (t.isGlobal && !t.isConditional)),
				);
				return transition?.transitionId;
			}
		},
		[workflows],
	);
};

export const useCreateIssueOld = <TIssue,>(
	mutation: DocumentNode,
	query: DocumentNode,
): CreateIssue<TIssue> => {
	const createIssue = useCreateIssueService<TIssue>(mutation);
	const refetchIssue = useRefetchIssue<TIssue>(query);
	const showCreateSuccessFlag = useShowCreateSuccessFlag();
	const { showFlag } = useFlagsService();
	const { typesWithFields } = useIssueTypesAndFields({
		issueOperation: 'CREATE',
	});
	const getTransitionId = useCreateIssueTransitionId();
	const { formatMessage } = useIntl();
	const showCreateIssueDialog = useCreateIssueDialog();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		async ({ createParams, highlightIssue, unhighlightIssue, ignoreFiltersContext }) => {
			const transitionId = getTransitionId(createParams.input);

			try {
				if (
					!hasAllRequiredFields({
						issueType: typesWithFields.find(
							({ issueType }) => issueType.id === createParams.input.issueType.id,
						),
						createInput: createParams.input,
					})
				) {
					throw new ValidationError('Missing required fields');
				}

				const response = await createIssue(createParams, { transitionId });

				highlightIssue(response.itemIdentifiers.issueId);

				if (createParams.showSuccessFlag) {
					showCreateSuccessFlag(response.itemIdentifiers.issueKey);
				}

				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'issue created',
					String(response.itemIdentifiers.issueId),
					{ location: createParams.location },
				);

				unhighlightIssue(response.itemIdentifiers.issueId);

				return response.item;
			} catch (error) {
				let finalError = error;
				try {
					if (error instanceof ValidationError) {
						const { issueId, issueKey } = await showCreateIssueDialog(createParams.input, {
							transitionId,
						});

						highlightIssue(issueId);

						const issue = await refetchIssue(
							issueId,
							createParams.fieldIdsToReturn,
							!ignoreFiltersContext,
						);

						if (createParams.showSuccessFlag) {
							showCreateSuccessFlag(issueKey);
						}

						unhighlightIssue(issueId);

						return issue;
					}
				} catch (refetchError) {
					finalError = refetchError;
				}

				if (finalError instanceof CancelledError) {
					return null;
				}

				showFlag({
					type: 'error',
					title: formatMessage(messages.errorFlagTitle),
					description: formatMessage(messages.errorFlagDescription),
				});

				throw finalError;
			}
		},
		[
			getTransitionId,
			typesWithFields,
			createIssue,
			createAnalyticsEvent,
			showCreateSuccessFlag,
			showFlag,
			formatMessage,
			showCreateIssueDialog,
			refetchIssue,
		],
	);
};

export const useCreateIssue = <TIssue,>(
	onCreateIssue: (
		createParams: CreateIssueParameters,
		extraPayload?: ExtraCreateIssuePayload,
	) => Promise<CreateIssueResponse<TIssue>>,
	onRefetchIssue: (
		issueId: number,
		fieldIds: string[],
		withFiltersContext: boolean,
	) => Promise<TIssue | null>,
): CreateIssue<TIssue> => {
	const showCreateSuccessFlag = useShowCreateSuccessFlag();
	const { showFlag } = useFlagsService();
	const { typesWithFields } = useIssueTypesAndFields({
		issueOperation: 'CREATE',
	});
	const getTransitionId = useCreateIssueTransitionId();
	const { formatMessage } = useIntl();
	const showCreateIssueDialog = useCreateIssueDialog();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		async ({ createParams, highlightIssue, unhighlightIssue, ignoreFiltersContext }) => {
			const transitionId = getTransitionId(createParams.input);

			try {
				if (
					!hasAllRequiredFields({
						issueType: typesWithFields.find(
							({ issueType }) => issueType.id === createParams.input.issueType.id,
						),
						createInput: createParams.input,
					})
				) {
					throw new ValidationError('Missing required fields');
				}

				const response = await onCreateIssue(createParams, { transitionId });

				highlightIssue(response.itemIdentifiers.issueId);

				if (createParams.showSuccessFlag) {
					showCreateSuccessFlag(response.itemIdentifiers.issueKey);
				}

				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'issue created',
					String(response.itemIdentifiers.issueId),
					{ location: createParams.location },
				);

				unhighlightIssue(response.itemIdentifiers.issueId);

				return response.item;
			} catch (error) {
				let finalError = error;
				try {
					if (error instanceof ValidationError) {
						const { issueId, issueKey } = await showCreateIssueDialog(createParams.input, {
							transitionId,
						});

						highlightIssue(issueId);

						const issue = await onRefetchIssue(
							issueId,
							createParams.fieldIdsToReturn,
							!ignoreFiltersContext,
						);

						if (createParams.showSuccessFlag) {
							showCreateSuccessFlag(issueKey);
						}

						unhighlightIssue(issueId);

						return issue;
					}
					if (error instanceof Error) {
						fireErrorAnalytics({
							meta: {
								id: 'JWMCreateIssueInline',
								packageName: 'jiraBusinessIssueCreate',
								teamName: 'wanjel',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
					}
				} catch (refetchError) {
					finalError = refetchError;
				}

				if (finalError instanceof CancelledError) {
					return null;
				}

				showFlag({
					type: 'error',
					title: formatMessage(messages.errorFlagTitle),
					description: formatMessage(messages.errorFlagDescription),
				});

				throw finalError;
			}
		},
		[
			getTransitionId,
			typesWithFields,
			onCreateIssue,
			createAnalyticsEvent,
			showCreateSuccessFlag,
			showFlag,
			formatMessage,
			showCreateIssueDialog,
			onRefetchIssue,
		],
	);
};

import { PARENT_TYPE } from '@atlassian/jira-platform-field-config';
import type { Sorting } from '../../../sorting/types';
import type { TransformedParentField } from '../types';

const getParentSorting: Sorting<TransformedParentField> = (field) =>
	field.value ? field.value.issueKey : '';

export const parentSortingHandler = {
	[PARENT_TYPE]: getParentSorting,
};

import qs from 'query-string';
import { getErrorType } from '@atlassian/jira-business-error-handling/src/utils/get-error-type/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Component, FetchComponentsResponse, ComponentOption } from './types';
import { mapComponentsToOptions, mapComponentsToFilterOptions } from './utils';

export const fetchComponents = ({
	inputValue,
	autoCompleteUrl,
	useFilterMapper,
	mounted,
	queryParameters,
}: {
	inputValue: string;
	autoCompleteUrl: string;
	useFilterMapper?: boolean;
	mounted?: boolean;
	queryParameters?: Record<string, string>;
}) => {
	const mapperFunction =
		useFilterMapper === true ? mapComponentsToFilterOptions : mapComponentsToOptions;
	const query = ff('jira.list.field-support-part1')
		? qs.stringify({ query: inputValue, ...queryParameters })
		: qs.stringify({ query: inputValue });
	return performGetRequest(`${autoCompleteUrl}?${query}`)
		.then((response: FetchComponentsResponse) => mapperFunction(response.values))
		.catch((error) => {
			fireErrorAnalytics({
				meta: {
					id: 'fetchProjectComponents',
					packageName: 'jiraBusinessFetchComponents',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to fetch jwm project components',
					errorType: getErrorType(error),
					online: window.navigator.onLine,
					mounted,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		});
};

export { mapComponentsToOptions, mapComponentsToFilterOptions };
export type { Component, FetchComponentsResponse, ComponentOption };

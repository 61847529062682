import React, {
	type ReactNode,
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import type { Filters } from '../../common/types';
import { useFieldConfigWithCustomFields } from '../field-config-with-custom-fields';
import { useFilterFields } from '../filter-fields';
import { useInferHideDoneItemsWithFilters } from '../hide-done-items';
import { useFilters } from '../index';
import { useQuickFilters } from '../quick-filters';
import { deserialiseJql } from './utils';

const DEFAULT_VALUE = {
	filters: {},
};

type ProviderProps = {
	children: ReactNode;
};

type ContextValue = {
	filters: Filters;
	setFilters?: (filters: Filters) => void;
	isLoading?: boolean;
	setIsLoading?: (isLoading: boolean) => void;
};

/* Context used by the board view until the filters get implemented in the backend */
const DeserialisedFiltersContext = createContext<ContextValue>(DEFAULT_VALUE);
export const DeserialisedFiltersProvider = ({ children }: ProviderProps) => {
	const [filters, setFilters] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const value = useMemo(
		() => ({
			filters,
			setFilters,
			isLoading,
			setIsLoading,
		}),
		[filters, isLoading],
	);

	return (
		<DeserialisedFiltersContext.Provider value={value}>
			{children}
		</DeserialisedFiltersContext.Provider>
	);
};

export const useDeserialisedFilters = (): ContextValue => useContext(DeserialisedFiltersContext);

type Props = {
	view?: JWMView;
	allowedFieldTypes: Set<string>;
	allowedAliasFieldId?: Set<string>;
	allowCustomFields?: boolean;
};

const EMPTY_FILTER: Filters = {};

export const useDeserialiseJqlFilters = ({
	view,
	allowedFieldTypes,
	allowedAliasFieldId,
	allowCustomFields,
}: Props) => {
	const { jql, setFilter } = useFilters();

	const {
		fieldConfig,
		resolvers,
		customFieldsLoader,
		loading: isLoadingFieldConfig,
	} = useFieldConfigWithCustomFields({
		view,
		allowedFieldTypes,
		allowedAliasFieldId,
		allowCustomFields,
	});

	const [filters, setFilters] = useState({});
	const [error, setError] = useState<Error | null>(null);
	const hasFilterError = error != null;
	const [isDeserialisingFilters, setIsDeserialisingFilters] = useState(false);

	const [filtersCache, setFiltersCache] = useState<Record<string, Filters>>({});

	const { data: filterFields, loading: isLoadingFilterFields } = useFilterFields();

	const { quickFiltersJql } = useQuickFilters();

	useEffect(() => {
		// Skip if loading
		if (isDeserialisingFilters || isLoadingFieldConfig || isLoadingFilterFields) return;
		// Skip if no fields supported
		if (!Object.keys(fieldConfig).length) return;
		// Skip if deserialised this jql before
		if (jql != null && filters === filtersCache[jql]) return;
		// Skip if has error and jql not empty
		if (hasFilterError && jql != null) {
			return;
		}

		if (jql == null) {
			if (hasFilterError) {
				setError(null);
			}
			if (filters !== EMPTY_FILTER) {
				setFilters(EMPTY_FILTER);
			}

			return;
		}
		if (quickFiltersJql !== null) {
			setFilters(EMPTY_FILTER);
			return;
		}

		if (filtersCache[jql]) {
			setFilters(filtersCache[jql]);
			return;
		}

		setIsDeserialisingFilters(true);

		deserialiseJql(jql, fieldConfig, filterFields, resolvers)
			.then((result) => {
				setFilters(result);
				setFiltersCache({
					...filtersCache,
					[jql]: result,
				});
			})
			.catch((e) => {
				setError(e);
			})
			.finally(() => {
				setIsDeserialisingFilters(false);
			});
	}, [
		hasFilterError,
		quickFiltersJql,
		error,
		filters,
		isLoadingFieldConfig,
		isLoadingFilterFields,
		fieldConfig,
		jql,
		filtersCache,
		filterFields,
		resolvers,
		isDeserialisingFilters,
	]);

	useInferHideDoneItemsWithFilters({ filters });

	return {
		fieldConfig,
		filters,
		hasFilterError,
		customFieldsLoader,
		setFilters: setFilter,
		loading: isLoadingFieldConfig || isDeserialisingFilters,
	} as const;
};

export const useDeserialiseJqlFiltersWithContext = (props: Props) => {
	const { fieldConfig, filters, hasFilterError, customFieldsLoader, setFilters, loading } =
		useDeserialiseJqlFilters(props);

	const { setFilters: setContextFilters, setIsLoading: setIsLoadingFilters } =
		useDeserialisedFilters();

	useEffect(() => {
		if (setContextFilters) {
			setContextFilters(filters);
		}
	}, [filters, setContextFilters]);

	useEffect(() => {
		if (setIsLoadingFilters) {
			setIsLoadingFilters(loading);
		}
	}, [loading, setIsLoadingFilters]);

	return {
		fieldConfig,
		filters,
		hasFilterError,
		customFieldsLoader,
		setFilters,
	} as const;
};

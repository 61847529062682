import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitleAddColumn: {
		id: 'work-management-board.controllers.workflow-actions.error-flag-title-add-column',
		defaultMessage: 'Unable to create column',
		description: 'Title of error flag that is shown if create column is unsuccessful',
	},
	errorFlagTitleDeleteColumn: {
		id: 'work-management-board.controllers.workflow-actions.error-flag-title-delete-column',
		defaultMessage: 'Unable to delete column',
		description: 'Title of error flag that is shown if delete column is unsuccessful',
	},
	errorFlagDescription: {
		id: 'work-management-board.controllers.workflow-actions.error-flag-description',
		defaultMessage: 'We’ve run into some connection difficulties.',
		description: 'Description of error flag that is shown if column action is unsuccessful',
	},
	errorFlagActionsReload: {
		id: 'work-management-board.controllers.workflow-actions.error-flag-actions-reload',
		defaultMessage: 'Reload',
		description:
			'Content of error flag call to action prompting user to reload the page if the action is unsuccessful',
	},
	errorFlagActionsCheckStatus: {
		id: 'work-management-board.controllers.workflow-actions.error-flag-actions-check-status',
		defaultMessage: 'Check status page',
		description:
			'Content of error flag call to action prompting user to check status page if column action is unsuccessful',
	},
	statusNameIsNotUniqueDescription: {
		id: 'work-management-board.controllers.workflow-actions.status-name-is-not-unique-description',
		defaultMessage:
			'This status name has been used before. To use it again, open the workflow editor and create a status with the same name.',
		description:
			'Error message to be shown when status name has been used, and we prompt user to edit in workflow editor instead',
	},
	statusHasNoIncomingTransitionTitle: {
		id: 'work-management-board.controllers.workflow-actions.status-has-no-incoming-transition-title',
		defaultMessage: 'Action needed to delete column',
		description:
			'Error message to be shown when the delete status fail because it will end up in invalid transitions state',
	},
	statusHasNoIncomingTransitionDescription: {
		id: 'work-management-board.controllers.workflow-actions.status-has-no-incoming-transition-description',
		defaultMessage:
			'You need to remove the transitions linked to this status before you can delete it.',
		description:
			'Error message to be shown when the delete status fail because it will end up in invalid transitions state',
	},
	workflowVersionConflictDescription: {
		id: 'work-management-board.controllers.workflow-actions.workflow-version-conflict-description',
		defaultMessage:
			'Other workflow updates are in progress. Check the workflow editor and make sure they are complete before making any changes.',
		description:
			'Error message to be shown when the delete status fail because there is another workflow update in progress',
	},
	openWorkflowEditorAction: {
		id: 'work-management-board.controllers.workflow-actions.open-workflow-editor-action',
		defaultMessage: 'Open workflow editor',
		description: 'CTA to open project workflow editor',
	},
	viewWorkflowEditorAction: {
		id: 'work-management-board.controllers.workflow-actions.view-workflow-editor-action',
		defaultMessage: 'View workflow editor',
		description: 'CTA to view project workflow editor',
	},
	deletePollingTimeoutTitle: {
		id: 'work-management-board.controllers.workflow-actions.delete-polling-timeout-title',
		defaultMessage: 'Your column is being deleted',
		description: 'Message shown to user when the column is being deleted',
	},
	deletePollingTimeoutDescription: {
		id: 'work-management-board.controllers.workflow-actions.delete-polling-timeout-description',
		defaultMessage:
			'Before you make other changes, check the workflow editor to make sure your column has been deleted.',
		description: 'Warning message to inform the user to check the workflow editor',
	},
	oldStatusMissingInWorkflowDescription: {
		id: 'work-management-board.controllers.workflow-actions.old-status-missing-in-workflow-description',
		defaultMessage:
			'The origin status is not in the current workflow. Check the workflow and try again.',
		description: 'Error message to be shown when the origin status is missing in the workflow',
	},
	newStatusMissingInWorkflowDescription: {
		id: 'work-management-board.controllers.workflow-actions.new-status-missing-in-workflow-description',
		defaultMessage:
			'The target status is not in the current workflow. Check the workflow and try again.',
		description: 'Error message to be shown when the destination status is missing in the workflow',
	},
});

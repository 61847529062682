import {
	createStore,
	createHook,
	createContainer,
	createActionsHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { useCollapsedColumnIds } from '../expand-collapse-columns';

const COLUMN_CONFETTI_NAMES = ['🎊', '🎉', '🎆', '🎇', '✨'];

type State = { confettiColumns: Set<string> };

const actions = {
	addConfettiColumn:
		(columnId: string) =>
		({ setState, getState }: StoreActionApi<State>) => {
			const currentState = getState();
			const confettiColumns = new Set(currentState.confettiColumns);
			confettiColumns.add(columnId);
			setState({ confettiColumns });
		},
	removeConfettiColumn:
		(columnId: string) =>
		({ setState, getState }: StoreActionApi<State>) => {
			const currentState = getState();
			const confettiColumns = new Set(currentState.confettiColumns);
			confettiColumns.delete(columnId);
			setState({ confettiColumns });
		},
};

type Actions = typeof actions;

export const ConfettiColumnsProvider = createContainer();

const store = createStore<State, Actions>({
	name: 'JWMConfettiColumnsStore',
	containedBy: ConfettiColumnsProvider,
	initialState: { confettiColumns: new Set() },
	actions,
});

const useConfettiColumnsStore = createHook(store);

const useConfettiColumnsActions = createActionsHook(store);

export const useConfettiColumns = () => {
	const [{ confettiColumns }, { removeConfettiColumn }] = useConfettiColumnsStore();
	return { confettiColumns, removeConfettiColumn };
};

export const useAddConfettiColumn = () => {
	const collapsedColumnIds = useCollapsedColumnIds();
	const { addConfettiColumn } = useConfettiColumnsActions();

	const addConfettiColumnWithCheck = (columnId: string) => {
		if (!collapsedColumnIds.includes(columnId)) {
			addConfettiColumn(columnId);
		}
	};

	return addConfettiColumnWithCheck;
};

export const matchConfettiColumn = (str: string) =>
	COLUMN_CONFETTI_NAMES.some((emoji) => str.includes(emoji));

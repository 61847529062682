import { useEffect } from 'react';
import { JWM_CONTAINER } from '@atlassian/jira-business-app-wrapper/src/constants.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { JWM_VIEW, PRODUCT } from '../../types';

type FirstInteractionMetricProps = {
	view: JWM_VIEW;
	product?: PRODUCT;
};

export const FirstInteractionMetric = ({ view, product = 'jwm' }: FirstInteractionMetricProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const container = document.getElementById(JWM_CONTAINER) ?? document;
		const hasScrollCapture = true;
		const startTime = performance.now();

		const handleFirstClick = () => {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				`${product}.${view}.first-interaction clicked`,
				{
					duration: performance.now() - startTime,
				},
			);

			container.removeEventListener('click', handleFirstClick);
		};

		const handleFirstScroll = () => {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				`${product}.${view}.first-interaction scrolled`,
				{
					duration: performance.now() - startTime,
				},
			);

			container.removeEventListener('scroll', handleFirstScroll, hasScrollCapture);
		};

		container.addEventListener('click', handleFirstClick);
		container.addEventListener('scroll', handleFirstScroll, hasScrollCapture);

		return () => {
			container.removeEventListener('click', handleFirstClick);
			container.removeEventListener('scroll', handleFirstScroll, hasScrollCapture);
		};
	}, [createAnalyticsEvent, view, product]);
	return null;
};
